import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Typography, CircularProgress } from "@mui/material";
import { useAuthQuery } from "providers/auth";
import { getTeamMembers } from "store/team-members/team-members.query";
import {
  ITeamMemberInfo,
  TeamMemberStatus,
} from "store/team-members/team-members.model";
import { getTeamRoleTitle } from "utils/team";
import { Trans } from "@lingui/macro";
import { StyledTransparentDataGrid } from "components/table.component";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import { getPlayerName, renderPlayer } from "../leaderboard/rows-leaderboard";
import Box from "@mui/material/Box";
import { PlayerPositionFullTitle } from "@gamer/common/lib/models/user";

interface TeamMembersReadOnlyViewProps {
  teamId: string;
}

export const TeamMembersReadOnlyView = (
  props: TeamMembersReadOnlyViewProps,
) => {
  const teamMembersQuery = useAuthQuery(
    ["teamMembers", props.teamId],
    getTeamMembers(props.teamId),
  );

  const { isLoading, error, data } = teamMembersQuery;

  const [members, setMembers] = useState<Array<ITeamMemberInfo>>([]);

  useEffect(() => {
    if (data?.teamMembers) {
      setMembers(
        data.teamMembers.filter(
          ({ status }) => status === TeamMemberStatus.APPROVED,
        ),
      );
    }
  }, [data]);

  if (isLoading) {
    return <CircularProgress size={30}  />;
  }
  if (error) {
    return (
      <Typography variant="subtitle1">
        <Trans>You will get started soon!</Trans>
      </Typography>
    );
  }

  const columns: GridColDef[] = [
    {
      field: "player",
      headerName: "Player",
      flex: 2,
      renderCell: ({ row }) =>
        renderPlayer()({ value: row } as GridRenderCellParams),
      sortable: true,
      valueGetter: ({ row }) =>
        getPlayerName({ value: row } as GridValueFormatterParams<{
          value: Record<string, string>;
        }>),
    },
    {
      field: "position",
      flex: 1,
      headerName: "Position",
      renderCell: (player) => {
        const info = player.row.memberInfo as unknown as ITeamMemberInfo;
        const position = info.position;
        return position
          ? `${PlayerPositionFullTitle[position]} (${position})`
          : "Not defined";
      },
      sortable: true,
    },
    {
      flex: 0.5,
      field: "age",
      headerName: "Age",
      renderCell: (player) => {
        const info = player.row as unknown as ITeamMemberInfo;
        return info.memberInfo?.fullYearsAge;
      },
      sortable: true,
      valueGetter: (player) => {
        const info = player.row as unknown as ITeamMemberInfo;
        return info.memberInfo?.fullYearsAge;
      },
    },
    {
      flex: 0.5,
      field: "role",
      headerName: "Role",
      renderCell: (player) => {
        const info = player.row as unknown as ITeamMemberInfo;
        const role = info.role;
        return (
          <Typography
            variant="body2"
            style={{ textTransform: "capitalize" }}
          >{`${getTeamRoleTitle(role)}`}</Typography>
        );
      },
      sortable: true,
    },
  ];

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      justifyContent="flex-end"
      spacing={5}
    >
      <Grid item>
        <Typography variant="h6">
          <Trans context="Football team players">
            Team members: ({members.length})
          </Trans>
        </Typography>
        <Box sx={{ width: "100%" }}>
          <StyledTransparentDataGrid
            rows={members}
            hideFooter
            columns={columns}
            loading={isLoading}
            disableColumnMenu={true}
            hideFooterPagination
            style={{ width: "100%" }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

import * as React from "react";
import theme, { muiTheme } from "theme";
import { DateTime } from "luxon";
import { Avatar, Badge, Grid, Paper, Typography } from "@mui/material";
import MoodIcon from "@mui/icons-material/Mood";
import SpeedIcon from "@mui/icons-material/Speed";

import { PlayerAvatar } from "components/avatar.component";

import { UserEvent } from "store/events/events.model";
import {
  ITeamMemberInfo,
  PlayerPositionFullTitle,
} from "store/team-members/team-members.model";
import TooltipCustom from "components/tooltip.component";
import UserIconDefault from "components/icons/UserIcon";
import { Progress } from "components/progress";
import { EventReplies } from "./event-feedback-reply";
import { EventFeedbackReaction } from "./event-feedback-reaction";
import { Trans } from "@lingui/macro";

interface EventFeedbackProps {
  userEvent: UserEvent;
  isPlayer: boolean;
  member?: ITeamMemberInfo;
}

export const EventFeedbackComment = ({
  userEvent,
  member,
  isPlayer,
}: EventFeedbackProps) => {
  const name = member
    ? `${member.memberInfo?.firstName} ${member.memberInfo?.lastName}`
    : "Not Available";

  return (
    <Paper style={{ paddingLeft: 16, paddingBottom: 16 }}>
      <Grid
        container
        direction="row"
        spacing={2}
        justifyContent="space-between"
      >
        <Grid item style={{ paddingTop: 12, paddingLeft: 25 }} xs={1}>
          {member ? (
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              badgeContent={
                <TooltipCustom
                  title={
                    (member.position &&
                      PlayerPositionFullTitle[member.position]) ||
                    ""
                  }
                >
                  <Avatar
                    style={{
                      width: 18,
                      height: 18,
                      backgroundColor: theme.background.primary,
                      color: theme.text.primary,
                      fontSize: 10,
                    }}
                  >
                    {member.position || ""}
                  </Avatar>
                </TooltipCustom>
              }
            >
              <PlayerAvatar teamId={member.teamId} playerId={member.playerId} />
            </Badge>
          ) : (
            <UserIconDefault />
          )}
        </Grid>
        <Grid item xs>
          <Grid container direction="column" spacing={1}>
            <Grid item id="comment">
              <Typography
                variant="subtitle2"
                display="inline"
                style={{ fontSize: 14 }}
              >
                {name}
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
                style={{ fontSize: 14 }}
                display="inline"
              >
                {" "}
                &nbsp;
                {userEvent.feedback
                  ? userEvent.feedback.comment
                  : "No Comment Provided"}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ fontSize: 12 }}
              >
                {DateTime.fromMillis(userEvent.updatedAt).toRelative()}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: 400, fontSize: 15 }}
              ></Typography>
            </Grid>
            <Grid item id="rating">
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                spacing={1}
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography
                        style={{
                          fontWeight: 400,
                          fontSize: 15,
                          lineHeight: "18px",
                        }}
                      >
                        <SpeedIcon
                          style={{
                            height: 15,
                            color: muiTheme.palette.primary.main,
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item style={{ width: 90 }}>
                      <Typography
                        style={{
                          fontWeight: 400,
                          fontSize: 12,
                          lineHeight: "18px",
                        }}
                      >
                        <Trans>INTENSITY</Trans>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Progress
                        variant="determinate"
                        color="primary"
                        value={userEvent.feedback.intensityRating * 20}
                        style={{ width: 120, height: 10, borderRadius: 5 }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{
                          fontWeight: 400,
                          fontSize: 12,
                          lineHeight: "18px",
                        }}
                      >
                        {userEvent.feedback.intensityRating.toFixed(1)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography
                        style={{
                          fontWeight: 400,
                          fontSize: 15,
                          lineHeight: "18px",
                        }}
                      >
                        <MoodIcon
                          style={{
                            height: 15,
                            color: muiTheme.palette.secondary.main,
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item style={{ width: 90 }}>
                      <Typography
                        style={{
                          fontWeight: 400,
                          fontSize: 12,
                          lineHeight: "18px",
                        }}
                      >
                        <Trans>HAPPINESS</Trans>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Progress
                        variant="determinate"
                        
                        value={userEvent.feedback.satisfactionRating * 20}
                        style={{ width: 120, height: 10, borderRadius: 5 }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{
                          fontWeight: 400,
                          fontSize: 12,
                          lineHeight: "18px",
                        }}
                      >
                        {userEvent.feedback.satisfactionRating.toFixed(1)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item id="replies">
              <EventReplies {...userEvent} isPlayer={isPlayer} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <EventFeedbackReaction
            isPlayer={isPlayer}
            userEvent={userEvent}
            member={member}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  IconButton,
  Typography,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { Skeleton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TeamAvatar } from "components/avatar.component";
import { CreateTeamModal } from "../teams/create.team.modal";
import { useAuthQuery } from "providers/auth";
import { getTeams } from "store/user-teams/teams.query";
import { getTeamMembers } from "store/team-members/team-members.query";
import theme from "theme";
import { IGetTeamMembersData } from "store/team-members/team-members.model";
import { IUserTeamInfo } from "store/user-teams/user-teams.model";
import { Trans } from "@lingui/macro";
import { useAnalytics } from "use-analytics";

interface ITeamSelectProps {
  currentTeamId?: string;
  onChange: (teamId: string) => void;
}

export default function TeamSelect(props: ITeamSelectProps) {
  const analytics = useAnalytics();
  const { currentTeamId, onChange } = props;

  const [teamId, setTeamId] = useState(currentTeamId || "");
  const teamsQuery = useAuthQuery("teams", getTeams);
  const teamMembersQuery = useAuthQuery(
    ["teamMembers", teamId],
    getTeamMembers(teamId),
    { enabled: Boolean(teamId) },
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [createTeamModalOpen, setCreateTeamModalOpen] = useState(false);
  const [currentTeamName, setCurrentTeamName] = useState("");
  const [currentTeam, setCurrentTeam] = useState<IUserTeamInfo | undefined>(
    undefined,
  );

  useEffect(() => {
    if (teamsQuery.isSuccess && !teamId) {
      const teamIdToSet = teamsQuery.data?.userTeams[0].teamId || "";
      setTeamId(teamIdToSet);
      onChange(teamIdToSet);
    }
  }, [teamsQuery.isSuccess, teamsQuery.data, teamId, onChange]);

  useEffect(() => {
    const team = teamsQuery.data?.userTeams.find((v) => v.teamId === teamId);

    setCurrentTeamName(team?.teamName || "");
    setCurrentTeam(team);
  }, [teamId, teamsQuery.data]);

  if (teamsQuery.error) {
    return (
      <Typography variant="subtitle1">
        <Trans>Failed to Load teams.</Trans>
      </Typography>
    );
  }

  const teams =
    teamsQuery.data?.userTeams.filter(({ status }) => status === "APPROVED") ||
    [];

  const getMembersAmountText = (data?: IGetTeamMembersData) => {
    if (!data) {
      return "";
    }

    const count = data.teamMembers.filter(
      ({ status }) => status === "APPROVED",
    ).length;

    return count === 1 ? `${count} member` : `${count} members`;
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCreateTeam = () => {
    analytics.track("team-select-create-team");
    setCreateTeamModalOpen(true);
    handleClose();
  };

  const handleModalClose = () => {
    analytics.track("team-select-create-team-created");
    setCreateTeamModalOpen(false);
    teamsQuery.refetch();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick =
    (teamId: string) => (_event: React.MouseEvent<HTMLElement>) => {
      onChange(teamId);
      setTeamId(teamId);
      analytics.track("team-select-change-team", { teamId });

      handleClose();
    };

  return (
    <React.Fragment>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item id="image">
          <TeamAvatar
            style={{ width: 40, height: 40 }}
            teamInfo={currentTeam}
          />
        </Grid>
        <Grid item id="text" style={{ minWidth: 137 }}>
          <Grid container direction="column" alignItems="flex-start">
            <Grid item id="name">
              <Typography
                variant="h4"
                style={{
                  fontSize: "20px",
                  fontStyle: "italic",
                  fontWeight: "700",
                  lineHeight: "25.76px",
                  letterSpacing: "1px",
                  textTransform: "uppercase",
                }}
              >
                {teamsQuery.isLoading ? (
                  <Skeleton variant="text" style={{ width: 100 }}></Skeleton>
                ) : (
                  currentTeamName
                )}
              </Typography>
            </Grid>
            <Grid item id="headcount">
              <Typography
                variant="body1"
                style={{ fontSize: 13, color: theme.text.secondary }}
              >
                {teamMembersQuery.isLoading ? (
                  <Skeleton width={70} />
                ) : teamMembersQuery.error ? (
                  "a plenty of members"
                ) : (
                  getMembersAmountText(teamMembersQuery.data)
                )}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item id="expand">
          <IconButton onClick={handleClick} style={{ borderRadius: 1 }}>
            <ExpandMoreIcon
              style={{ transform: anchorEl ? "rotate(180deg)" : "none" }}
            />
          </IconButton>
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 70, left: 0 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{ padding: 0 }}
        MenuListProps={{ style: { padding: 0 } }}
      >
        {teams.map((team) => (
          <MenuItem
            key={`team-select-menu-item-${team.teamId}`}
            value={team.teamId}
            onClick={handleItemClick(team.teamId)}
            sx={{
              padding: "14px 16px 14px 16px",
            }}
            divider
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography
                  variant="body1"
                  color={team.teamId === teamId ? "primary" : "textPrimary"}
                  style={{
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "12px",
                    letterSpacing: "0px",
                    textAlign: "left",
                  }}
                >
                  {team.teamName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  color="primary"
                  style={{
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "12px",
                    letterSpacing: "0px",
                    textAlign: "left",
                  }}
                >
                  {team.teamId === teamId ? (
                    <CheckIcon fontSize="inherit" />
                  ) : (
                    ""
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Divider light />
          </MenuItem>
        ))}
        <MenuItem
          style={{
            padding: "14px 16px 14px 16px",
          }}
        >
          <Button
            variant="outlined"
            style={{ fontSize: 14, lineHeight: "20px", height: 40 }}
            onClick={handleCreateTeam}
          >
            CREATE TEAM
          </Button>
        </MenuItem>
      </Menu>
      {createTeamModalOpen && (
        <CreateTeamModal
          open={createTeamModalOpen}
          handleClose={handleModalClose}
        ></CreateTeamModal>
      )}
    </React.Fragment>
  );
}

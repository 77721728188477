import { PlayerPosition } from "../team-members/team-members.model";

export enum EventType {
  TRAINING = "TRAINING",
  MATCH = "MATCH",
  OTHER = "OTHER",
}

export enum InviteStatus {
  INVITATION_PENDING = "INVITATION_PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  TENTATIVE = "TENTATIVE",
}

export enum ParticipationStatus {
  MISSED = "MISSED",
  NOT_SYNCED = "NOT_SYNCED",
  SYNCED = "SYNCED",
}

export type EventFeedbackReply = {
  comment: string;
  reaction: string;
  playerId: string;
  commentTimestamp: number;
  reactionTimestamp: number;
};

export type EventFeedback = {
  comment?: string;
  intensityRating: number;
  satisfactionRating: number;
  replies?: EventFeedbackReply[];
};

export type UserEvent = {
  userEventId: string;
  playerId: string;
  eventId: string;
  teamId: string;
  inviteStatus: InviteStatus;
  participationStatus: ParticipationStatus;
  createdAt: number;
  updatedAt: number;
  feedback: EventFeedback;
  position?: PlayerPosition;
};

export type TeamEvent = {
  eventId: string;
  teamId: string;
  seriesId?: string;
  title: string;
  type: EventType;
  startTime: number;
  endTime: number;
  ownerId: string;
  locationName: string;
  locationLat?: number;
  locationLong?: number;
  description?: string;
  parentEventId?: string;
  trainingDetails?: string;
  coachReview?: string;
  reoccurrence?: string;
  matchDetails?: {
    opponentTeamName?: string;
    opponentScore: number;
    teamScore: number;
    startTime: number;
  };
  invitesCount: number;
  invitesAcceptedCount: number;
  autoEventId?: string;
};

export type TeamEventUserSpecific = {
  participation: UserEvent;
  invitesCount: number;
  invitesAcceptedCount: number;
  intensityAverage: number;
  satisfactionAverage: number;
} & TeamEvent;

export type TeamEventUserSpecificWithParticipants = TeamEventUserSpecific & {
  participants: UserEvent[];
};

export type TeamEventUserSpecificWithMembers = TeamEventUserSpecific & {
  participants: UserEvent[];
};

import React, { MouseEventHandler } from "react";

import styled from "styled-components";
import theme from "../theme";
import { Button, Grid, SvgIcon, Typography } from "@mui/material";

import AppleIcon from "@mui/icons-material/Apple";
import FacebookIcon from "@mui/icons-material/Facebook";

const GoogleLogo = () => (
  <SvgIcon style={{ fontSize: 20 }} viewBox="0 0 24 24">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.04 12.2615C23.04 11.446 22.9668 10.6619 22.8309 9.90918H12V14.3576H18.1891C17.9225 15.7951 17.1123 17.013 15.8943 17.8285V20.714H19.6109C21.7855 18.7119 23.04 15.7637 23.04 12.2615Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9995 23.5001C15.1045 23.5001 17.7077 22.4703 19.6104 20.7139L15.8938 17.8285C14.864 18.5185 13.5467 18.9262 11.9995 18.9262C9.00425 18.9262 6.46902 16.9032 5.5647 14.1851H1.72266V17.1646C3.61493 20.923 7.50402 23.5001 11.9995 23.5001Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.56523 14.185C5.33523 13.495 5.20455 12.7579 5.20455 12C5.20455 11.242 5.33523 10.505 5.56523 9.81499V6.83545H1.72318C0.944318 8.38795 0.5 10.1443 0.5 12C0.5 13.8557 0.944318 15.612 1.72318 17.1645L5.56523 14.185Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9995 5.07386C13.6879 5.07386 15.2038 5.65409 16.3956 6.79364L19.694 3.49523C17.7024 1.63955 15.0992 0.5 11.9995 0.5C7.50402 0.5 3.61493 3.07705 1.72266 6.83545L5.5647 9.815C6.46902 7.09682 9.00425 5.07386 11.9995 5.07386Z"
        fill="#EA4335"
      />
    </svg>
  </SvgIcon>
);

interface IProps {
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const FacebookButton: React.FunctionComponent<IProps> = ({
  onClick,
}) => (
  <React.Fragment>
    <Button
      style={{
        background: "#206fe6",
        maxWidth: 450,
        height: 60,
        color: "#ffffff",
        width: 400
      }}
      onClick={onClick}
    >
      <Typography
        style={{
          fontFamily: theme.font.regular,
          fontSize: 20,
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          spacing={2}
        >
          <Grid item>
            <FacebookIcon fontSize="large" style={{ marginTop: 1 }} />
          </Grid>
          <Grid item>Login with Facebook</Grid>
        </Grid>
      </Typography>
    </Button>
  </React.Fragment>
);
export const GoogleButton: React.FunctionComponent<IProps> = ({ onClick }) => (
  <React.Fragment>
    <Button
      onClick={onClick}
      style={{
        background: "#ffffff",
        border: "1px white solid",
        width: 400,
        maxWidth: 450,
        height: 60,
        color: "#000000",
      }}
    >
      <Typography
        style={{
          fontFamily: theme.font.regular,
          fontSize: 20,
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          spacing={2}
        >
          <Grid item>
            <SvgIcon style={{ fontSize: 20, marginTop: 3 }}>
              <GoogleLogo />
            </SvgIcon>
          </Grid>
          <Grid item>Login with Google</Grid>
        </Grid>
      </Typography>
    </Button>
  </React.Fragment>
);

export const AppleButton: React.FunctionComponent<IProps> = ({ onClick }) => (
  <React.Fragment>
    <Button
      onClick={onClick}
      style={{
        background: "#000000",
        border: "1px white solid",
        width: 400,
        maxWidth: 450,
        height: 60,
        color: "#ffffff",
      }}
    >
      <Typography
        style={{
          fontFamily: theme.font.regular,
          fontSize: 20,
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          spacing={2}
        >
          <Grid item>
            <AppleIcon style={{ fontSize: 20 }} />
          </Grid>
          <Grid item>Login with Apple</Grid>
        </Grid>
      </Typography>
    </Button>
  </React.Fragment>
);

import type { IntervalStats } from "store/member-stats/member-stats.model";
import { DAY_IN_MS, M_IN_KM, MPS_TO_KMPH } from "../analytic.page";
import {
  BallSpeedZone,
  RunSpeedZone,
} from "store/member-stats/member-stats.model";

export function getPlayerChartData(stats: IntervalStats) {
  const intervalItems = stats.data;
  const interval = intervalItems.length > 0 ? intervalItems[0].interval : 0;

  const spreadMS =
    intervalItems.length > 0
      ? intervalItems[intervalItems.length - 1].intervalStart -
        intervalItems[0].intervalStart
      : 0;
  const daysInclude = DAY_IN_MS < spreadMS;

  const distItems = intervalItems.map(
    (element) => element.playerMotion.totalDistance / M_IN_KM,
  );
  const kickItems = intervalItems.map((element) => element.kicks.count);

  const avgSpeedItems = intervalItems.map(
    (element) => element.playerMotion.avgRunningSpeed * MPS_TO_KMPH,
  );
  const peakSpeedItems = intervalItems.map(
    (element) => element.playerMotion.topRunningSpeed * MPS_TO_KMPH,
  );

  const ballZones = {
    [BallSpeedZone.COLD]: 0,
    [BallSpeedZone.MEDIUM]: 0,
    [BallSpeedZone.HOT]: 0,
    [BallSpeedZone.FIRE]: 0,
  };
  const avgRunZones: Record<string, number> = {
    [RunSpeedZone.WALK]: 0,
    [RunSpeedZone.JOG]: 0,
    [RunSpeedZone.RUN]: 0,
    [RunSpeedZone.HIGH_SPEED_RUN]: 0,
    [RunSpeedZone.SPRINT]: 0,
  };
  const peakRunZones: Record<string, number> = {
    [RunSpeedZone.WALK]: 0,
    [RunSpeedZone.JOG]: 0,
    [RunSpeedZone.RUN]: 0,
    [RunSpeedZone.HIGH_SPEED_RUN]: 0,
    [RunSpeedZone.SPRINT]: 0,
  };

  intervalItems.forEach((element) => {
    element.kicks.zones.forEach((zone) => {
      ballZones[zone.zone] += zone.count;
    });
  });

  intervalItems.forEach((element) => {
    element.playerMotion.avgSpeedZones.forEach((zone) => {
      avgRunZones[zone.zone] += zone.zoneDist / M_IN_KM;
    });
  });

  intervalItems.forEach((element) => {
    element.playerMotion.peakSpeedZones.forEach((zone) => {
      peakRunZones[zone.zone] += zone.zoneDist / M_IN_KM;
    });
  });

  const labels = intervalItems
    .map((element, index) => {
      const date = new Date(element.intervalStart + interval / 2);

      return {
        intervalStart: element.intervalStart,
        intervalIndex: index,
        time: date.getTime(),
        str: `${date.toLocaleTimeString()} ${
          daysInclude || index === 0 ? date.toLocaleDateString() : ""
        }`,
      };
    })
    .sort((a, b) => a.time - b.time);

  return {
    distItems,
    kickItems,
    avgSpeedItems,
    peakSpeedItems,
    ballZones,
    avgRunZones,
    peakRunZones,
    labels,
  };
}

import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import {
  Divider,
  IconButton,
  LinearProgress,
  Paper,
  Grid,
} from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import theme from "theme";
import TeamSelect from "containers/layout/team.select.container";
import { Notifications } from "containers/layout/notifications.container";
import UserBadge from "containers/layout/user-badge.container";
import { Tab, Tabs } from "components/tabs.component";
import Background from "components/background.component";
import { TeamMemberStatus } from "store/team-members/team-members.model";
import { getUserProfile } from "store/user/user.query";
import { useAuthQuery } from "providers/auth";
import { getTeams } from "store/user-teams/teams.query";
import { IUserTeamInfo } from "store/user-teams/user-teams.model";
import { User } from "store/user";
import { currentLocale, initLocale } from "utils/i18n";
import { useAnalytics } from "use-analytics";
import "./navigation.style.css";
import { CountrySelector } from "containers/layout/country-selector.container";

export enum NavigationTabs {
  DASHBOARD,
  LEADERBOARD,
  TIMELINE,
  ANALYTICS,
  FEEDBACK,
  SETTINGS,
  PROFILE,
}

export const NavigationContext = createContext({
  teams: [] as IUserTeamInfo[],
  currentTeam: {} as IUserTeamInfo,
  player: {} as User,
  selectedLocale: currentLocale(),
  setSelectedLocale: (locale: string) => {},
});

export const getNavigationUrl = (teamId: string, tab: number) =>
  `/teams/${teamId}/${NavigationTabs[tab].toLocaleLowerCase()}`;

export const NavigationProvider = ({ children }: PropsWithChildren) => {
  const analytics = useAnalytics();
  const history = useHistory();
  const params = useParams<{ teamId: string }>();
  const [teamId, setTeamId] = useState(
    params.teamId || history.location.pathname.split("/")[2] || "",
  );
  const [locale, setLocale] = useState(currentLocale());

  const [currentTeam, setCurrentTeam] = useState<IUserTeamInfo | null>(null);

  const query = new URLSearchParams(window.location.search);
  const pathTab = history.location.pathname.split("/")[3]?.toUpperCase();

  const [tab, setTab] = useState(
    pathTab ? NavigationTabs[pathTab] : NavigationTabs.DASHBOARD,
  );

  const pathPlayerId = query.get("playerId");
  const [playerId, setPlayerId] = useState(pathPlayerId || "");

  const userQuery = useAuthQuery("userProfile", getUserProfile);

  const teamsQuery = useAuthQuery("teams", getTeams);

  const [settingsToolSetVisible, setSettingsToolSetVisible] = useState(false);

  useEffect(() => {
    if (userQuery.isSuccess) {
      setPlayerId(userQuery.data?.playerId || pathPlayerId || "");
      analytics.identify(userQuery.data?.playerId);
    }
  }, [userQuery.isSuccess, userQuery.data, pathPlayerId]);

  useEffect(() => {
    if (userQuery.isError || teamsQuery.isError) {
      history.push("/logout");
    }
  }, [userQuery.isError, teamsQuery.isError, history]);

  useEffect(() => {
    if (teamsQuery.isSuccess) {
      const team = teamsQuery.data?.userTeams.find(
        (item) => item.teamId === teamId,
      );
      const status = team?.status || TeamMemberStatus.REJECTED;
      setSettingsToolSetVisible(
        [TeamMemberStatus.APPROVED].includes(TeamMemberStatus[status]),
      );
      setCurrentTeam(team || null);
    }
  }, [teamId, teamsQuery.isSuccess, teamsQuery.data, playerId]);

  useEffect(() => {
    history.push({
      ...history.location,
      pathname: getNavigationUrl(teamId, tab),
    });
  }, [teamId, tab, history]);

  useEffect(() => {
    if (userQuery.data) {
      initLocale(userQuery.data);
    }
  }, [userQuery.data]);

  history.listen((location) => {
    const pathTabAlias = location.pathname.split("/").pop()?.toUpperCase();
    const pathTab =
      (pathTabAlias && NavigationTabs[pathTabAlias]) ||
      NavigationTabs.DASHBOARD;

    if (pathTab !== undefined && pathTab !== tab) {
      setTab(pathTab);
    }
  });

  return (
    <Background>
      <Paper
        style={{ backgroundColor: theme.background.actions, paddingLeft: 20 }}
        id="headerMenu"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <TeamSelect currentTeamId={teamId} onChange={setTeamId} />
          </Grid>
          <Grid item>
            <Tabs
              value={tab}
              onChange={(e, v) => setTab(v)}
              style={{ color: theme.text.primary }}
              TabIndicatorProps={{ style: { backgroundColor: "white" } }}
              indicatorColor="secondary"
              textColor="inherit"
              centered
            >
              <Tab
                label={NavigationTabs[NavigationTabs.DASHBOARD]}
                className={"navigation-tab"}
              />
              <Tab
                label={NavigationTabs[NavigationTabs.LEADERBOARD]}
                className={"navigation-tab"}
              />
              <Tab
                label={NavigationTabs[NavigationTabs.TIMELINE]}
                className={"navigation-tab"}
              />
              <Tab
                label={NavigationTabs[NavigationTabs.ANALYTICS]}
                className={"navigation-tab"}
              />
              <Tab
                label={NavigationTabs[NavigationTabs.FEEDBACK]}
                className={"navigation-tab"}
              />
            </Tabs>
          </Grid>
          <Grid item>
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item>
                <CountrySelector
                  locale={locale}
                  setSelectedLocale={setLocale}
                />
              </Grid>
              <Grid item>
                <Notifications />
              </Grid>
              <Grid item>
                <IconButton
                  className="navigationIcon-button"
                  onClick={() => setTab(NavigationTabs.PROFILE)}
                >
                  <UserBadge active={ tab == NavigationTabs.PROFILE } />
                </IconButton>
              </Grid>
              <Grid item hidden={!settingsToolSetVisible}>
                <IconButton
                  className="navigationIcon-button"
                  onClick={() => setTab(NavigationTabs.SETTINGS)}
                  color={
                    tab === NavigationTabs.SETTINGS ? "primary" : "default"
                  }
                >
                  <SettingsOutlinedIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  className="navigationIcon-button"
                  onClick={() => history.push("/logout")}
                >
                  <ExitToAppIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
      </Paper>

      {teamsQuery.isLoading || userQuery.isLoading ? (
        <LinearProgress color="primary" />
      ) : (
        <NavigationContext.Provider
          value={{
            teams: teamsQuery.data?.userTeams || [],
            currentTeam: currentTeam || ({} as IUserTeamInfo),
            player: userQuery.data || ({} as User),
            selectedLocale: locale,
            setSelectedLocale: setLocale,
          }}
        >
          {children}
        </NavigationContext.Provider>
      )}
    </Background>
  );
};

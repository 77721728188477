import React, { useRef } from "react";
import { Line, getDatasetAtEvent, getElementAtEvent, getElementsAtEvent } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { PlayerTwoStatsProps } from "../utils/utils";
import { font } from "theme";
import { CHARTS_COLORS } from "hooks/useGetChartsData";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const time = new Date().getTime();
const labels = [
  new Date(time - 300000).toLocaleTimeString(),
  new Date(time - 240000).toLocaleTimeString(),
  new Date(time - 180000).toLocaleTimeString(),
  new Date(time - 120000).toLocaleTimeString(),
  new Date(time - 60000).toLocaleTimeString(),
  new Date(time).toLocaleTimeString(),
];

export const PlayerTwoStatsLines = (props: PlayerTwoStatsProps) => {
  const secondScale = props.useSingleYAxis
    ? {}
    : {
        statTwoAxis: {
          position: "right" as const,
          title: {
            text: props.data?.statTwo?.statsMeasure || "KM",
            align: "end" as const,
            display: true,
            color: "rgba(255, 255, 255, 1)",
            font: { family: font.adihaus.regular },
          },
          grid: {
            color: "rgba(114, 123, 132, 0.3)",
          },
          ticks: {
            color: "rgba(255, 255, 255, 1)",
            min: 0,
          },
        },
      };

  const scales = {
    x: {
      ticks: {
        color: "rgba(255, 255, 255, 1)",
      },
    },
    statOneAxis: {
      position: "left" as const,
      title: {
        text: props.data?.statOne?.statsMeasure || "COUNTS",
        align: "end" as const,
        display: true,
        color: "rgba(255, 255, 255, 1)",
        font: { family: font.adihaus.regular },
      },
      grid: {
        color: "rgba(114, 123, 132, 0.3)",
      },
      ticks: {
        color: "rgba(255, 255, 255, 1)",
        min: 0,
      },
    },
    ...secondScale,
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0.3, // Change this value to adjust the curvature of the lines
      },
    },
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          color: "rgba(255, 255, 255, 1)",
          usePointStyle: true,
        },
      },
      title: {
        display: true,
        text: props.title || "Two Stats comparison",
        color: "rgba(255, 255, 255, 1)",
        font: { size: 18, family: font.adihaus.regular },
      }
    },
    scales,
  };


  const chartRef = useRef();
  const onClick = (event) => {
    if (props.onElementClick) {
      const element = getElementsAtEvent(chartRef.current as any, event)[0];
      if (element) {
        props.onElementClick(element.datasetIndex, element.index);
      }
    }
  };

  const data = {
    labels: props.labels || labels,
    datasets: [
      {
        label: props.data?.statOne?.statName || "Distance",
        yAxisID: "statOneAxis",
        fill: true,
        data: props.data?.statOne?.items || [],
        backgroundColor:
          props.data?.statOne?.chartEntryColor || CHARTS_COLORS[0],
        borderColor:
          props.data?.statOne?.chartEntryColor || CHARTS_COLORS[0],
        
      },
      {
        label: props.data?.statTwo?.statName || "Kicks Count",
        yAxisID: props.useSingleYAxis ? "statOneAxis" : "statTwoAxis",
        data: props.data?.statTwo?.items || [],
        fill: true,
        backgroundColor:
          props.data?.statTwo?.chartEntryColor || CHARTS_COLORS[1],
        borderColor:
          props.data?.statTwo?.chartEntryColor || CHARTS_COLORS[1],
      },
    ],
  };
  return <Line ref={chartRef} options={options} data={data} onClick={onClick}/>;
};

import { getHeaders, isError, send } from "services/commons.api";
import { IGetUserTeamsData, IUserTeamInfo } from "./user-teams.model";
import clubTeamsApi from "../../config";
import { ITeamInfo } from "store/team/team.model";

export const getTeams = (token: string) => (_context) =>
  send<IGetUserTeamsData>({
    apiEndpoint: clubTeamsApi.API.USER_TEAMS.GET_ALL,
    headers: getHeaders(token),
  }).then((v) => {
    if (isError(v)) {
      throw v;
    }

    return v.data;
  });

export const createTeam = (token: string) => (data: { teamName: string }) =>
  send<IUserTeamInfo>({
    apiEndpoint: clubTeamsApi.API.USER_TEAMS.POST,
    body: data,
    headers: getHeaders(token),
  }).then((v) => {
    if (isError(v)) {
      throw v;
    }

    return v.data;
  });

export const updateTeam =
  (token: string) => (data: { teamInfo: ITeamInfo; teamId: string }) =>
    send<ITeamInfo>({
      apiEndpoint: clubTeamsApi.API.USER_TEAMS.UPDATE_TEAM,
      body: data.teamInfo,
      urlParams: {
        teamId: data.teamId,
      },
      headers: getHeaders(token),
    }).then((v) => {
      if (isError(v)) {
        throw v;
      }

      return v.data;
    });

import { compose } from "ramda";

export const templater = (templateString: string, templateVariables: any) => {
  let result = templateString;
  Object.keys(templateVariables).forEach((paramName) => {
    // tslint:disable-next-line
    result = result.replace(
      "${" + paramName + "}",
      `${templateVariables[paramName] || ""}`,
    );
  });

  return result;
};
export const removeUnderscore = (input: string) => input.split("_").join(" ");
export const capitalizeFirstLetters = (input: string) =>
  input
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
export const lowercase = (input: string) => input.toLowerCase();
export const separateCamelCase = (input: string) =>
  input.replace(/([a-z](?=[A-Z]))/g, "$1 ");

export const snakeCaseToSentence = compose(
  capitalizeFirstLetters,
  removeUnderscore,
  lowercase,
  separateCamelCase,
);

export const nameOf = <T>(name: Extract<keyof T, string>): string => name;

export const getClosestByClass = (className: string, node: HTMLElement) => {
  let currentParent = node.parentElement;
  while (currentParent !== null) {
    if (currentParent.className === className) {
      return currentParent;
    }
    currentParent = currentParent.parentElement;
  }

  return null;
};

export const downloadByLink = (link: string) => {
  const a = document.createElement("a");
  a.href = link;
  a.click();

  return a;
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import theme from "../../theme";

import CloseIcon from "@mui/icons-material/Close";
import { EventTypeButton } from "./event-type-button";
import { ReactComponent as ChallengeSvg } from "../../images/icons/icon_challenges.svg";
import { ReactComponent as SportEventSvg } from "../../images/icons/icon_sport_events.svg";
import {
  ActivityBarChart,
  ActivityEntries,
  timestampToHourMinute,
} from "./activity-bar-chart-events";
import { TeamAvatar } from "components/avatar.component";
import { useAuthQuery } from "providers/auth";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { EventType } from "store/events/events.model";
import { getAutoEvents } from "store/auto-events/auto-events.query";
import { NavigationContext } from "providers/navigation.provider";
import { TeamMemberRole } from "store/team-members/team-members.model";
import { getMemberStats } from "store/member-stats/member-stats.query";
import { IntervalMetric } from "store/member-stats/member-stats.model";
import { DateTime, Duration } from "luxon";
import { Skeleton } from "@mui/material";
import { Trans } from "@lingui/macro";

export type AutoSuggestedTeamEventModalProps = {
  autoEventId: string;
  open: boolean;
  handleClose: () => void;
};

export const AutoSuggestedTeamEventModal = (
  props: AutoSuggestedTeamEventModalProps,
) => {
  const { open, handleClose } = props;
  const { currentTeam } = useContext(NavigationContext);
  const { teamId, playerId, role } = currentTeam;
  const [timeRange, setTimeRange] = useState([0, 0]);
  const [eventType, setEventType] = useState<EventType | null>(null);

  const [suggestedRange, setSuggestedRange] = useState([0, 0]);
  const opponentTeamNameRef = useRef<HTMLInputElement>();
  const opponentTeamScoreRef = useRef<HTMLInputElement>();
  const myTeamScoreRef = useRef<HTMLInputElement>();
  const autoEventQuery = useAuthQuery("autoEvents", getAutoEvents);
  const statsFetchSubject = role === TeamMemberRole.PLAYER ? playerId : "all";

  const statsQuery = useAuthQuery(
    ["stats", statsFetchSubject],
    getMemberStats({
      teamId,
      memberId: statsFetchSubject,
      from: timeRange[0] - Duration.fromObject({ minutes: 15 }).toMillis(),
      to: timeRange[1] + Duration.fromObject({ minutes: 15 }).toMillis(),
      interval: "auto",
    }),
    { enabled: timeRange[0] !== timeRange[1] },
  );

  useEffect(() => {
    if (autoEventQuery.isSuccess && autoEventQuery.data) {
      const autoEvent = autoEventQuery.data.data.find(
        ({ workoutId }) => props.autoEventId === workoutId,
      );

      if (!autoEvent) {
        handleClose();
        return;
      }

      setSuggestedRange([autoEvent.startTimestamp, autoEvent.endTimestamp]);
      setTimeRange([autoEvent.startTimestamp, autoEvent.endTimestamp]);
    }

    if (autoEventQuery.isError) {
      handleClose();
    }
  }, [autoEventQuery, handleClose, props.autoEventId]);

  const statsToBars = (stats: IntervalMetric[]): ActivityEntries[] =>
    stats.map(({ count, intervalStart }) => ({
      timestamp: intervalStart,
      value: count,
    }));

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      fullWidth={true}
      maxWidth="sm"
      scroll="paper"
      style={{ height: 720, background: theme.background.actions }}
    >
      <DialogTitle
        id="form-dialog-title"
        style={{ backgroundColor: theme.background.actions }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignContent="center"
        >
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="h5">
                  <Trans>Activity AUTO DETECTION</Trans>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  style={{ fontSize: 12, textTransform: "none" }}
                >
                  {DateTime.fromMillis(suggestedRange[0]).toFormat(
                    "dd LLLL yyyy",
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon style={{ fontSize: 16 }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{ backgroundColor: theme.background.dialog }}
      >
        <DialogContentText>
          <Grid container direction="column" spacing={2}>
            <Grid item key="titleAndLocation">
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid item key="title">
                  <TextField id="title" label="Event Title" variant="filled" />
                </Grid>
                <Grid item key="location">
                  <TextField id="location" label="Location" variant="filled" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item key="eventType">
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid item>
                  <EventTypeButton
                    selected={eventType === EventType.TRAINING}
                    style={{ width: 232 }}
                    icon={<SportEventSvg />}
                    text="Training"
                    onClick={() => setEventType(EventType.TRAINING)}
                  />
                </Grid>
                <Grid item>
                  <EventTypeButton
                    selected={eventType === EventType.MATCH}
                    style={{ width: 232 }}
                    icon={<ChallengeSvg />}
                    text="Match"
                    onClick={() => setEventType(EventType.MATCH)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item key="opponent" hidden={eventType !== EventType.MATCH}>
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid item xs={5}>
                  <FormControl>
                    <TextField
                      id="opponentName"
                      label="Team name"
                      variant="filled"
                      inputRef={opponentTeamNameRef}
                    />
                    <FormHelperText>
                      <Typography variant="body2">
                        <Trans>Opponent Team</Trans>
                      </Typography>
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item key="score" xs={5}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-around"
                    spacing={1}
                  >
                    <Grid item>
                      <TeamAvatar />
                    </Grid>
                    <Grid item>
                      <TextField
                        id="opponentScore"
                        variant="filled"
                        style={{ width: 35 }}
                        inputProps={{ maxLength: 1 }}
                        inputRef={opponentTeamScoreRef}
                      />
                    </Grid>
                    <Grid item>
                      <Typography>:</Typography>
                    </Grid>
                    <Grid item>
                      <TextField
                        id="opponentScore"
                        variant="filled"
                        style={{ width: 35 }}
                        inputProps={{ maxLength: 1 }}
                        inputRef={myTeamScoreRef}
                      />
                    </Grid>

                    <Grid item>
                      <TeamAvatar />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item key="time">
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Grid container direction="row">
                    <Grid item>
                      <Typography variant="subtitle1">
                        <Trans>Duration</Trans>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button variant="text">
                        <Typography
                          variant="body2"
                          style={{ textTransform: "none" }}
                          onClick={() => setTimeRange(suggestedRange)}
                        >
                          <Trans context="time frame">Auto adjust</Trans>
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container>
                    <Grid
                      item
                      style={{
                        width: 464,
                        height: 100,
                      }}
                    >
                      {!statsQuery.data ? (
                        <Skeleton variant="rectangular" height={200} />
                      ) : (
                        <ActivityBarChart
                          data={statsToBars(statsQuery.data.data)}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  {!statsQuery.data ? (
                    <Skeleton variant="rectangular" height={200} />
                  ) : (
                    <Slider
                      value={timeRange}
                      onChange={(_event, values) =>
                        setTimeRange(values as number[])
                      }
                      min={statsQuery.data.data[0].intervalStart}
                      max={
                        statsQuery.data.data[statsQuery.data.data.length - 1]
                          .intervalStart
                      }
                      step={statsQuery.data.data[0].interval}
                      valueLabelDisplay="on"
                      aria-labelledby="range-slider"
                      style={{ width: 464 }}
                      valueLabelFormat={timestampToHourMinute}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item key="attendance">
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="body2">
                    <Trans>Attendance</Trans>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container direction="column" spacing={1}>
                    {/* {teamMembersQuery.data
                      ? teamMembersQuery.data.teamMembers.map((member) => (
                          <Grid item key={member.id}>
                            <Grid container direction="row" justifyContent="space-between">
                              <Grid item>
                                <Grid container direction="row" justifyContent="center" spacing={1}>
                                  <Grid item>
                                    <PlayerAvatar
                                      teamId={teamId}
                                      playerId={member.playerId}
                                    />
                                  </Grid>
                                  <Grid item>
                                    {member.memberInfo?.firstName} {member.memberInfo?.lastName}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Checkbox value="" />
                              </Grid>
                            </Grid>
                          </Grid>
                        ))
                      : ''} */}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{ padding: 16, backgroundColor: theme.background.actions }}
      >
        <Button variant="outlined" onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          style={{
            width: 80,
            height: 40,
            marginRight: 16,
            marginLeft: 24,
            fontSize: 18,
            color: theme.text.secondary,
          }}
          onClick={() => handleClose()}
        >
          <Trans>Save</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React, { ReactElement, useState } from "react";
import { TeamEventUserSpecificWithMembers } from "store/events/events.model";
import { Skeleton, Button } from "@mui/material";
import { StyledDataGrid, getStripedClassNames } from "components/table.component";
import {
  getLeaderBoardAsList,
  getPlayerName,
  renderPlayer,
  renderPosition,
} from "containers/leaderboard/rows-leaderboard";
import { GridApi, GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { MPS_TO_KMPH, M_IN_KM } from "utils/team";
import { useAuthQuery } from "providers/auth";
import { getTeamLeaderboard } from "store/team-leaderboard/leaderboard.query";
import { getTeamMembers } from "store/team-members/team-members.query";
import { Grid, IconButton, Typography } from "@mui/material";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import TimerIcon from "@mui/icons-material/Timer";
import { PlayerPosition } from "store/team-members/team-members.model";
import TooltipCustom from "components/tooltip.component";
import { PositionFilterModal } from "containers/position/position-filter.modal";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MetricsDistance from "components/icons/MetricsDistance";
import MetricsRunningSpeed from "components/icons/MetricsRunningSpeed";
import MetricsBallSpeed from "components/icons/MetricsBallSpeed";
import MetricsExplosivenessDistance from "components/icons/MetricsExplosivenessDistance";
import MetricsExplosivenessCount from "components/icons/MetricsExplosivenessCount";
import MetricsKickCount from "components/icons/MetricsKickCount";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { DateTime } from "luxon";
import { Trans } from "@lingui/macro";
import qs from "query-string";
import {
  getNavigationUrl,
  NavigationTabs,
} from "providers/navigation.provider";
import { useHistory } from "react-router-dom";
import { useAnalytics } from "use-analytics";
import MetricsSprintDistance from "components/icons/MetricsSprintDistance";
import MetricsSprintCount from "components/icons/MetricsSprintCount";
import { PositionFilter } from "containers/position/position-models";

export const FluidHeader =
  ({ threshold, icon }: { threshold: number; icon: ReactElement }) =>
  (params: GridColumnHeaderParams) =>
    params.colDef.computedWidth > threshold ? (
      <Grid container alignItems="flex-start">
        <Grid item zeroMinWidth>
          {params.colDef.headerName}
        </Grid>
      </Grid>
    ) : (
      <TooltipCustom title={params.colDef.headerName || ""}>
        {icon}
      </TooltipCustom>
    );

export const EventLeaderBoardTab = (
  event: TeamEventUserSpecificWithMembers,
) => {
  const analytics = useAnalytics();
  const leaderboardQuery = useAuthQuery(
    ["leaderboard", event.teamId, event.startTime, event.endTime],
    getTeamLeaderboard({
      teamId: event.teamId,
      from: event.startTime,
      to: event.endTime,
    }),
  );
  const teamMembersQuery = useAuthQuery(
    ["teamMembers", event.teamId],
    getTeamMembers(event.teamId),
  );
  const isLoading = leaderboardQuery.isLoading || teamMembersQuery.isLoading;
  const history = useHistory();
  const [positionFilter, setPositionFilter] = useState<PositionFilter>({positions: [], includeNotDefined: true});
  const [showPositionFilterModal, setShowPositionFIlterModal] = useState(false);
  const [apiRef, setApiRef] = React.useState<GridApi | null>(null);
  const onPlayerClick = (playerId: string) => {
    const search = {
      ...qs.parse(history.location.search),
      playerId,
    };
    history.push({
      ...history.location,
      pathname: getNavigationUrl(event.teamId, NavigationTabs.ANALYTICS),
      search: qs.stringify(search),
    });
  };
  const onExport = () => {
    analytics.track("leaderboard-export-event", event);
    apiRef?.exportDataAsCsv({
      fileName: `${event.type.toUpperCase()} ${
        event.title
      } [ ${DateTime.fromMillis(event.startTime).toISO()}:${DateTime.fromMillis(
        event.endTime,
      ).toISO()} ]`,
    });
  };

  if (event.startTime > Date.now()) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        style={{ padding: "10%" }}
      >
        <Grid item>
          <Typography>
            <TimerIcon style={{ width: 75, height: 75 }} />
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">
            <Trans id="leaderboard_no_event_title">
              There are no data yet.
            </Trans>
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              fontSize: 13,
              lineHeight: "15px",
              textAlign: "center",
            }}
          >
            <Trans id="leaderboard_no_event_description">
              Your event not even started. <br /> Wait until event ends or some
              of your players sync.
            </Trans>
          </Typography>
        </Grid>
      </Grid>
    );
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: " ",
      width: 50,
      renderCell: (params) => {
        if (!apiRef) {
          setApiRef(params.api);
        }
        return renderPosition(params);
      },
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "player",
      headerName: "Player",
      minWidth: 300,
      valueFormatter: getPlayerName,
      renderCell: renderPlayer(onPlayerClick),
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "totalDistance",
      headerName: "Distance",
      flex: 0.6,
      minWidth: 80,
      renderHeader: FluidHeader({ threshold: 80, icon: <MetricsDistance /> }),
      valueFormatter: (params) =>
        `${(Number(params.value) / M_IN_KM).toFixed(2)}  KM`,
    },
    {
      field: "topRunningSpeed",
      headerName: "Top Running Speed",
      flex: 1,
      minWidth: 80,
      renderHeader: FluidHeader({
        threshold: 160,
        icon: <MetricsRunningSpeed />,
      }),
      renderCell: (params) =>
        `${(Number(params.value) * MPS_TO_KMPH).toFixed(2)}  KM/H`,
      valueFormatter: (params) =>
        (Number(params.value) * MPS_TO_KMPH).toFixed(2),
    },
    {
      field: "topBallSpeed",
      flex: 1,
      minWidth: 80,
      headerName: "Top Ball Speed",
      renderHeader: FluidHeader({ threshold: 120, icon: <MetricsBallSpeed /> }),
      renderCell: (params) => `${Number(params.value).toFixed(2)} KM/H`,
      valueFormatter: (params) => Number(params.value).toFixed(2),
    },
    {
      field: "totalDistanceXplos",
      flex: 1,
      minWidth: 80,
      headerName: "Explos. Distance",
      renderHeader: FluidHeader({
        threshold: 140,
        icon: <MetricsExplosivenessDistance />,
      }),
      renderCell: (params) => `${Math.round(Number(params.value))}  M`,
      valueFormatter: (params) => Math.round(Number(params.value)),
    },
    {
      field: "countXplos",
      flex: 1,
      minWidth: 75,
      renderHeader: FluidHeader({
        threshold: 140,
        icon: <MetricsExplosivenessCount />,
      }),
      headerName: "Explos. Count",
    },
    {
      field: "totalDistanceSprint",
      flex: 1,
      minWidth: 80,
      headerName: "Sprint Distance",
      renderHeader: FluidHeader({
        threshold: 140,
        icon: <MetricsSprintDistance />,
      }),
      renderCell: (params) => `${Math.round(Number(params.value))}  M`,
      valueFormatter: (params) => Math.round(Number(params.value)),
    },
    {
      field: "countSprint",
      flex: 1,
      minWidth: 75,
      renderHeader: FluidHeader({
        threshold: 140,
        icon: <MetricsSprintCount />,
      }),
      headerName: "Sprint Count",
    },
    {
      field: "countKicks",
      flex: 1,
      minWidth: 75,
      renderHeader: FluidHeader({ threshold: 120, icon: <MetricsKickCount /> }),
      headerName: "Kick Count",
    },
    {
      field: "actions",
      flex: 1,
      minWidth: 20,
      maxWidth: 70,
      sortable: false,
      renderHeader: () => (
        <IconButton onClick={onExport}>
          <ArrowDownwardIcon />
        </IconButton>
      ),
      headerName: "Action",
    },
  ];

  if (isLoading) {
    return <Skeleton variant="rectangular" height={500} />;
  }

  const leaderboard = getLeaderBoardAsList(
    leaderboardQuery?.data?.metrics,
    teamMembersQuery?.data?.teamMembers,
  );

  if (!leaderboard.length) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        style={{ padding: "10%" }}
      >
        <Grid item>
          <Typography>
            <SentimentDissatisfiedIcon style={{ width: 75, height: 75 }} />
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">
            <Trans id="leaderboard_no_data_title">Nothing to show here</Trans>
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              fontSize: 13,
              lineHeight: "15px",
              textAlign: "center",
            }}
          >
            <Trans id="leaderboard_no_data_description">
              Looks like we have no data for that time frame. <br /> Please,
              remind players to sync their tags.
            </Trans>
          </Typography>
        </Grid>
      </Grid>
    );
  }

  const rows = leaderboard
    .map((record) => {
      const participation = event.participants.find(
        ({ playerId }) => record.playerId === playerId,
      );

      return {
        ...record,
        player: {
          ...record.player,
          position: participation?.position || record.player.position,
        },
      };
    })
    .filter((row) =>
      (positionFilter.includeNotDefined && !row?.player?.position) || (positionFilter.positions.length
        ? positionFilter.positions.includes(row?.player?.position as PlayerPosition)
        : true)
    );

  return (
    <Grid container direction="column">
      <Grid item>
        {showPositionFilterModal && (
          <PositionFilterModal
            open={showPositionFilterModal}
            filter={positionFilter}
            onSave={(positions) => {
              setPositionFilter(positions);
              setShowPositionFIlterModal(!showPositionFilterModal);
            }}
          />
        )}
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">
              <Trans id="timeline_leaderboard_title">EVENT LEADERBOARD</Trans>
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined"
              onClick={() => setShowPositionFIlterModal(true)}
              style={{ borderRadius: 4, borderColor: "white" }}
            >
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  {positionFilter.positions.length ? (
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      style={{ textTransform: "none" }}
                    >
                      <Trans>
                        Filter by Position Applied ({positionFilter.positions.length})
                      </Trans>
                    </Typography>
                  ) : (
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      style={{ textTransform: "none" }}
                    >
                      <Trans>Filter by Position</Trans>
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <ArrowDropDownIcon
                    color="disabled"
                    style={{ fontSize: 20 }}
                  />
                </Grid>
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        position={"absolute"}
        mt={8}
        mr={5}
        width={"-webkit-fill-available"}
      >
        <StyledDataGrid
          rows={rows}
          columns={columns}
          loading={isLoading}
          disableColumnMenu={false}
          getRowClassName={getStripedClassNames}
        />
      </Grid>
    </Grid>
  );
};

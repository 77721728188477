import React from "react";
import { SvgIcon } from "@mui/material";

function TeamDefaultIcon() {
  return (
    <SvgIcon fontSize="large" style={{ paddingLeft: 2.5 }}>
      <path
        d="M6.56519 16.7498V10.7431H10.5203V11.7895H7.73935V13.2644H10.1083V14.3109H7.73935V16.7498H6.56519Z"
        fill="#212F3C"
      />
      <path
        d="M11.4015 13.7465C11.4015 13.4608 11.4056 13.2026 11.4138 12.9719C11.4221 12.7412 11.444 12.5283 11.4797 12.3333C11.5182 12.1383 11.5772 11.9598 11.6569 11.7978C11.7393 11.633 11.856 11.475 12.0071 11.324C12.2158 11.1207 12.4493 10.9655 12.7075 10.8584C12.9656 10.7486 13.2691 10.6936 13.618 10.6936C14.181 10.6936 14.6603 10.8529 15.0558 11.1715C15.454 11.4874 15.7095 11.9598 15.8221 12.5888H14.6232C14.56 12.3416 14.4502 12.1383 14.2936 11.979C14.1371 11.817 13.9118 11.736 13.618 11.736C13.4559 11.736 13.3117 11.7648 13.1854 11.8225C13.059 11.8774 12.9533 11.9529 12.8681 12.0491C12.8132 12.1122 12.7665 12.1836 12.7281 12.2633C12.6924 12.3402 12.6635 12.4405 12.6415 12.564C12.6196 12.6876 12.6031 12.8442 12.5921 13.0337C12.5811 13.2205 12.5756 13.4581 12.5756 13.7465C12.5756 14.0321 12.5811 14.2697 12.5921 14.4592C12.6031 14.6487 12.6196 14.8053 12.6415 14.9289C12.6635 15.0525 12.6924 15.1541 12.7281 15.2337C12.7665 15.3106 12.8132 15.3807 12.8681 15.4438C12.9533 15.54 13.059 15.6155 13.1854 15.6704C13.3117 15.7254 13.4559 15.7528 13.618 15.7528C13.9091 15.7528 14.1357 15.6732 14.2977 15.5139C14.4598 15.3546 14.571 15.15 14.6314 14.9H15.8221C15.7095 15.529 15.454 16.0028 15.0558 16.3214C14.6603 16.64 14.181 16.7993 13.618 16.7993C13.2691 16.7993 12.9656 16.7443 12.7075 16.6345C12.4493 16.5246 12.2158 16.3694 12.0071 16.1689C11.856 16.0179 11.7393 15.8613 11.6569 15.6993C11.5772 15.5345 11.5182 15.3546 11.4797 15.1596C11.444 14.9646 11.4221 14.7517 11.4138 14.521C11.4056 14.2903 11.4015 14.0321 11.4015 13.7465Z"
        fill="#212F3C"
      />
      <path
        fillRule="evenodd"
        d="M3.2653 2.38212C8.20007 0.24372 13.7999 0.24372 18.7347 2.38212L21.0781 3.3976V12.9903C21.0781 16.4015 19.3526 19.5812 16.4925 21.4402L11 25.0103L5.50754 21.4402C2.64744 19.5812 0.921875 16.4015 0.921875 12.9903V3.3976L3.2653 2.38212ZM18.1756 3.67243C13.5975 1.68862 8.40247 1.68862 3.82443 3.67243L2.32812 4.32083V7.02352C7.67278 3.86416 14.3272 3.86417 19.6719 7.02354V4.32083L18.1756 3.67243ZM19.6719 8.68354C14.4216 5.18583 7.57844 5.18583 2.32812 8.68352V12.9903C2.32812 15.9255 3.81292 18.6615 6.27393 20.2612L11 23.3331L15.7261 20.2612C18.1871 18.6615 19.6719 15.9255 19.6719 12.9903V8.68354Z"
        fill="#212F3C"
      />
    </SvgIcon>
  );
}

export default TeamDefaultIcon;

import { useEffect, useState } from "react";
import { Grid, Box, Stack, Skeleton } from "@mui/material";
import { DateTime } from "luxon";
import {
  PlayersStatsLines,
  PlayersStatsBars,
} from "components/charts/players-stats-charts";
import { PlayerStatPie } from "components/charts/player-stat-pie";
import { IGetTeamMembersData } from "store/team-members/team-members.model";
import { useQueries } from "react-query";
import { getMemberStats } from "store/member-stats/member-stats.query";
import { CHARTS_COLORS, useGetChartsData } from "hooks/useGetChartsData";
import { IntervalMetric } from "store/member-stats/member-stats.model";

const chartRunZonesLabels = ["WALK", "JOG", "RUN", "HIGH_SPEED_RUN", "SPRINT"];
const chartBallZonesLabels = ["COLD", "MEDIUM", "HOT", "FIRE"];

type ComparisonProps = {
  teamMembers?: IGetTeamMembersData;
  to: DateTime;
  from: DateTime;
  queryInterval: string;
  teamId: string;
  playerIds: string[];
  setDisabledPlayerIds?: (playerIds: string[]) => void;
  onExport?: (data: IntervalMetric[][]) => void;
};
export default function Comparison({
  to,
  from,
  queryInterval,
  teamId,
  playerIds,
  setDisabledPlayerIds,
  onExport
}: ComparisonProps) {
  const token = localStorage.getItem("jwtToken") || "";
  const membersStats = useQueries(
    playerIds
      .filter((memberId) => !!memberId)
      .map((memberId) => {
        return {
          queryKey: ["memberStats", teamId, memberId, from, to, queryInterval],
          queryFn: getMemberStats({
            teamId,
            memberId,
            from: from.valueOf(),
            to: to.valueOf(),
            interval: queryInterval,
          })(token),
        };
      }),
  );
  const isLoading = membersStats.some((predicate) => predicate.isLoading);

  useEffect(() => {
    if (membersStats.every((q) => q.isSuccess)) {
      onExport?.(membersStats.map((q) => q.data?.data || []));
    }
  }, [...membersStats]);

  const {
    ballZonesData,
    peakRunZonesData,
    avgRunZonesData,
    chartDistData,
    chartKickData,
    chartAvgSpeedData,
    chartPeakSpeedData,
  } = useGetChartsData(membersStats);

  useEffect(() => {
    if (!isLoading) {
      const disabledPlayers = playerIds.filter(
        (playerId, index) =>
          !membersStats[index].data?.data?.length,
      );
      setDisabledPlayerIds?.(disabledPlayers);
    }
  }, membersStats);

  return isLoading ? (
    <>
      <Skeleton variant="rectangular" height="300px" />
      <Skeleton variant="rectangular" height="300px" />
      <Skeleton variant="rectangular" height="300px" />
      <Skeleton variant="rectangular" height="300px" />
      <Skeleton variant="rectangular" height="300px" />
      <Skeleton variant="rectangular" height="300px" />
      <Skeleton variant="rectangular" height="300px" />
      <Skeleton variant="rectangular" height="300px" />
    </>
  ) : (
    <>
      <Grid item style={{ height: "300px" }}>
        <PlayersStatsBars
          title="Player Distances"
          measure="KM"
          data={chartDistData}
        />
      </Grid>
      <Grid item style={{ height: "300px" }}>
        <PlayersStatsBars
          title="Players Kicks Count"
          measure="COUNT"
          data={chartKickData}
        />
      </Grid>
      <Grid item style={{ height: "300px" }}>
        <PlayersStatsLines
          title="Players Peak Speed"
          measure="KM/H"
          data={chartPeakSpeedData}
        />
      </Grid>
      <Grid item style={{ height: "300px" }}>
        <PlayersStatsLines
          title="Players Avg Speed"
          measure="KM/H"
          data={chartAvgSpeedData}
        />
      </Grid>
      <Stack
        direction="row"
        mt="2rem"
        spacing="1rem"
        justifyContent="space-between"
      >
        {playerIds.map((id, index) => (
          <Stack key={id + index} spacing="1rem">
            <Box height="300px">
              <PlayerStatPie
                items={ballZonesData[index]}
                statName={`Player ${index + 1} Ball Speed Zones`}
                chartEntryColors={CHARTS_COLORS}
                statsMeasure={"COUNT"}
                labels={chartBallZonesLabels}
              />
            </Box>
            <Box height="300px">
              <PlayerStatPie
                items={avgRunZonesData[index]}
                statName={`Player ${index + 1} Avg Speed Zones`}
                chartEntryColors={CHARTS_COLORS}
                statsMeasure={"KM"}
                labels={chartRunZonesLabels}
              />
            </Box>
            <Box height="300px">
              <PlayerStatPie
                items={peakRunZonesData[index]}
                statName={`Player ${index + 1} Peak Speed Zones`}
                chartEntryColors={CHARTS_COLORS}
                statsMeasure={"KM"}
                labels={chartRunZonesLabels}
              />
            </Box>
          </Stack>
        ))}
      </Stack>
    </>
  );
}

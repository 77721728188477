import {
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { TeamAvatar } from "components/avatar.component";
import AdidasLogoIcon from "components/icons/AdidasLogoIcon";
import { useAuthMutation, useAuthQuery, queryClient } from "providers/auth";
import { updateTeamMember } from "store/team-members/team-members.query";
import { getTeams } from "store/user-teams/teams.query";
import { IUserTeamInfo } from "store/user-teams/user-teams.model";
import { Trans } from "@lingui/macro";
import { OnboardingLeftContent } from "./components/OnboardingLeftContent";
import ProgressBar from "../../components/progress-bar.component";
import theme from "../../theme";

export const OnboardingInvite = () => {
  const teamsQuery = useAuthQuery("teams", getTeams);
  const memberMutation = useAuthMutation("members", updateTeamMember);
  const [isRejected, setIsRejected] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const history = useHistory();
  const [team, setTeam] = useState<IUserTeamInfo>({ teamId: "", playerId: "" });

  useEffect(() => {
    if (teamsQuery.isSuccess) {
      const invite = teamsQuery.data?.userTeams.find(
        (t) => t.status === "INVITATION_PENDING",
      );

      if (!invite) {
        return history.push("/onboarding/landing");
      }

      setTeam(invite);
    }
  }, [teamsQuery.isSuccess, teamsQuery.data, history, team.teamId]);

  useEffect(() => {
    if (memberMutation.isSuccess) {
      queryClient
        .invalidateQueries("teams")
        .then(() => history.push("/onboarding/landing"));
    }

    if (memberMutation.isError) {
      setIsAccepted(false);
      setIsRejected(false);
      toast.error(memberMutation.error.data.detail);
    }
  }, [
    memberMutation.isSuccess,
    memberMutation.isError,
    history,
    team.teamId,
    memberMutation.error,
  ]);

  const onAccept = () => {
    setIsAccepted(true);
    memberMutation.mutate({
      playerId: team.playerId,
      teamId: team.teamId,
      status: "APPROVED",
    });
  };

  const onReject = () => {
    setIsRejected(true);
    memberMutation.mutate({
      playerId: team.playerId,
      teamId: team.teamId,
      status: "REJECTED",
    });
  };

  return (
    <div className={"login"}>
      <div className="login-leftSide login-part">
        <OnboardingLeftContent />
      </div>
      <div className="login-rightSide login-part">
        <div className={"login-content"}>
          <div className="topHeader">
            <div className={"login-logo__container"}>
              <AdidasLogoIcon
                onClick={() =>
                  (window.location.href = "https://www.adidas.com/us")
                }
                height={60}
              />
            </div>
            <Typography variant="h1">TEAM FX</Typography>
          </div>
          {teamsQuery.isSuccess ? (
            <>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <LinearProgress variant="determinate" value={80} />
                </Grid>
                <Grid item width="fit-content">
                  <Typography variant="h3">
                    <Trans>join a team</Trans>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    <Trans>
                      You have been invited to the team. Accept or reject
                      invitation below.
                    </Trans>
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item>
                      <TeamAvatar
                        teamInfo={team}
                        style={{
                          width: 75,
                          height: 75,
                          fontSize: 40,
                          marginRight: 20,
                        }}
                      />
                    </Grid>
                    <Grid>
                      <Typography
                        variant="h4"
                        style={{ textTransform: "uppercase" }}
                      >
                        {team?.teamName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sx={{ width: "100%" }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={onAccept}
                    disabled={isAccepted || isRejected}
                    fullWidth
                    style={{ height: 60 }}
                  >
                    {isAccepted && memberMutation.isLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Trans>Accept</Trans>
                    )}
                  </Button>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: 470,
                    color: theme.text.secondary,
                  }}
                >
                  <hr
                    style={{
                      flex: 1,
                      backgroundColor: theme.text.secondary,
                      border: 0,
                      height: 1,
                    }}
                  />
                  <span style={{ margin: "0 8px" }}>
                    <Typography variant="subtitle2">OR</Typography>
                  </span>
                  <hr
                    style={{
                      flex: 1,
                      backgroundColor: theme.text.secondary,
                      border: 0,
                      height: 1,
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={onReject}
                    disabled={isAccepted || isRejected}
                    style={{ height: 60 }}
                    fullWidth
                  >
                    {isRejected && memberMutation.isLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Trans>Reject</Trans>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <LinearProgress
              sx={{
                width: "100%",
                backgroundColor: "#25272a",
                ["& .MuiLinearProgress-bar"]: {
                  backgroundColor: theme.actions.primary,
                },
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

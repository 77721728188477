import React from "react";
import { CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { EventFeedbackStats } from "./components/event-feedback-stats";
import { TeamEventUserSpecificWithMembers } from "store/events/events.model";
import { useAuthQuery } from "providers/auth";
import { getTeamMembers } from "store/team-members/team-members.query";
import {
  ITeamMemberInfo,
  TeamMemberRole,
} from "store/team-members/team-members.model";
import { EventFeedbackInput } from "./components/event-feedback-input";
import { EventFeedbackComment } from "./components/event-feedback-comment";
import EditIcon from "@mui/icons-material/Edit";
import { Skeleton } from "@mui/material";
import { Trans } from "@lingui/macro";
import { getTeams } from "../../store/user-teams/teams.query";
import { useAnalytics } from "use-analytics";

export const TimelineEventFeedbackTab = (
  event: TeamEventUserSpecificWithMembers,
) => {
  const analytics = useAnalytics();
  const membersQuery = useAuthQuery(
    ["teamMembers", event.teamId],
    getTeamMembers(event.teamId),
  );
  const teamsQuery = useAuthQuery("teams", getTeams);
  const { data: teamsQueryData } = teamsQuery;
  const curTeam = teamsQueryData?.userTeams.find(
    (v) => v.teamId === event.teamId,
  );
  const isOwner = curTeam?.role === TeamMemberRole.OWNER;
  const isPlayer = curTeam?.role === TeamMemberRole.PLAYER;
  const feedbackAvailable = Boolean(event.participation.feedback);
  const [feedbackEditActive, setFeedbackEditActive] = React.useState(
    !feedbackAvailable,
  );

  if (membersQuery.isLoading) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ padding: "25%" }}
      >
        <Grid item>
          <Skeleton variant="rectangular" height={350} />
        </Grid>
        <Grid item>
          <Skeleton variant="rectangular" height={350} />
        </Grid>
      </Grid>
    );
  }

  if (!membersQuery.data) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ padding: "25%" }}
      >
        <Grid item>
          <CircularProgress
            
            style={{ width: 100, height: 100 }}
          />
        </Grid>
        <Grid item>
          <Trans id="failed_to_load_text">Failed to load. Try later.</Trans>
        </Grid>
      </Grid>
    );
  }

  const membersMap = membersQuery.data.teamMembers.reduce(
    (acc, member) => ({ ...acc, [member.playerId]: member }),
    {} as Record<string, ITeamMemberInfo>,
  );
  const comments = event.participants.filter(
    (p) => p.userEventId !== event.participation.userEventId && p.feedback,
  );

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <EventFeedbackStats
          participants={event.participants}
          intensityAverage={event.intensityAverage}
          satisfactionAverage={event.satisfactionAverage}
        />
      </Grid>
      {!isOwner && (
        <Grid item>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography variant="h6">
                <Trans id="your_feedback_title">YOUR FEEDBACK</Trans>
              </Typography>
            </Grid>
            {feedbackAvailable && (
              <Grid item>
                <IconButton
                  disabled={!feedbackAvailable}
                  onClick={() => {
                    analytics.track("edit-feedback-click", {
                      eventId: event.eventId,
                    });
                    setFeedbackEditActive(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      {!isOwner && (
        <Grid item>
          {!feedbackAvailable || feedbackEditActive ? (
            <EventFeedbackInput
              userEvent={event.participation}
              leaveEditMode={() => setFeedbackEditActive(false)}
            />
          ) : (
            <EventFeedbackComment
              isPlayer={isPlayer}
              userEvent={event.participation}
              member={membersMap[event.participation.playerId]}
            />
          )}
        </Grid>
      )}
      {!isPlayer && (
        <>
          <Grid item>
            <Typography variant="h6">
              <Trans id="feedback_comments_title">COMMENTS</Trans>
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={2}>
              {comments.length ? (
                comments.map((userEvent) => (
                  <Grid item>
                    <EventFeedbackComment
                      isPlayer={isPlayer}
                      userEvent={userEvent}
                      member={membersMap[userEvent.playerId]}
                    />
                  </Grid>
                ))
              ) : (
                <Grid item>
                  <Typography variant="body2">
                    <Trans id="feedback_no_comments_text">No comments</Trans>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

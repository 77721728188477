import * as React from "react";
import { GridColDef, GridApi } from "@mui/x-data-grid";
import { FormControl, Grid, Menu, Typography, Button } from "@mui/material";
import { Skeleton } from "@mui/material";
import { useAuthQuery } from "providers/auth";
import { getTeamLeaderboard } from "store/team-leaderboard/leaderboard.query";
import { getTeamMembers } from "store/team-members/team-members.query";
import {
  StyledDataGrid,
  getStripedClassNames,
} from "components/table.component";
import {
  getLeaderBoardAsList,
  getPlayerName,
  renderPlayer,
  renderPosition,
} from "../containers/leaderboard/rows-leaderboard";
import { DateTime } from "luxon";
import {
  getNavigationUrl,
  NavigationContext,
  NavigationTabs,
} from "providers/navigation.provider";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import { MPS_TO_KMPH, M_IN_KM } from "utils/team";
import { PositionFilterModal } from "containers/position/position-filter.modal";
import { PlayerPosition } from "@gamer/common/lib/models/user";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import { MenuItemSmallSelectCustom } from "components/select.component";
import { DatePicker } from "components/date-picker.component";
import theme from "theme";
import { EventOrDateTimeSelector } from "containers/layout/date-time.container";
import { Trans } from "@lingui/macro";
import { useAnalytics } from "use-analytics";
import { PositionFilter } from "containers/position/position-models";

export default function Leaderboard() {
  const { currentTeam } = React.useContext(NavigationContext);
  const history = useHistory();
  const analytics = useAnalytics();
  const now = DateTime.now();
  const search = new URLSearchParams(useLocation().search);
  const { teamId } = currentTeam;
  const range: Record<string, DateTime[]> = {
    today: [now.startOf("day"), now.endOf("day")],
    yesterday: [
      now.minus({ days: 1 }).startOf("day"),
      now.minus({ days: 1 }).endOf("day"),
    ],
    "this week": [now.startOf("week"), now ],
    "last week": [
      now.minus({ week: 1 }).startOf("week"),
      now.minus({ week: 1 }).endOf("week"),
    ],
    "this month": [now.startOf("month"), now ],
    "last month": [
      now.minus({ month: 1 }).startOf("month"),
      now.minus({ month: 1 }).endOf("month"),
    ],
  };
  const [rangeIndex, setRangeIndex] = React.useState<keyof typeof range | null>(
    search.get("from") ? null : "this week",
  );
  const [from, setFrom] = React.useState<DateTime>(
    rangeIndex
      ? range[rangeIndex][0]
      : DateTime.fromMillis(Number(search.get("from"))),
  );
  const [to, setTo] = React.useState<DateTime>(
    rangeIndex
      ? range[rangeIndex][1]
      : DateTime.fromMillis(Number(search.get("to"))),
  );
  const [apiRef, setApiRef] = React.useState<GridApi | null>(null);
  const [positionFilter, setPositionFilter] = React.useState<PositionFilter>(
    {positions: [], includeNotDefined: true}
  );
  const [showPositionFilterModal, setShowPositionFilterModal] =
    React.useState(false);
  const [selectAnchorEl, setSelectAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [customDateAnchorEl, setCustomDateAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isFramed = Boolean(false);

  const leaderboardQuery = useAuthQuery(
    ["leaderboard", teamId, from, to],
    getTeamLeaderboard({ teamId, from: from.valueOf(), to: to.valueOf() }),
    { enabled: Boolean(teamId) },
  );
  const teamMembersQuery = useAuthQuery(
    ["teamMembers", teamId],
    getTeamMembers(teamId),
    { enabled: Boolean(teamId) },
  );

  const isLoading =
    leaderboardQuery.isLoading ||
    teamMembersQuery.isLoading ||
    !leaderboardQuery.data ||
    !teamMembersQuery.data;

  React.useEffect(() => {
    if (from.valueOf() > to.valueOf()) {
      const min = to;
      const max = from;

      setFrom(min);
      setTo(max);
    }
    const query = {
      ...qs.parse(history.location.search),
      from: from.valueOf(),
      to: to.valueOf(),
    };

    history.push({
      search: qs.stringify(query),
    });
  }, [from, to, history]);

  const onExport = () => {
    apiRef?.exportDataAsCsv({
      fileName: `${from.toISODate()}:${to.toISODate()}`,
    });
  };

  React.useEffect(() => {
    analytics.page("leaderboard");
  }, []);

  const onPlayerClick = (playerId: string) => {
    const search = {
      ...qs.parse(history.location.search),
      playerId,
    };
    history.push({
      ...history.location,
      pathname: getNavigationUrl(teamId, NavigationTabs.ANALYTICS),
      search: qs.stringify(search),
    });
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      headerAlign: "center",
      width: 50,
      renderCell: (params) => {
        if (!apiRef) {
          setApiRef(params.api);
        }
        return renderPosition(params);
      },
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "player",
      headerName: "Player",
      flex: 0.5,
      renderCell: renderPlayer(onPlayerClick),
      valueFormatter: getPlayerName,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "totalDistance",
      headerName: "Distance",
      valueFormatter: (params) =>
        `${(Number(params.value) / M_IN_KM).toFixed(2)}  KM`,
      flex: 0.2,
    },
    {
      field: "topRunningSpeed",
      headerName: "Top Running Speed",
      renderCell: (params) =>
        `${(Number(params.value) * MPS_TO_KMPH).toFixed(2)}  KM/H`,
      valueFormatter: (params) =>
        (Number(params.value) * MPS_TO_KMPH).toFixed(2),
      flex: 0.25,
    },
    {
      field: "topBallSpeed",
      headerName: "Top Ball Speed",
      renderCell: (params) => `${Number(params.value).toFixed(2)} KM/H`,
      valueFormatter: (params) => Number(params.value).toFixed(2),
      flex: 0.25,
    },
    {
      field: "countKicks",
      headerName: "Kick Count",
      flex: 0.2,
    },
    {
      field: "totalDistanceXplos",
      headerName: "Explosiveness Distance",
      renderCell: (params) => `${Math.round(Number(params.value))}  M`,
      valueFormatter: (params) => Math.round(Number(params.value)),
      flex: 0.25,
    },
    {
      field: "countXplos",
      headerName: "Explosiveness Count",
      flex: 0.25,
    },
    {
      field: "totalDistanceSprint",
      headerName: "Sprint Distance",
      renderCell: (params) => `${Math.round(Number(params.value))}  M`,
      valueFormatter: (params) => Math.round(Number(params.value)),
      flex: 0.25,
    },
    {
      field: "countSprint",
      headerName: "Sprint Count",
      flex: 0.2,
    },
  ];

  const raw = getLeaderBoardAsList(
    leaderboardQuery?.data?.metrics,
    teamMembersQuery?.data?.teamMembers,
  );
  const rows = raw.filter((row) =>
    (positionFilter.includeNotDefined && !row?.player?.position) || (positionFilter.positions.length
      ? positionFilter.positions.includes(row?.player?.position as PlayerPosition)
      : true)
  );

  return (
    <Grid
      sx={{ padding: "16px 40px 0px 40px" }}
      container
      direction="column"
      spacing={5}
    >
      {isFramed ? (
        ""
      ) : (
        <Grid item>
          <Typography variant="h4">
            <Trans>LEADERBOARD</Trans>
          </Typography>
        </Grid>
      )}
      <Grid item>
        <PositionFilterModal
          open={showPositionFilterModal}
          filter={positionFilter}
          onSave={(positionFilter) => {
            setPositionFilter(positionFilter);
            setShowPositionFilterModal(!showPositionFilterModal);
          }}
        />
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="start"
          alignItems="center"
          rowGap={2}
        >
          <Grid item sx={{ marginRight: "1rem" }}>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item id="selector" hidden={true}>
                <FormControl>
                  <Button
                    variant="outlined"
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      setSelectAnchorEl(event.currentTarget);
                    }}
                    style={{ borderRadius: 4, height: 50 }}
                  >
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography
                          color="textSecondary"
                          variant="body2"
                          style={{ textTransform: "capitalize" }}
                        >
                          {rangeIndex || from.toRelativeCalendar()}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <ArrowDropDownIcon />
                      </Grid>
                    </Grid>
                  </Button>
                  <Menu
                    elevation={0}
                    anchorEl={selectAnchorEl}
                    keepMounted
                    open={Boolean(selectAnchorEl)}
                    onClose={() => setSelectAnchorEl(null)}
                  >
                    {Object.keys(range).map((k) => (
                      <MenuItemSmallSelectCustom
                        selected={k === rangeIndex}
                        key={k}
                        onClick={() => {
                          setFrom(range[k][0]);
                          setTo(range[k][1]);
                          setRangeIndex(k as any);
                        }}
                      >
                        {k}
                      </MenuItemSmallSelectCustom>
                    ))}
                    <MenuItemSmallSelectCustom
                      id="default"
                      onClick={(event: React.MouseEvent<HTMLElement>) =>
                        !customDateAnchorEl &&
                        setCustomDateAnchorEl(event.currentTarget)
                      }
                    >
                      <Grid container alignItems="center">
                        <Grid item>
                          <Trans context="date and time range with from and to">
                            Choose Range
                          </Trans>
                        </Grid>
                      </Grid>
                      <DatePicker
                        open={Boolean(customDateAnchorEl)}
                        sx={{
                          width: 75,
                          fontSize: 14,
                          padding: 5,
                        }}
                        disableFuture={true}
                        value={from}
                        onChange={(v) => {
                          if (v) {
                            setFrom(v);
                            setTo(DateTime.now());
                            setRangeIndex(null);
                            setCustomDateAnchorEl(null);
                            setSelectAnchorEl(null);
                          }
                        }}
                        format={"MMMM dd HH:mm"}
                      />
                    </MenuItemSmallSelectCustom>
                  </Menu>
                </FormControl>
              </Grid>
              <Grid item id="legacy-date-selector">
                <EventOrDateTimeSelector
                  from={from}
                  to={to}
                  setFrom={setFrom}
                  setTo={setTo}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container>
              <Grid item id="position-filter" sx={{ marginRight: "1rem" }}>
                <Button
                  variant="outlined"
                  onClick={() => setShowPositionFilterModal(true)}
                  style={{
                    borderRadius: 4,
                    height: 50,
                    borderColor: "white",
                  }}
                >
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      {positionFilter.positions.length ? (
                        <Typography
                          color="textSecondary"
                          variant="body2"
                          style={{ textTransform: "none" }}
                        >
                          {positionFilter.positions.slice(0, 5).join(", ") +
                            (positionFilter.positions.length > 5 ? " ..." : "")}
                        </Typography>
                      ) : (
                        <Typography
                          color="textSecondary"
                          variant="body2"
                          style={{ textTransform: "none" }}
                        >
                          Filter by Position
                        </Typography>
                      )}
                    </Grid>
                    <Grid item>
                      <ArrowDropDownIcon
                        color="disabled"
                        style={{ fontSize: 20 }}
                      />
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  disabled={!apiRef}
                  style={{
                    width: "fit-content",
                    height: 50,
                    borderRadius: 4,
                    color: theme.actions.secondary,
                    borderColor: theme.actions.secondary,
                  }}
                  onClick={onExport}
                >
                  EXPORT AS CSV
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        {isLoading ? (
          <Skeleton variant="rectangular" height={500} />
        ) : !rows.length ? (
          positionFilter.positions.length ? (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              style={{ padding: "10%" }}
            >
              <Grid item>
                <Typography variant="subtitle2">
                  <Trans>No results on leaderboard</Trans>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: 13,
                    lineHeight: "15px",
                    color: theme.text.secondary,
                    textAlign: "center",
                  }}
                >
                  <Trans>Try a different filters or without filters</Trans>
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setPositionFilter({positions: [], includeNotDefined: true });
                  }}
                  style={{
                    height: "fit-content",
                    width: "fit-content",
                    border: `1px solid ${theme.actions.primary}`,
                    color: theme.actions.primary,
                  }}
                >
                  <Typography
                    variant="h6"
                    style={{
                      fontSize: 18,
                      lineHeight: "24px",
                    }}
                  >
                    <Trans>Clear all filters</Trans>
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              style={{ padding: "10%" }}
            >
              <Grid item>
                <Typography>
                  <SentimentDissatisfiedIcon
                    style={{ width: 75, height: 75 }}
                  />
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">
                  <Trans>Not much records here.</Trans>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: 13,
                    lineHeight: "15px",
                    color: theme.text.secondary,
                    textAlign: "center",
                  }}
                >
                  <Trans>
                    No data was returned for selected range. <br /> Please, try
                    a different period.
                  </Trans>
                </Typography>
              </Grid>
            </Grid>
          )
        ) : (
          <StyledDataGrid
            rows={rows}
            columns={columns}
            loading={isLoading}
            disableColumnMenu={false}
            sx={{
              "& .MuiDataGrid-columnHeaderTitle": {
                textOverflow: "clip",
                whiteSpace: "break-spaces",
                lineHeight: 1,
              },
            }}
            // pageSize={25}
            getRowClassName={getStripedClassNames}
            style={{ height: 2150 }}
          />
        )}
      </Grid>
    </Grid>
  );
}

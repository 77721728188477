import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { PlayerAvatar, PlayerName } from "components/avatar.component";
import React, { useContext, useEffect, useState } from "react";
import { UserEvent } from "store/events/events.model";
import SendIcon from "@mui/icons-material/Send";
import { queryClient, useAuthMutation } from "providers/auth";
import { putUserEventFeedbackReply } from "store/events/events.query";
import { NavigationContext } from "providers/navigation.provider";
import { DateTime } from "luxon";
import { Trans } from "@lingui/macro";
import { useAnalytics } from "use-analytics";

export type EventReplyProps = UserEvent & { isPlayer: boolean };

export const EventReplies = (userEvent: EventReplyProps) => {
  const analytics = useAnalytics();
  const replies =
    (userEvent.feedback &&
      userEvent.feedback.replies &&
      userEvent.feedback.replies
        .filter(({ comment }) => comment)
        .sort((a, b) => a.commentTimestamp - b.commentTimestamp)) ||
    [];
  const { playerId, eventId, teamId } = userEvent;
  const {
    player: { playerId: issuerId },
  } = useContext(NavigationContext);
  const replyMutation = useAuthMutation(
    ["updateUserEvent", eventId, playerId],
    putUserEventFeedbackReply(eventId, playerId),
  );
  const myReply = replies.find((r) => r.playerId === issuerId);
  const [expandComment, setExpandComment] = useState(false);
  const [comment, setComment] = useState(myReply ? myReply.comment : "");

  useEffect(() => {
    setExpandComment(false);
    queryClient.invalidateQueries(["event", eventId]);
  }, [replyMutation.isSuccess, replyMutation.isError, eventId]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item hidden={expandComment || userEvent.isPlayer}>
        <Button onClick={() => setExpandComment(true)} >
          <Typography variant="body2">
            {myReply ? <Trans>EDIT REPLY</Trans> : <Trans>REPLY</Trans>}
          </Typography>
        </Button>
      </Grid>
      <Grid item hidden={!expandComment}>
        <Button onClick={() => setExpandComment(false)} >
          <Typography variant="body2">
            <Trans>DISMISS</Trans>
          </Typography>
        </Button>
      </Grid>
      <Grid item hidden={!expandComment}>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item>
            <PlayerAvatar playerId={issuerId} teamId={teamId} />
          </Grid>
          <Grid item>
            <TextField
              multiline
              style={{ width: 600 }}
              inputProps={{ maxLength: 256 }}
              onChange={(event) => setComment(event.target.value)}
              value={comment}
              label="Reply"
            />
          </Grid>
          <Grid item>
            <IconButton
              
              onClick={() => {
                analytics.track("event-reply", { eventId, playerId });
                replyMutation.mutate({ comment });
              }}
              disabled={replyMutation.isLoading}
            >
              {replyMutation.isLoading ? <CircularProgress size={20} /> : <SendIcon />}
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column" spacing={2}>
          {replies.map((reply) => (
            <Grid item hidden={expandComment && reply.playerId === issuerId}>
              <Grid container direction="row" spacing={2}>
                <Grid item>
                  <PlayerAvatar playerId={reply.playerId} teamId={teamId} />
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <PlayerName
                        variant="subtitle2"
                        playerId={reply.playerId}
                        teamId={teamId}
                        style={{ fontSize: 14 }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" style={{ fontSize: 13 }}>
                        {reply.comment}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ fontSize: 12 }}
                      >
                        {DateTime.fromMillis(
                          reply.commentTimestamp,
                        ).toRelative()}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

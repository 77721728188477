import { Grid, IconButton, Popover, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { UserEvent } from "store/events/events.model";
import MoodIcon from "@mui/icons-material/Mood";
import { putUserEventFeedbackReply } from "store/events/events.query";
import { queryClient, useAuthMutation } from "providers/auth";
import { ITeamMemberInfo } from "store/team-members/team-members.model";
import { Skeleton } from "@mui/material";
import { useAnalytics } from "use-analytics";

export type EventFeedbackReactionProps = {
  userEvent: UserEvent;
  isPlayer: boolean;
  member?: ITeamMemberInfo;
};

const REACTIONS_LIST = ["👍", "❤️", "🎊", "😢", "👏"];
const validHeartReaction = "🧡";

export const EventFeedbackReaction = ({
  userEvent,
  isPlayer,
}: EventFeedbackReactionProps) => {
  const analytics = useAnalytics();
  const { eventId, playerId } = userEvent;
  const recentReaction = userEvent.feedback.replies
    ?.map(({ reaction }) =>
      reaction === validHeartReaction ? REACTIONS_LIST[1] : reaction,
    )
    .filter(Boolean)[0];
  const replyMutation = useAuthMutation(
    ["updateUserEvent", eventId, playerId],
    putUserEventFeedbackReply(eventId, playerId),
  );
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    queryClient.invalidateQueries(["event", eventId]);
  }, [replyMutation.isSuccess, replyMutation.isError, eventId]);

  return (
    <div>
      {replyMutation.isLoading ? (
        <Skeleton width={15} />
      ) : isPlayer ? (
        <Typography textAlign="center">
          {recentReaction || <MoodIcon />}
        </Typography>
      ) : (
        <IconButton
          disabled={isPlayer}
          onClick={handleClick}
          style={{ borderRadius: 1 }}
        >
          {recentReaction || <MoodIcon />}
        </IconButton>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Grid container spacing={1}>
          {REACTIONS_LIST.map((r) => (
            <Grid item>
              <IconButton
                style={{ borderRadius: 1 }}
                onClick={() => {
                  replyMutation.mutate({
                    reaction: r === REACTIONS_LIST[1] ? validHeartReaction : r,
                  });
                  analytics.track("event-feedback-reaction", {
                    eventId,
                    playerId,
                    reaction: r,
                  });
                  setAnchorEl(null);
                }}
              >
                {r}
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </div>
  );
};

import React, { useEffect, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Typography,
  MenuItem,
  Menu
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { toast } from "react-toastify";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useAuthMutation, useAuthQuery } from "providers/auth";
import theme from "theme";
import {
  getTeamMembers,
  patchTeamMembership,
  deleteTeamMembership,
} from "store/team-members/team-members.query";
import {
  ITeamMemberInfo,
  PlayerPosition,
  TeamMemberRole,
  TeamMemberStatus,
} from "store/team-members/team-members.model";
import { SearchByTagId } from "../user/search.container";
import { PlayerAvatar, TeamAvatar } from "components/avatar.component";
import {
  copyInviteLink,
  getInvitedMemberNameOrTag,
  getTeamRoleTitle,
} from "utils/team";

import { PositionSelectModal } from "../position/position-select.modal";
import { PositionButton } from "../position/position.button";
import TooltipCustom from "components/tooltip.component";
import { NavigationContext } from "providers/navigation.provider";
import { PositionFilterModal } from "../position/position-filter.modal";
import { Trans } from "@lingui/macro";
import { useAnalytics } from "use-analytics";
import { PositionFilter } from "containers/position/position-models";

const MemberItem = styled.div`
  background: ${theme.background.tertiary};
  border-bottom: 1px solid black;
  padding: 10px 15px 10px 15px;
`;

interface TeamMembersViewProps {
  teamName: string;
}

export const TeamMembersView = (props: TeamMembersViewProps) => {
  const analytics = useAnalytics();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [playerToUpdate, setPlayerToUpdate] = useState("");
  const [menuCurRoleUpdate, setMenuCurRoleUpdate] = useState("");
  const navigator = useContext(NavigationContext);
  const { currentTeam } = navigator;

  const { teamId } = useParams<{ teamId: string }>();
  const teamMembersQuery = useAuthQuery(
    ["teamMembers", teamId],
    getTeamMembers(teamId),
  );
  const membershipMutation = useAuthMutation(
    "patchTeamMembership",
    patchTeamMembership,
  );
  const deleteTeamMembershipMutation = useAuthMutation(
    "deleteTeamMembership",
    deleteTeamMembership,
  );
  const { isLoading, isSuccess, error, data } = teamMembersQuery;
  const teamName = props.teamName;

  const [invitationShown, setInvitationShown] = useState(false);
  const [positionModalSelectedPlayer, setPositionModalSelectedPlayer] =
    useState<ITeamMemberInfo | null>(null);
  const [members, setMembers] = useState<Array<ITeamMemberInfo>>([]);
  const [approvalPendingMembers, setApprovalPendingMembers] = useState<
    Array<ITeamMemberInfo>
  >([]);
  const [invitationPendingMembers, setInvitationPendingMembers] = useState<
    Array<ITeamMemberInfo>
  >([]);
  const [changedPlayerId, setChangedPlayerId] = useState("");
  const [positionFilter, setPositionFilter] = useState<PositionFilter>({positions: [], includeNotDefined: true});
  const [showPositionFilterModal, setShowPositionFIlterModal] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setApprovalPendingMembers(
        data?.teamMembers?.filter(
          (item) => item.status === TeamMemberStatus.APPROVAL_PENDING,
        ) || [],
      );
      setInvitationPendingMembers(
        data?.teamMembers?.filter(
          (item) => item.status === TeamMemberStatus.INVITATION_PENDING,
        ) || [],
      );
    }
  }, [isSuccess, data]);

  useEffect(() => {
    setMembers(
      data?.teamMembers
        ?.filter((item) => item.status === TeamMemberStatus.APPROVED)
        .filter(
          (item) => (positionFilter.includeNotDefined && !item.position) || 
            (!positionFilter.positions.length ||
            positionFilter.positions.includes(item.position as PlayerPosition)),
        ) || [],
    );
  }, [positionFilter, data]);

  useEffect(() => {}, [changedPlayerId]);

  if (isLoading) {
    return <CircularProgress size={30} />;
  }
  if (error) {
    return (
      <Typography variant="subtitle1">
        <Trans>Failed to Load Team Members.</Trans>
      </Typography>
    );
  }

  const handleOpen = (
    event: React.MouseEvent<HTMLElement>,
    playerIdToUpdate: string,
    role?: string,
  ) => {
    analytics.track("team-members-update", { playerIdToUpdate, role });
    setAnchorEl(event.currentTarget);
    setPlayerToUpdate(playerIdToUpdate);
    setMenuCurRoleUpdate(role || "");
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPlayerToUpdate("");
  };

  const refreshMembers = () => {
    teamMembersQuery.refetch();
  };

  const handlePositionButtonClick = (member: ITeamMemberInfo) => {
    setPositionModalSelectedPlayer(member);
  };

  const handlePositionModalClose = (position?: PlayerPosition) => {
    if (positionModalSelectedPlayer?.playerId && position) {
      analytics.track("team-members-update-position", {
        playerId: positionModalSelectedPlayer?.playerId,
        position,
      });
      changePlayerPosition(positionModalSelectedPlayer?.playerId, position);
    }
    setPositionModalSelectedPlayer(null);
  };

  const removePlayer = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Do you want to remove player?")) {
      await deleteTeamMembershipMutation.mutateAsync({
        teamId,
        playerId: playerToUpdate,
      });

      if (deleteTeamMembershipMutation.isError) {
        return toast.error("Failed to remove team member!");
      }

      analytics.track("team-members-remove", { playerId: playerToUpdate });
      setAnchorEl(null);
      await teamMembersQuery.refetch();

      return toast.success("Team member has been removed!");
    }
  };

  const updateRole = (role: string) => async () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Do you want to update role of the team member?")) {
      await membershipMutation.mutateAsync({
        teamId,
        playerId: playerToUpdate,
        patchReq: { role: role as TeamMemberRole },
      });

      if (membershipMutation.isError) {
        return toast.error("Failed to update team member role!");
      }

      analytics.track("team-members-update-role", {
        playerId: playerToUpdate,
        role,
      });
      setAnchorEl(null);
      await teamMembersQuery.refetch();

      return toast.success("Team member role has been updated!");
    }
  };

  const changePlayerPosition = async (
    playerId: string,
    position: PlayerPosition,
  ) => {
    setChangedPlayerId(playerId);

    await membershipMutation.mutateAsync({
      teamId,
      playerId,
      patchReq: { position },
    });

    if (membershipMutation.isError) {
      return toast.error("Failed to set member position");
    }

    await teamMembersQuery.refetch();

    setChangedPlayerId("");
    return toast.success("Position changed!");
  };

  const getInviteLink = () => {
    analytics.track("team-members-invite-link-crated", { teamId });
    copyInviteLink(teamId, teamName);

    return toast.success("Link copied to the clipboard!");
  };

  const approveJoin = async (playerId: string) => {
    await membershipMutation.mutateAsync({
      teamId,
      playerId,
      patchReq: { status: TeamMemberStatus.APPROVED },
    });

    if (membershipMutation.isError) {
      return toast.error("Failed to approve team join request!");
    }
    analytics.track("team-members-approve-join", { playerId });

    await teamMembersQuery.refetch();
    return toast.success("Team join has been approved!");
  };

  const rejectJoin = async (playerId: string) => {
    await membershipMutation.mutateAsync({
      teamId,
      playerId,
      patchReq: { status: TeamMemberStatus.REJECTED },
    });

    if (membershipMutation.isError) {
      return toast.error("Failed to reject team join request!");
    }
    analytics.track("team-members-reject-join", { playerId });

    await teamMembersQuery.refetch();
    return toast.success("Team join has been reject!");
  };
  const setInviteStatus = () => {
    setInvitationShown(!invitationShown);
  };
  const hideRoleManagement = () => currentTeam.role !== TeamMemberRole.OWNER;
  const adminToolSetVisible = () => currentTeam.role !== TeamMemberRole.PLAYER;

  return (
    <div>
      {positionModalSelectedPlayer && (
        <PositionSelectModal
          open={Boolean(positionModalSelectedPlayer)}
          handleClose={handlePositionModalClose}
          position={positionModalSelectedPlayer?.position}
          preferredPosition={positionModalSelectedPlayer?.memberInfo?.position}
          playerName={`${positionModalSelectedPlayer?.memberInfo?.firstName} ${positionModalSelectedPlayer?.memberInfo?.lastName}`}
        />
      )}
      {showPositionFilterModal && (
        <PositionFilterModal
          open={showPositionFilterModal}
          filter={positionFilter}
          onSave={(positions) => {
            setPositionFilter(positions);
            setShowPositionFIlterModal(!showPositionFilterModal);
          }}
        />
      )}
      <Menu
        id="playerMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!hideRoleManagement() &&
          Object.values(TeamMemberRole).map(
            (role) =>
              role !== menuCurRoleUpdate && (
                <MenuItem>
                  <Button variant="outlined" onClick={updateRole(role)}>
                    Update to {getTeamRoleTitle(role)}
                  </Button>
                </MenuItem>
              ),
          )}
        <MenuItem
          disabled={
            (menuCurRoleUpdate as TeamMemberRole) === TeamMemberRole.OWNER
          }
        >
          <Button variant="outlined" onClick={removePlayer}>
            Remove Player
          </Button>
        </MenuItem>
      </Menu>
      <Grid
        container
        direction="column"
        alignItems="stretch"
        justifyContent="flex-end"
        spacing={5}
      >
        <Grid item>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Typography variant="h4">
                {adminToolSetVisible() ? (
                  <Trans>MANAGE MEMBERS</Trans>
                ) : (
                  <Trans>MEMBERS</Trans>
                )}
              </Typography>
            </Grid>
            <Grid item hidden={!adminToolSetVisible()}>
              <Button
                color="primary"
                variant="contained"
                style={{ width: "fit-content" }}
                onClick={setInviteStatus}
              >
                {invitationShown ? "Hide Invitation Panel" : "Invite Member"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ display: invitationShown ? "block" : "none" }}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <SearchByTagId teamId={teamId} onAdded={refreshMembers} />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                style={{ height: 50 }}
                onClick={getInviteLink}
              >
                Share Invite
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            alignItems="stretch"
            justifyContent="flex-end"
          >
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">
                  ALL TEAM MEMBERS ({members.length})
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  onClick={() =>
                    setShowPositionFIlterModal(!showPositionFilterModal)
                  }
                >
                  {positionFilter.positions.length ? (
                    <Trans>
                      Filter by Position Applied {positionFilter.positions.length}
                    </Trans>
                  ) : (
                    <Trans>Filter by Position</Trans>
                  )}
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <Box sx={{ width: "100%" }}>
                {members.map((member) => (
                  <Grid item key={member.playerId}>
                    <MemberItem>
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <Grid container direction="row" alignItems="center">
                            <Grid item>
                              <PlayerAvatar
                                playerId={member.playerId}
                                teamId={teamId}
                              />
                            </Grid>
                            <Grid item>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                                spacing={1}
                              >
                                <Grid item>
                                  <Typography
                                    variant="body2"
                                    style={{ padding: "0px 0px 0px 10px" }}
                                  >
                                    {getInvitedMemberNameOrTag(member)}{" "}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <TooltipCustom
                                    arrow
                                    placement="top"
                                    title={`${member.memberInfo?.fullYearsAge} years`}
                                  >
                                    <InfoOutlinedIcon />
                                  </TooltipCustom>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item flex={1}>
                          {changedPlayerId === member.playerId ? (
                            <LinearProgress
                              style={{ width: 500, marginTop: 20 }}
                            />
                          ) : (
                            <Grid
                              container
                              justifyContent="flex-end"
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>
                                <PositionButton
                                  disabled={!adminToolSetVisible()}
                                  position={member.position}
                                  onClick={() =>
                                    handlePositionButtonClick(member)
                                  }
                                />
                              </Grid>
                              <Grid item style={{ width: 80 }}>
                                <Typography
                                  variant="body2"
                                  style={{ textTransform: "capitalize" }}
                                >{`${getTeamRoleTitle(
                                  member.role,
                                )}`}</Typography>
                              </Grid>
                              <Grid item xs={1} hidden={!adminToolSetVisible()}>
                                <IconButton
                                  onClick={(event) =>
                                    handleOpen(
                                      event,
                                      member.playerId,
                                      member.role?.toLocaleUpperCase(),
                                    )
                                  }
                                  disabled={
                                    currentTeam.playerId === member.playerId
                                  }
                                >
                                  <MoreHorizIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </MemberItem>
                  </Grid>
                ))}
              </Box>
            </Grid>
            <Grid item>&nbsp;</Grid>
            <Grid item hidden={!adminToolSetVisible()}>
              <Grid
                container
                direction="column"
                alignItems="stretch"
                justifyContent="flex-end"
              >
                <Grid item>
                  <Typography variant="h6">
                    MEMBER REQUESTS ({approvalPendingMembers.length})
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    alignItems="stretch"
                    justifyContent="flex-end"
                  >
                    {approvalPendingMembers.map((joinMember) => (
                      <Grid item key={joinMember.playerId}>
                        <MemberItem>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignContent="center"
                          >
                            <Grid item>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                              >
                                <Grid item>
                                  <TeamAvatar teamInfo={currentTeam} />
                                </Grid>
                                <Grid item>
                                  <Typography
                                    variant="h6"
                                    style={{ padding: "0px 0px 0px 10px" }}
                                  >
                                    {getInvitedMemberNameOrTag(joinMember)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              alignContent="center"
                              alignItems="center"
                            >
                              <Grid container direction="row">
                                <Grid item>
                                  <Button
                                    variant="contained"
                                    style={{
                                      width: 68,
                                      height: 32,
                                      fontSize: 16,
                                      color: "black",
                                    }}
                                    onClick={() =>
                                      approveJoin(joinMember.playerId)
                                    }
                                  >
                                    CONFIRM
                                  </Button>
                                </Grid>
                                <Grid item>&nbsp;</Grid>
                                <Grid item>
                                  <Button
                                    variant="outlined"
                                    style={{
                                      width: 68,
                                      height: 32,
                                      fontSize: 16,
                                    }}
                                    onClick={() =>
                                      rejectJoin(joinMember.playerId)
                                    }
                                  >
                                    REJECT
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </MemberItem>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>&nbsp;</Grid>
            <Grid item hidden={!adminToolSetVisible()}>
              <Grid
                container
                direction="column"
                alignItems="stretch"
                justifyContent="flex-end"
              >
                <Grid item>
                  <Typography variant="h6">
                    <Trans>
                      INVITATION REQUESTS ({invitationPendingMembers.length})
                    </Trans>
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    alignItems="stretch"
                    justifyContent="flex-end"
                  >
                    {invitationPendingMembers.map((invitedMember) => (
                      <Grid item key={invitedMember.playerId}>
                        <MemberItem>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                          >
                            <Grid item>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                              >
                                <Grid item>
                                  <TeamAvatar teamInfo={currentTeam} />
                                </Grid>
                                <Grid item>
                                  <Typography
                                    variant="h6"
                                    style={{ padding: "0px 0px 0px 10px" }}
                                  >
                                    {getInvitedMemberNameOrTag(invitedMember)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <IconButton
                                id={`profileMenuBut${invitedMember.playerId}`}
                                onClick={(event) =>
                                  handleOpen(event, invitedMember.playerId)
                                }
                              >
                                <MoreHorizIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </MemberItem>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item></Grid>
      </Grid>
    </div>
  );
};

import * as React from "react";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import { Button, Grid, Stack } from "@mui/material";
import { useAuthQuery } from "providers/auth";
import { getMemberStats } from "store/member-stats/member-stats.query";
import { getTeamMembers } from "store/team-members/team-members.query";
import { NavigationContext } from "providers/navigation.provider";
import { FeedbackStats } from "./analytic/tabs/feedback-stats";
import { useAnalytics } from "use-analytics";
import { MobileDateTimePicker } from "@mui/x-date-pickers";

export const MPS_TO_KMPH = 3.6;
export const M_IN_KM = 1000;
export const DAY_IN_MS = 86400 * 1000;

export default function TeamFeedbackPage() {
  const analytics = useAnalytics();
  const { currentTeam } = React.useContext(NavigationContext);
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const { teamId } = currentTeam;
  const minDate = React.useMemo(() => DateTime.now().minus({ weeks: 12 }), []);
  const [from, setFrom] = React.useState<DateTime>(
    query.get("from")
      ? DateTime.fromMillis(Number(query.get("from")))
      : DateTime.now().minus({ week: 1 }),
  );
  const [to, setTo] = React.useState<DateTime>(
    query.get("to")
      ? DateTime.fromMillis(Number(query.get("to")))
      : DateTime.now(),
  );
  const [memberId, setMemberId] = React.useState<string>(
    query.get("playerId") || "",
  );

  const [playerIds, setPlayerIds] = React.useState<string[]>([]);
  const [playersChoosed, setPlayersChoosed] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (from.valueOf() > to.valueOf()) {
      const min = to;
      const max = from;

      setFrom(min);
      setTo(max);
    }

    const interval = to.valueOf() - from.valueOf();
    if (interval > DAY_IN_MS) {
      setQueryInterval("auto");
    } else if (interval > DAY_IN_MS / 6) {
      setQueryInterval("5m");
    } else {
      setQueryInterval("1m");
    }
  }, [from, to]);

  const [queryInterval, setQueryInterval] = React.useState<string>("auto");
  const memberStatsQuery = useAuthQuery(
    ["memberStats", teamId, memberId, from, to, queryInterval],
    getMemberStats({
      teamId,
      memberId,
      from: from.valueOf(),
      to: to.valueOf(),
      interval: queryInterval,
    }),
    { enabled: Boolean(teamId) && Boolean(memberId) },
  );

  const teamMembersQuery = useAuthQuery(
    ["teamMembers", teamId],
    getTeamMembers(teamId),
    { enabled: Boolean(teamId) },
  );

  return (
    <Stack sx={{ padding: "40px" }} direction="column" spacing={5}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item>
              <Grid container direction="row" alignItems="center" spacing={1}>
                <Grid item>
                  <MobileDateTimePicker
                    label="From"
                    ampm={false}
                    disableFuture={true}
                    slotProps={{
                      textField: {
                        className: "leaderBoard-datepicker-input",
                      },
                      toolbar: {
                        className: "leaderBoard-datepicker",
                      },
                      layout: {
                        className: "leaderBoard-datepicker__layout",
                      },
                    }}
                    className={"dayTimePicker-input"}
                    format={"MMMM dd HH:mm"}
                    maxDate={to}
                    value={from}
                    onChange={(v) => {
                      setFrom(v || DateTime.now());
                    }}
                  />
                </Grid>
                <Grid item>
                  <MobileDateTimePicker
                    ampm={false}
                    label="To"
                    format={"MMMM dd HH:mm"}
                    slotProps={{
                      textField: {
                        className: "leaderBoard-datepicker-input",
                      },
                      toolbar: {
                        className: "leaderBoard-datepicker",
                      },
                      layout: {
                        className: "leaderBoard-datepicker__layout",
                      },
                    }}
                    maxDate={DateTime.now()}
                    minDate={from}
                    value={to}
                    className={"dayTimePicker-input"}
                    onChange={(v) => {
                      setTo(v || DateTime.now());
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item hidden={playersChoosed}>
          <Button
            color="primary"
            variant="contained"
            disabled={!playerIds.length}
            onClick={() => setPlayersChoosed(true)}
          >
            {playerIds.length > 1 ? "Compare Players" : "View Player Stats"}
          </Button>
        </Grid>
      </Grid>

      <FeedbackStats
        teamId={teamId}
        teamMembers={teamMembersQuery.data}
        from={from}
        to={to}
      />
    </Stack>
  );
}

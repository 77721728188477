import { Box } from "@mui/material";
import React from "react";

export type FileUploadComponentProps = {
  handleFile: (file: File) => void;
  children: React.ReactElement<
    React.ReactNode,
    string | React.JSXElementConstructor<React.ReactNode>
  >;
};

export const FileUploadComponent = ({
  children,
  handleFile,
}: FileUploadComponentProps) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  // Programmatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = () => {
    hiddenFileInput &&
      hiddenFileInput.current &&
      hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      const fileUploaded = target.files[0];
      handleFile(fileUploaded);
    }
    return;
  };

  return (
    <Box>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      {React.cloneElement(children, {
        onClick: handleClick,
      } as Partial<React.ReactNode> & React.Attributes)}
    </Box>
  );
};

import * as React from "react";
import {
  Grid,
} from "@mui/material";
import { MobileDateTimePicker } from "components/date-picker.component";
import { DateTime } from "luxon";
import "./date-time.container.style.css";
import { NavigationContext } from "providers/navigation.provider";
import { useAnalytics } from "use-analytics";
import { ButtonSwitch } from "components/button-switch";
import { EventSelector } from "./event-select.container";

export type DateTimeSelectorProps = {
  from: DateTime;
  to: DateTime;
  setFrom: (v: DateTime) => void;
  setTo: (v: DateTime) => void;
  minDate?: DateTime;
  setPopupOpened?: (v: boolean) => void;
};

export const EventOrDateTimeSelector = ({
  setFrom,
  setTo,
  from,
  to,
  setPopupOpened
}: DateTimeSelectorProps) => {
  const analytics = useAnalytics();
  const {
    currentTeam: { teamId },
  } = React.useContext(NavigationContext);
  const search = new URLSearchParams(location.search);
  const eventId = search.get("eventId") || "";
  const [rangeType, setRangeType] = React.useState<"EVENT" | "CUSTOM">("EVENT");
  const now = React.useMemo(() => DateTime.now().toMillis(), []);

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item>
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            <Grid item id="switch">
              <ButtonSwitch
                options={["EVENT", "CUSTOM"]}
                onOptionChange={setRangeType}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid container hidden={rangeType !== "EVENT"}>
              <Grid item>
                <EventSelector
                  to={now}
                  selectedEvent={eventId}
                  onRangeSelect={(a, b) => {
                    setFrom(DateTime.fromMillis(a));
                    setTo(DateTime.fromMillis(b));
                  }}
                  setPopupOpened={setPopupOpened}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={1}
              hidden={rangeType !== "CUSTOM"}
            >
              <Grid item>
                <MobileDateTimePicker
                  label="From"
                  ampm={false}
                  disableFuture={true}
                  slotProps={{
                    textField: {
                      className: "leaderBoard-datepicker-input",
                    },
                    toolbar: {
                      className: "leaderBoard-datepicker",
                    },
                    layout: {
                      className: "leaderBoard-datepicker__layout",
                    },
                  }}
                  className={"dayTimePicker-input"}
                  format={"MMMM dd HH:mm"}
                  maxDate={to}
                  value={from}
                  onChange={(v) => {
                    setFrom(v || DateTime.now());
                  }}
                  onOpen={() => setPopupOpened?.(true)}
                  onClose={() => setPopupOpened?.(false)}
                />
              </Grid>
              <Grid item>
                <MobileDateTimePicker
                  ampm={false}
                  label="To"
                  format={"MMMM dd HH:mm"}
                  slotProps={{
                    textField: {
                      className: "leaderBoard-datepicker-input",
                    },
                    toolbar: {
                      className: "leaderBoard-datepicker",
                    },
                    layout: {
                      className: "leaderBoard-datepicker__layout",
                    },
                  }}
                  maxDate={DateTime.now()}
                  minDate={from}
                  value={to}
                  className={"dayTimePicker-input"}
                  onChange={(v) => {
                    setTo(v || DateTime.now());
                  }}
                  onOpen={() => setPopupOpened?.(true)}
                  onClose={() => setPopupOpened?.(false)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

import * as React from "react";
import { muiTheme } from "theme";
import {
  Button,
  CircularProgress,
  Grid,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import MoodIcon from "@mui/icons-material/Mood";
import SpeedIcon from "@mui/icons-material/Speed";

import { queryClient, useAuthMutation } from "providers/auth";
import { updateUserEvent } from "store/events/events.query";
import { Trans } from "@lingui/macro";
import { useAnalytics } from "use-analytics";
import { UserEvent } from "store/events/events.model";

interface EventFeedbackInputProps {
  userEvent: UserEvent;
  leaveEditMode(): void;
}

export const EventFeedbackInput = ({
  userEvent,
  leaveEditMode,
}: EventFeedbackInputProps) => {
  const analytics = useAnalytics();
  const feedbackMutation = useAuthMutation(
    ["updateUserEvent", userEvent.eventId, userEvent.playerId],
    updateUserEvent(userEvent.eventId, userEvent.playerId),
  );
  const [comment, setComment] = React.useState(
    userEvent.feedback ? userEvent.feedback.comment : "",
  );
  const [intensityRating, setIntensityRating] = React.useState(
    userEvent.feedback ? userEvent.feedback.intensityRating : 1,
  );
  const [satisfactionRating, setSatisfactionRating] = React.useState(
    userEvent.feedback ? userEvent.feedback.satisfactionRating : 1,
  );

  const sendFeedback = () => {
    analytics.track("event-feedback-sent", { eventId: userEvent.eventId, comment, intensityRating, satisfactionRating });
    feedbackMutation.mutate({
      feedback: {
        comment,
        intensityRating,
        satisfactionRating,
      },
    });
  };

  React.useEffect(() => {
    if (feedbackMutation.isSuccess) {
      queryClient.invalidateQueries(["event", userEvent.eventId]);
      leaveEditMode();
    }
  }, [feedbackMutation.isSuccess, userEvent.eventId, userEvent.teamId]);

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <TextField
          multiline
          style={{ width: 600 }}
          inputProps={{ maxLength: 256 }}
          variant="filled"
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          label="Comment"
        />
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography
                  style={{ fontWeight: 400, fontSize: 15, lineHeight: "18px" }}
                >
                  <SpeedIcon
                    style={{ height: 15, color: muiTheme.palette.primary.main }}
                  />
                  <Trans>INTENSITY</Trans>
                </Typography>
              </Grid>
              <Grid item>
                <Slider
                  value={intensityRating}
                  onChange={(_, value) => setIntensityRating(Number(value))}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  style={{ width: 400 }}
                  marks
                  step={1}
                  min={1}
                  max={5}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography
                  style={{ fontWeight: 400, fontSize: 15, lineHeight: "18px" }}
                >
                  <MoodIcon
                    style={{
                      height: 15,
                      color: muiTheme.palette.secondary.main,
                    }}
                  />
                  <Trans>HAPPINESS</Trans>
                </Typography>
              </Grid>
              <Grid item>
                <Slider
                  value={satisfactionRating}
                  onChange={(_, value) => setSatisfactionRating(Number(value))}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  
                  style={{ width: 400 }}
                  marks
                  step={1}
                  min={1}
                  max={5}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                variant="contained"
                color="primary"
                disabled={feedbackMutation.isLoading || !comment}
                onClick={sendFeedback}
                style={{ width: 150 }}
              >
                {feedbackMutation.isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Trans>Save</Trans>
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

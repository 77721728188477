import AdidasLogoIcon from "components/icons/AdidasLogoIcon";
import Link from "components/link.component";
import React, { useRef, useState } from "react";
import {
  LinearProgress,
  Button,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import {
  getHeaders,
  isNotFound,
  isOkResponse,
  send,
} from "services/commons.api";
import { OnboardingLeftContent } from "../onboarding/components/OnboardingLeftContent";
import { Trans } from "@lingui/macro";
import { useHistory } from "react-router-dom";
import { authorizeByCredentials } from "providers/auth";
import config from "config";
import { theme } from "theme";

const LoginContainer = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const emailRef = useRef<HTMLInputElement | null>(null);
  const pwdRef = useRef<HTMLInputElement | null>(null);
  const history = useHistory();
  const query = new URLSearchParams(window.location.search);
  const root = "/onboarding/landing";
  const redirectTo = query.get("redirect") || root;

  const submit = async () => {
    setLoading(true);

    const email = emailRef.current?.value;
    const password = pwdRef.current?.value;

    if (!email || !password) {
      setLoading(false);
      setError("Provide valid credentials.");
      return;
    }

    try {
      const resp = await authorizeByCredentials(email, password);
      const info = await send({
        apiEndpoint: config.API.USER.GET,
        headers: getHeaders(resp.access_token),
      });

      if (isNotFound(info) || !isOkResponse(info)) {
        setLoading(false);
        setError("No user with profile in adidas TEAM FX.");
      } else {
        history.push(redirectTo);
      }
    } catch (err) {
      setLoading(false);
      setError("Unexpected error. Failed to login.");
    }
  };

  const toSelectLogin = () => {
    history.push(`/login?redirect=${redirectTo}`);
  };

  return (
    <div className={"login"}>
      <div className="login-leftSide login-part">
        <OnboardingLeftContent />
      </div>
      <div className="login-rightSide login-part">
        <div className={"login-content"}>
          <div className="topHeader">
            <div className={"login-logo__container"}>
              <AdidasLogoIcon
                onClick={() =>
                  (window.location.href = "https://www.adidas.com/us")
                }
                height={60}
              />
            </div>
            <Typography variant="h1">TEAM FX</Typography>
          </div>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <LinearProgress
                variant={loading ? "indeterminate" : "determinate"}
                value={40}
              />
            </Grid>
            <Grid item width="fit-content">
              <Typography variant="h3">
                <Trans>Sign in with email</Trans>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Trans>Please enter your account email address below.</Trans>
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                variant="filled"
                label={"E-mail"}
                inputRef={emailRef}
                name="email"
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                variant="filled"
                label={"Password"}
                type="password"
                inputRef={pwdRef}
                helperText={error}
                error={Boolean(error)}
                name="password"
                fullWidth
              />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">
                <Trans>
                  Don't remember?{" "}
                  <Link href="https://www.adidas.com/us/account-login" style={{ color: theme.actions.secondary, textDecoration: "underline" }}>
                    Reset your password.
                  </Link>
                </Trans>
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={submit}
                style={{
                  height: 60,
                }}
                fullWidth
                disabled={loading}
              >
                {!loading ? "Login and Continue" : "Loading..."}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                style={{
                  height: 60,
                }}
                fullWidth
                onClick={toSelectLogin}
              >
                <Trans>Go Back</Trans>
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default LoginContainer;

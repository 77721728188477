import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  DialogActions,
  Button,
  Typography,
  Switch, List, ListItem, Collapse, Checkbox, Stack, ListItemButton
} from "@mui/material";
import { ReactNode, useState } from "react";
import { font } from "../../theme";
import CloseIcon from "@mui/icons-material/Close";
import "./filter-modal.style.css";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { PositionFilter } from "./position-models";
import TooltipCustom from "components/tooltip.component";
import { PlayerPosition, PlayerPositionFullTitle } from "@gamer/common/lib/models/user";
import CFGoalSelected from "images/pitch/CFGoalSelected.svg";
import CFGoalDefault from "images/pitch/CFGoalDefault.svg";
import CFGoalHover from "images/pitch/CFGoalSelected.svg";
import CBGoalSelected from "images/pitch/CBGoalSelected.svg";
import CBGoalDefault from "images/pitch/CBGoalDefault.svg";
import CBGoalHover from "images/pitch/CBGoalHover.svg";
import LRMSelected from "images/pitch/LRMSelected.svg";
import LRMDefault from "images/pitch/LRMDefault.svg";
import LRMHover from "images/pitch/LRMHover.svg";
import CMSelected from "images/pitch/CMSelected.svg";
import CMDefault from "images/pitch/CMDefault.svg";
import CMHover from "images/pitch/CMHover.svg";

export type FilterItemProps = {
  title: string;
  value: string;
  onClick: (value: string) => void;
  selected: boolean;
};

export type FilterValue = {
  value: string;
  title?: string;
  groupName: string;
};

export type FilterComponentProps = {
  title: string;
  label: (props: FilterItemProps) => ReactNode;
  open: boolean;
  options: FilterValue[];
  filter: PositionFilter;
  onSave: (filter: PositionFilter) => void;
};

export const PositionFilterModalNew = (props: FilterComponentProps) => {
  const { open } = props;
  const [values, setValues] = useState(new Set<string>(props.filter.positions));
  const [notDefinedEnbled, setNotDefinedEnbled] = useState(props.filter.includeNotDefined);

  const groups = props.options.reduce(
    (acc, { groupName, ...rest }) => ({
      ...acc,
      [groupName]: acc[groupName] ? [...acc[groupName], rest] : [rest],
    }),
    {} as Record<string, Omit<FilterValue, "groupName">[]>,
  );
  const groupNames = Object.keys(groups);
  const [posListOpen, setPosListOpen] =
    useState<{ [id: string]: boolean }>(groupNames.reduce((prev, group) => { prev[group] = false; return prev; }, {}));

  const isAllGroupSelected = (group: string) =>
    groups[group].every((v) => values.has(v.value));

  const isAllSelected = () =>
    Object.keys(groups).every((groupName) => isAllGroupSelected(groupName));

  const isAnyGroupSelected = (group: string) =>
    groups[group].some((v) => values.has(v.value));

  const isAnySelected = () =>
    Object.keys(groups).some((groupName) => isAnyGroupSelected(groupName));

  const handleClose = () => {
    setValues(new Set(props.filter.positions));
    props.onSave({positions: props.filter.positions, includeNotDefined: notDefinedEnbled });
  };

  const handleApply = (values: string[]) => {
    props.onSave({positions: values, includeNotDefined: notDefinedEnbled});
  };

  const handleReset = () => {
    const resetVal = new Set(values);
    resetVal.forEach((val) => resetVal.delete(val));

    setValues(resetVal);
  };

  const onItemClick = (value: string) => {
    const result = new Set(values);

    result.has(value) ? result.delete(value) : result.add(value);

    setValues(result);
  };

  const handleGroupChange = (group: string) => () => {
    const result = new Set(values);

    isAllGroupSelected(group)
      ? groups[group].forEach((v) => result.delete(v.value))
      : groups[group].forEach((v) => result.add(v.value));

    setValues(result);
  };

  const handleAllGroupChange = () => () => {
    const result = new Set(values);

    isAllSelected()
      ? Object.keys(groups).forEach((groupName) => groups[groupName].forEach((v) => result.delete(v.value)))
      : Object.keys(groups).forEach((groupName) => groups[groupName].forEach((v) => result.add(v.value)));

    setValues(result);
  };
  const pitchPositionSpacing = 0.5;
  const pitchPosHeight = 65;
  const pitchPosWidth = 90;
  const bgPosColor = "#262626";
  const getTextColor = (pos: PlayerPosition) => values.has(pos) ? "#51F69D" : "#B3B3B3";

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      scroll="body"
      style={{
        height: 700,
        backgroundImage: "unset",
      }}
      className="filterModal-dialog"
    >
      <DialogTitle id="form-dialog-title">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignContent="center"
        >
          <Grid item>
            <Grid direction="column" alignItems="flex-start" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Filter Positions</Typography>
              </Grid>
              <Grid item>
                <Typography color="#B3B3B3" fontStyle="normal" fontSize={12} fontFamily={font.adineue.cond}>Select the positions you want to display</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon style={{ fontSize: 16 }} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ width: "100%" }}>
        <DialogContentText>
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <Stack direction="row" style={{ width: "100%" }} spacing={8}>
              <Stack direction="column" style={{ width: "40%", height: "410px", overflow: "auto" }}>
                <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
                  <Stack direction="row" alignItems={"center"}>
                    <Checkbox
                      checked={isAllSelected()}
                      indeterminate={!isAllSelected() && isAnySelected()}
                      onClick={handleAllGroupChange()}
                    />
                    <Typography fontSize="16px" fontFamily={font.adihaus.regular}>Select All</Typography>
                  </Stack>
                  <Typography fontSize="12px" fontFamily={font.adihaus.regular}>{values.size} SELECTED</Typography>
                </Stack>
                <List>
                  {groupNames.map((groupName) => (<div>
                    <ListItem
                      style={{ width: "100%", padding: "0px 0px 0px 0px" }}
                    >
                      <Checkbox
                        checked={isAllGroupSelected(groupName)}
                        indeterminate={!isAllGroupSelected(groupName) && isAnyGroupSelected(groupName)}
                        onClick={handleGroupChange(groupName)} />
                      <ListItemButton
                        onClick={() => { setPosListOpen({ ...posListOpen, [groupName]: !posListOpen[groupName] }); }}
                        style={{ width: "100%", padding: "0px 0px 0px 0px" }}
                      >
                        <Stack direction="row" alignContent="space-between" style={{ width: "100%" }}>
                          <Typography fontSize="16px" fontFamily={font.adihaus.regular} style={{ width: "100%" }}>{groupName}</Typography>
                          {posListOpen[groupName] ? <ExpandLess /> : <ExpandMore />}
                        </Stack>
                      </ListItemButton>
                    </ListItem>
                    <Collapse in={posListOpen[groupName]} timeout="auto" unmountOnExit>
                      <List>
                        {groups[groupName].map(({ title, value }) => (<div>
                          <ListItem style={{ padding: "0px 0px 0px 10px" }}>
                            <Checkbox checked={values.has(value)} onClick={() => onItemClick(value)} />
                            <Typography fontSize="16px" fontFamily={font.adihaus.regular}>{title}</Typography>
                          </ListItem>
                        </div>))}
                      </List>
                    </Collapse></div>
                  ))}
                </List>
              </Stack>
              <Stack direction="column" spacing={2 * pitchPositionSpacing} style={{ height: "410px" }}>
                {/* OFFENSE */}
                <Stack id="offenseRow" direction="row" spacing={pitchPositionSpacing} style={{width: "100%"}}>
                  <Stack direction="column" id="leftOffense" spacing={pitchPositionSpacing}>
                    <TooltipCustom
                      arrow
                      placement="left-start"
                      title={PlayerPositionFullTitle[PlayerPosition.LF]}>
                      <Button
                        onClick={() => onItemClick(PlayerPosition.LF)}
                        variant="text"
                        style={{ width: pitchPosWidth, height: pitchPosHeight, textAlign: "left", borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.LF)
                        }}
                      >LF</Button>
                    </TooltipCustom>
                    <TooltipCustom
                      arrow
                      placement="left-start"
                      title={PlayerPositionFullTitle[PlayerPosition.LW]}>
                      <Button
                        onClick={() => onItemClick(PlayerPosition.LW)}
                        variant="text"
                        style={{ width: pitchPosWidth, height: pitchPosHeight, textAlign: "left", borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.LW)
                        }}
                      >LW</Button>
                    </TooltipCustom>
                  </Stack>
                  <Stack direction="column" id="centerOffense" spacing={pitchPositionSpacing}>
                    <TooltipCustom
                      arrow
                      placement="top"
                      title={PlayerPositionFullTitle[PlayerPosition.CF]}>
                      <Button
                        onClick={() => onItemClick(PlayerPosition.CF)}
                        variant="text"
                        style={{ 
                          width: pitchPosWidth, height: pitchPosHeight, textAlign: "left", borderRadius: 4,
                          backgroundImage: `url(${values.has(PlayerPosition.CF) ? CFGoalSelected : CFGoalDefault})`,
                          backgroundPosition: "top",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.CF)
                        }}
                        >
                          CF
                      </Button>
                    </TooltipCustom>
                    <TooltipCustom
                      arrow
                      placement="bottom"
                      title={PlayerPositionFullTitle[PlayerPosition.ST]}>
                      <Button
                        onClick={() => onItemClick(PlayerPosition.ST)}
                        variant="text"
                        style={{ width: pitchPosWidth, height: pitchPosHeight, textAlign: "left", borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.ST)
                        }}
                      >ST</Button>
                    </TooltipCustom>
                  </Stack>
                  <Stack direction="column" id="rightOffense" spacing={pitchPositionSpacing}>
                    <TooltipCustom
                      arrow
                      placement="right-start"
                      title={PlayerPositionFullTitle[PlayerPosition.RF]}>
                      <Button
                        onClick={() => onItemClick(PlayerPosition.RF)}
                        variant="text"
                        style={{ width: pitchPosWidth, height: pitchPosHeight, textAlign: "left", borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.RF)
                        }}
                      >RF</Button>
                    </TooltipCustom>
                    <TooltipCustom
                      arrow
                      placement="right-start"
                      title={PlayerPositionFullTitle[PlayerPosition.RW]}>
                      <Button
                        onClick={() => onItemClick(PlayerPosition.RW)}
                        variant="text"
                        style={{ width: pitchPosWidth, height: pitchPosHeight, textAlign: "left", borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.RW)
                        }}
                      >RW</Button>
                    </TooltipCustom>
                  </Stack>
                  <Typography sx={{textOrientation: "sideways", writingMode: "vertical-lr", textAlign: "center"}}>OFFENSE</Typography>
                </Stack>
                {/* MIDFIELD */}
                <Stack id="midfieldRow" direction="row" spacing={pitchPositionSpacing}>
                  <Stack direction="column" id="leftMid" spacing={pitchPositionSpacing}>
                    <TooltipCustom
                      arrow
                      placement="left-start"
                      title={PlayerPositionFullTitle[PlayerPosition.LM]}>
                      <Button
                        onClick={() => onItemClick(PlayerPosition.LM)}
                        variant="text"
                        style={{ width: pitchPosWidth, height: "100%", textAlign: "left", borderRadius: 4,
                          backgroundColor: bgPosColor,
                          backgroundImage: `url(${values.has(PlayerPosition.LM) ? LRMSelected : LRMDefault})`,
                          backgroundPosition: "center",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          color: getTextColor(PlayerPosition.LM)
                        }}
                      >LM</Button>
                    </TooltipCustom>
                  </Stack>
                  <Stack direction="column" id="centerMid" spacing={pitchPositionSpacing}>
                    <TooltipCustom
                      arrow
                      placement="top"
                      title={PlayerPositionFullTitle[PlayerPosition.CAM]}>
                      <Button
                        onClick={() => onItemClick(PlayerPosition.CAM)}
                        variant="text"
                        style={{ 
                          width: pitchPosWidth, height: 2 * pitchPosHeight / 3, textAlign: "left", borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.CAM)
                        }}
                        >
                          CAM
                      </Button>
                    </TooltipCustom>
                    <TooltipCustom
                      arrow
                      placement="left-start"
                      title={PlayerPositionFullTitle[PlayerPosition.CM]}>
                      <Button
                        onClick={() => onItemClick(PlayerPosition.CM)}
                        variant="text"
                        style={{ width: pitchPosWidth, height: 2 * pitchPosHeight / 3, textAlign: "left", borderRadius: 4,
                          backgroundColor: bgPosColor,
                          backgroundImage: `url(${values.has(PlayerPosition.CM) ? CMSelected : CMDefault})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          color: getTextColor(PlayerPosition.CM)
                        }}
                      >CM</Button>
                    </TooltipCustom>
                    <TooltipCustom
                      arrow
                      placement="bottom"
                      title={PlayerPositionFullTitle[PlayerPosition.CDM]}>
                      <Button
                        onClick={() => onItemClick(PlayerPosition.CDM)}
                        variant="text"
                        style={{ 
                          width: pitchPosWidth, height: 2 * pitchPosHeight / 3, textAlign: "left", borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.CDM)
                        }}
                        >
                          CDM
                      </Button>
                    </TooltipCustom>
                  </Stack>
                  <Stack direction="column" id="rightMid" spacing={pitchPositionSpacing}>
                    <TooltipCustom
                      arrow
                      placement="right-start"
                      title={PlayerPositionFullTitle[PlayerPosition.RM]}>
                      <Button
                        onClick={() => onItemClick(PlayerPosition.RM)}
                        variant="text"
                        style={{ width: pitchPosWidth, height: "100%", textAlign: "left", borderRadius: 4,
                          backgroundColor: bgPosColor,
                          backgroundImage: `url(${values.has(PlayerPosition.LM) ? LRMSelected : LRMDefault})`,
                          backgroundPosition: "center",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          color: getTextColor(PlayerPosition.RM)
                        }}
                      >RM</Button>
                    </TooltipCustom>
                  </Stack>
                  <Typography sx={{textOrientation: "sideways", writingMode: "vertical-lr", textAlign: "center"}}>MIDFIELD</Typography>
                </Stack>
                {/* DEFENSE */}
                <Stack id="defenseRow" direction="row" spacing={pitchPositionSpacing}>
                  <Stack direction="column" id="leftDefense" spacing={pitchPositionSpacing}>
                    <TooltipCustom
                      arrow
                      placement="left-start"
                      title={PlayerPositionFullTitle[PlayerPosition.LWB]}>
                      <Button
                        onClick={() => onItemClick(PlayerPosition.LWB)}
                        variant="text"
                        style={{ width: pitchPosWidth, height: pitchPosHeight, textAlign: "left", borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.LWB)
                        }}
                      >LWB</Button>
                    </TooltipCustom>
                    <TooltipCustom
                      arrow
                      placement="left-start"
                      title={PlayerPositionFullTitle[PlayerPosition.LB]}>
                      <Button
                        onClick={() => onItemClick(PlayerPosition.LB)}
                        variant="text"
                        style={{ width: pitchPosWidth, height: pitchPosHeight, textAlign: "left", borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.LB)
                        }}
                      >LB</Button>
                    </TooltipCustom>
                  </Stack>
                  <Stack direction="column" id="centerDefense" spacing={pitchPositionSpacing}>
                    <TooltipCustom
                      arrow
                      placement="top"
                      title={PlayerPositionFullTitle[PlayerPosition.CB]}>
                      <Button
                        onClick={() => onItemClick(PlayerPosition.CB)}
                        variant="text"
                        style={{ 
                          width: pitchPosWidth, height: pitchPosHeight, textAlign: "left", borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.CB)
                        }}
                        >
                          CB
                      </Button>
                    </TooltipCustom>
                    <TooltipCustom
                      arrow
                      placement="bottom"
                      title={PlayerPositionFullTitle[PlayerPosition.GK]}>
                      <Button
                        onClick={() => onItemClick(PlayerPosition.GK)}
                        variant="text"
                        style={{ width: pitchPosWidth, height: pitchPosHeight, textAlign: "left", borderRadius: 4,
                          backgroundColor: bgPosColor,
                          backgroundImage: `url(${values.has(PlayerPosition.GK) ? CBGoalSelected : CBGoalDefault})`,
                          backgroundPosition: "bottom",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          color: getTextColor(PlayerPosition.GK)
                        }}
                      >GK</Button>
                    </TooltipCustom>
                  </Stack>
                  <Stack direction="column" id="rightDefense" spacing={pitchPositionSpacing}>
                    <TooltipCustom
                      arrow
                      placement="right-start"
                      title={PlayerPositionFullTitle[PlayerPosition.RWB]}>
                      <Button
                        onClick={() => onItemClick(PlayerPosition.RWB)}
                        variant="text"
                        style={{ width: pitchPosWidth, height: pitchPosHeight, textAlign: "left", borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.RWB)
                        }}
                      >RWB</Button>
                    </TooltipCustom>
                    <TooltipCustom
                      arrow
                      placement="right-start"
                      title={PlayerPositionFullTitle[PlayerPosition.RB]}>
                      <Button
                        onClick={() => onItemClick(PlayerPosition.RB)}
                        variant="text"
                        style={{ width: pitchPosWidth, height: pitchPosHeight, textAlign: "left", borderRadius: 4,
                          backgroundColor: bgPosColor,
                          color: getTextColor(PlayerPosition.RB)
                        }}
                      >RB</Button>
                    </TooltipCustom>
                  </Stack>
                  <Typography sx={{textOrientation: "sideways", writingMode: "vertical-lr", textAlign: "center"}}>DEFENSE</Typography>
                </Stack>
              </Stack>
            </Stack>
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: "16px 24px" }}>
        <Stack direction="column" spacing={1} style={{ width: "660px"}}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item>
              <Switch checked={notDefinedEnbled} onClick={() => setNotDefinedEnbled(!notDefinedEnbled)} />
            </Grid>&nbsp;
            <Grid item>
              <Typography fontFamily={font.adihaus.regular} fontSize={14}>Include players with undefined position</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item>
              <Button variant="outlined" onClick={() => handleReset()}>
                Clear All
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={() => handleClose()}>
                Cancel
              </Button>
              &nbsp;
              <Button
                disabled={values.size === props.options.length}
                variant="contained"
                onClick={() => handleApply(Array.from<string>(values))}
              >
                Apply Filter
              </Button>
            </Grid>
          </Grid>                        
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

import history from "services/history";
import {
  QueryFunction,
  QueryKey,
  useQuery,
  useMutation,
  UseQueryOptions,
  UseQueryResult,
  MutationKey,
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
  QueryClient,
} from "react-query";
import {
  ISendParams,
  isError,
  send,
  HttpResponse,
  IErrorResult,
} from "services/commons.api";
import {
  loginUser,
  loginWithCode,
  loginWithRefresh,
} from "store/user/user.api";

export type AuthResponse = {
  access_token: string;
  refresh_token: string;
  expires_in: number;
};
export const storeToken = (data: AuthResponse) => {
  localStorage.setItem("jwtToken", data.access_token);
  localStorage.setItem("refreshToken", data.refresh_token);
  localStorage.setItem("tokenTTL", String(Date.now() + data.expires_in * 1000));
};

export const getToken = () => {
  const data = {
    jwtToken: localStorage.getItem("jwtToken"),
    refreshToken: localStorage.getItem("refreshToken"),
    tokenTTL: Number(localStorage.getItem("tokenTTL")),
  };

  return data;
};

export const clearToken = () => {
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("tokenTTL");
};

export type IErrorData = { detail?: string; title?: string };
// Create a client
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      retry: 2,
      retryDelay: 2000,
    },
    mutations: {
      retry: 0,
      retryDelay: 10000,
    },
  },
});

export const useAuthQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: (token: string) => QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "queryKey" | "queryFn"
  >,
): UseQueryResult<TData, TError> => {
  let token = getToken().jwtToken as string;

  if (!token) {
    history.push("/logout");
  }

  return useQuery(queryKey, queryFn(token), {
    onError: async (error) => {
      const auth = getToken();
      const httpError = error as unknown as HttpResponse<IErrorData>;
      const is403 =
        httpError.status === 0 || // To catch CORS 403 which appears first
        httpError.status === 403 ||
        (httpError.data &&
          httpError.data.detail &&
          (httpError.data.detail?.includes("INVALID_TOKEN") ||
            httpError.data.detail?.includes("EXPIRED_TOKEN")));
      const executeRefresh = is403 && auth.jwtToken === token;

      if (executeRefresh) {
        const { refreshToken } = getToken();

        if (!refreshToken) {
          history.push("/logout");
          return;
        }
        try {
          await authorizeByRefreshToken();
          token = getToken().jwtToken as string;
        } catch (err) {
          history.push("/logout");
        }
      }
    },
    ...options,
  });
};

export const useAuthMutation = <
  TData = unknown,
  TError = IErrorResult,
  TVariables = void,
  TContext = unknown,
>(
  mutationKey: MutationKey,
  mutationFn: (token: string) => MutationFunction<TData, TVariables>,
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    "mutationKey" | "mutationFn"
  >,
): UseMutationResult<TData, TError, TVariables, TContext> => {
  let token = getToken().jwtToken as string;

  if (!token) {
    history.push("/logout");
  }

  return useMutation(mutationKey, mutationFn(token), {
    onError: async (error) => {
      const auth = getToken();
      const httpError = error as unknown as HttpResponse<IErrorData>;
      const is403 =
        httpError.status === 0 || // To catch CORS 403 which appears first
        httpError.status === 403 ||
        httpError.data.detail?.includes("INVALID_TOKEN") ||
        httpError.data.detail?.includes("EXPIRED_TOKEN");
      const executeRefresh = is403 && auth.jwtToken === token;

      if (executeRefresh) {
        const { refreshToken } = getToken();

        if (!refreshToken) {
          history.push("/logout");
          return;
        }
        try {
          await authorizeByRefreshToken();
          token = getToken().jwtToken as string;
        } catch (err) {
          history.push("/logout");
        }
      }
    },
    ...options,
  });
};

export const sendQuery = <T>(params: ISendParams) => {
  return send<T>(params).then((v) => {
    if (isError(v)) {
      throw v.data;
    }

    return v.data;
  });
};

export const authorizeByCredentials = async (
  login: string,
  password: string,
) => {
  const resp = await loginUser(login, password);

  if (isError(resp)) {
    throw resp.data;
  }

  storeToken(resp.data as any);

  return resp.data;
};

export const authorizeByCode = async (code: string) => {
  const resp = await loginWithCode(code);

  if (isError(resp)) {
    throw resp.data;
  }

  storeToken(resp.data as any);

  return resp.data;
};

export const authorizeByRefreshToken = async () => {
  const { refreshToken } = getToken();

  const resp = await loginWithRefresh(refreshToken as string);

  if (isError(resp) || resp.data.error) {
    clearToken();
    throw resp.data;
  }
  storeToken(resp.data as any);

  return resp.data;
};

export const resetAuthorization = () => {
  clearToken();
};

import { Grid, Skeleton } from "@mui/material";
import { PlayerTwoStatsBars } from "components/charts/player-two-stats-bars";
import { PlayerTwoStatsLines } from "components/charts/player-two-stats-line";
import { PlayerStatPie } from "components/charts/player-stat-pie";
import * as React from "react";
import { SingleStatProps } from "components/utils/utils";
import {
  BallSpeedZone,
  IntervalMetric,
  RunSpeedZone,
} from "store/member-stats/member-stats.model";
import { DAY_IN_MS, M_IN_KM, MPS_TO_KMPH } from "../analytic.page";
import { CHARTS_COLORS } from "hooks/useGetChartsData";
import { DateTime } from "luxon";
import { useAuthQuery } from "providers/auth";
import { getMemberStats } from "store/member-stats/member-stats.query";

type PlayerStatsProps = {
  memberId: string;
  teamId: string;
  from: number;
  to: number;
  queryInterval: string;
  setDisabledPlayerIds?: (playerIds: string[]) => void;
  onExport?: (data: IntervalMetric[][]) => void;
};

export default function PlayerStats({
  memberId,
  from,
  to,
  teamId,
  onExport,
  setDisabledPlayerIds
}: PlayerStatsProps) {
  const [queryInterval, setQueryInterval] = React.useState<string>("auto");
  const memberStatsQuery = useAuthQuery(
    ["memberStats", teamId, memberId, from, to, queryInterval],
    getMemberStats({
      teamId,
      memberId,
      from,
      to,
      interval: queryInterval,
    }),
  );

  const [chartDistData, setChartDistData] = React.useState<SingleStatProps>({
    chartEntryColor: CHARTS_COLORS[0],
    statName: "Distance",
    statsMeasure: "KM",
  });
  const [chartKickData, setChartKickData] = React.useState<SingleStatProps>({
    chartEntryColor: CHARTS_COLORS[1],
    statName: "Kicks Count",
    statsMeasure: "COUNT",
  });

  const [chartExpCountData, setChartExpCountData] =
    React.useState<SingleStatProps>({
      chartEntryColor: CHARTS_COLORS[2],
      statName: "Bursts Count",
      statsMeasure: "COUNT",
    });
  const [chartExpDistData, setChartExpDistData] =
    React.useState<SingleStatProps>({
      chartEntryColor: CHARTS_COLORS[3],
      statName: "Explosiveness Distance",
      statsMeasure: "M",
    });

  const [chartSprintCountData, setChartSprintCountData] =
    React.useState<SingleStatProps>({
      chartEntryColor: CHARTS_COLORS[2],
      statName: "Sprint Count",
      statsMeasure: "COUNT",
    });
  const [chartSprintDistData, setChartSprintDistData] =
    React.useState<SingleStatProps>({
      chartEntryColor: CHARTS_COLORS[3],
      statName: "Sprint Distance",
      statsMeasure: "M",
    });

  const [chartAvgSpeedData, setChartAvgSpeedData] =
    React.useState<SingleStatProps>({
      chartEntryColor: CHARTS_COLORS[0],
      statName: "Avg Running Speed",
      statsMeasure: "KM/H",
    });
  const [chartPeakSpeedData, setChartPeakSpeedData] =
    React.useState<SingleStatProps>({
      chartEntryColor: CHARTS_COLORS[1],
      statName: "Peak Running Speed",
      statsMeasure: "KM/H",
    });

  const [chartAvgBallData, setChartAvgBallData] =
    React.useState<SingleStatProps>({
      chartEntryColor: CHARTS_COLORS[2],
      statName: "Avg Ball Speed",
      statsMeasure: "KM/H",
    });
  const [chartTopBallData, setChartTopBallData] =
    React.useState<SingleStatProps>({
      chartEntryColor: CHARTS_COLORS[3],
      statName: "Top Ball Speed",
      statsMeasure: "KM/H",
    });

  const [chartTimeLabels, setChartTimeLabels] = React.useState<Array<string>>(
    [],
  );

  const [chartBallZonesData, setChartBallZonesData] = React.useState<
    Array<number>
  >([]);
  const [chartBallZonesLabels] = React.useState<Array<string>>([
    "COLD",
    "MEDIUM",
    "HOT",
    "FIRE",
  ]);

  const [chartAvgRunZonesData, setChartAvgRunZonesData] = React.useState<
    Array<number>
  >([]);
  const [chartPeakRunZonesData, setChartPeakRunZonesData] = React.useState<
    Array<number>
  >([]);
  const [chartRunZonesLabels] = React.useState<Array<string>>([
    "WALK",
    "JOG",
    "RUN",
    "HIGH_SPEED_RUN",
    "SPRINT",
  ]);

  React.useEffect(() => {
    if (memberStatsQuery.data && chartTimeLabels.length === 0) {

      if (memberStatsQuery.data.data.length === 0) {
        setDisabledPlayerIds?.([memberId]);
      }

      const intervalItems = memberStatsQuery.data.data;
      const interval = intervalItems.length > 0 ? intervalItems[0].interval : 0;

      const spreadMS =
        intervalItems.length > 0
          ? intervalItems[intervalItems.length - 1].intervalStart -
            intervalItems[0].intervalStart
          : 0;
      const daysInclude = DAY_IN_MS < spreadMS;

      const distItems = intervalItems.map(
        (element) => element.playerMotion.totalDistance / M_IN_KM,
      );
      const kickItems = intervalItems.map((element) => element.kicks.count);

      const expDistItems = intervalItems.map(
        (element) => element.explosiveness.totalDistance / M_IN_KM,
      );
      const expCntItems = intervalItems.map(
        (element) => element.explosiveness.count,
      );
      const sprintDistItems = intervalItems.map(
        (element) => element.sprint.totalDistance / M_IN_KM,
      );
      const sprintCntItems = intervalItems.map(
        (element) => element.sprint.count,
      );

      const avgSpeedItems = intervalItems.map(
        (element) => element.playerMotion.avgRunningSpeed * MPS_TO_KMPH,
      );
      const peakSpeedItems = intervalItems.map(
        (element) => element.playerMotion.topRunningSpeed * MPS_TO_KMPH,
      );

      const avgBallItems = intervalItems.map(
        (element) => element.kicks.avgBallSpeed,
      );
      const topBallItems = intervalItems.map(
        (element) => element.kicks.topBallSpeed,
      );

      const ballZones = {
        [BallSpeedZone.COLD]: 0,
        [BallSpeedZone.MEDIUM]: 0,
        [BallSpeedZone.HOT]: 0,
        [BallSpeedZone.FIRE]: 0,
      };
      intervalItems.forEach((element) => {
        element.kicks.zones.forEach((zone) => {
          ballZones[zone.zone] += zone.count;
        });
      });

      const avgRunZones: Record<any, number> = {
        [RunSpeedZone.WALK]: 0,
        [RunSpeedZone.JOG]: 0,
        [RunSpeedZone.RUN]: 0,
        [RunSpeedZone.HIGH_SPEED_RUN]: 0,
        [RunSpeedZone.SPRINT]: 0,
      };
      intervalItems.forEach((element) => {
        element.playerMotion.avgSpeedZones.forEach((zone) => {
          avgRunZones[zone.zone] += zone.zoneDist / M_IN_KM;
        });
      });

      const peakRunZones: Record<any, number> = {
        [RunSpeedZone.WALK]: 0,
        [RunSpeedZone.JOG]: 0,
        [RunSpeedZone.RUN]: 0,
        [RunSpeedZone.HIGH_SPEED_RUN]: 0,
        [RunSpeedZone.SPRINT]: 0,
      };
      intervalItems.forEach((element) => {
        element.playerMotion.peakSpeedZones.forEach((zone) => {
          peakRunZones[zone.zone as RunSpeedZone] += zone.zoneDist / M_IN_KM;
        });
      });

      const labels = intervalItems.map((element, index) => {
        const date = DateTime.fromMillis(element.intervalStart + interval / 2);
        return daysInclude || index === 0
          ? date.toFormat("HH:mm dd/MM/yyyy")
          : date.toFormat("HH:mm");
      });

      setChartDistData((prevStats) => ({ ...prevStats, items: distItems }));
      setChartKickData((prevStats) => ({ ...prevStats, items: kickItems }));

      setChartExpCountData((prevStats) => ({
        ...prevStats,
        items: expCntItems,
      }));
      setChartExpDistData((prevStats) => ({
        ...prevStats,
        items: expDistItems,
      }));
      setChartSprintCountData((prevStats) => ({
        ...prevStats,
        items: sprintCntItems,
      }));
      setChartSprintDistData((prevStats) => ({
        ...prevStats,
        items: sprintDistItems,
      }));

      setChartAvgSpeedData((prevStats) => ({
        ...prevStats,
        items: avgSpeedItems,
      }));
      setChartPeakSpeedData((prevStats) => ({
        ...prevStats,
        items: peakSpeedItems,
      }));

      setChartAvgBallData((prevStats) => ({
        ...prevStats,
        items: avgBallItems,
      }));
      setChartTopBallData((prevStats) => ({
        ...prevStats,
        items: topBallItems,
      }));

      setChartBallZonesData(Object.values(ballZones));

      setChartAvgRunZonesData(Object.values(avgRunZones));
      setChartPeakRunZonesData(Object.values(peakRunZones));

      setChartTimeLabels(labels);
    }
  }, [memberStatsQuery.isSuccess, memberStatsQuery.data, memberId]);

  React.useEffect(() => {
    if (memberStatsQuery.isSuccess) {
      onExport?.([memberStatsQuery.data.data]);
    }
  }, [memberStatsQuery.isSuccess]);

  return (
    <>
      {memberStatsQuery.isLoading ? (
        <>
          <Skeleton variant="rectangular" height="300px" />
          <Skeleton variant="rectangular" height="300px" />
          <Skeleton variant="rectangular" height="300px" />
          <Skeleton variant="rectangular" height="300px" />
          <Skeleton variant="rectangular" height="300px" />
          <Skeleton variant="rectangular" height="300px" />
          <Skeleton variant="rectangular" height="300px" />
          <Skeleton variant="rectangular" height="300px" />
        </>
      ) : (
        <>
          <Grid item style={{ height: "300px" }}>
            <PlayerTwoStatsBars
              title="Distance vs Kicks Count"
              useSingleYAxis={false}
              data={{ statOne: chartDistData, statTwo: chartKickData }}
              labels={chartTimeLabels}
            />
          </Grid>
          <Grid item style={{ height: "300px" }}>
            <PlayerTwoStatsBars
              useSingleYAxis={false}
              title="Explosiveness Distance vs Explosiveness Count"
              data={{ statOne: chartExpDistData, statTwo: chartExpCountData }}
              labels={chartTimeLabels}
            />
          </Grid>
          <Grid item style={{ height: "300px" }}>
            <PlayerTwoStatsBars
              useSingleYAxis={false}
              title="Sprint Distance vs Sprint Count"
              data={{
                statOne: chartSprintDistData,
                statTwo: chartSprintCountData,
              }}
              labels={chartTimeLabels}
            />
          </Grid>
          <Grid item style={{ height: "300px" }}>
            <PlayerTwoStatsLines
              title="Avg Speed vs Peak Speed"
              useSingleYAxis={true}
              data={{ statOne: chartAvgSpeedData, statTwo: chartPeakSpeedData }}
              labels={chartTimeLabels}
            />
          </Grid>
          <Grid item style={{ height: "300px" }}>
            <PlayerTwoStatsBars
              title="Top Ball Speed vs Avg Ball Speed"
              useSingleYAxis={true}
              data={{ statOne: chartTopBallData, statTwo: chartAvgBallData }}
              labels={chartTimeLabels}
            />
          </Grid>
          <Grid item style={{ height: "300px" }}>
            <PlayerStatPie
              items={chartBallZonesData}
              statName={"Ball Speed Zones"}
              chartEntryColors={CHARTS_COLORS}
              statsMeasure={"COUNT"}
              labels={chartBallZonesLabels}
            />
          </Grid>
          <Grid item style={{ height: "300px" }}>
            <Grid container direction="row" justifyContent="space-evenly">
              <Grid item style={{ height: "300px" }}>
                <PlayerStatPie
                  items={chartAvgRunZonesData}
                  statName={"Average Run Speed Zones"}
                  chartEntryColors={CHARTS_COLORS}
                  statsMeasure={"KM"}
                  labels={chartRunZonesLabels}
                />
              </Grid>
              <Grid item>
                <PlayerStatPie
                  items={chartPeakRunZonesData}
                  statName={"Peak Run Speed Zones"}
                  chartEntryColors={CHARTS_COLORS}
                  statsMeasure={"KM"}
                  labels={chartRunZonesLabels}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

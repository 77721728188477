import {
  ReoccuranceProps,
  RepeatFrequency,
} from "containers/timeline/components/event-repeating";
import { DateTime } from "luxon";
import { validateName } from "store/user";
import iCal from "ical.js";
import {
  ReoccuringDaysProps,
  WeekDays,
} from "containers/timeline/components/event-repeating-checkbox";

export const onNameChange =
  (setError: (v: string) => void, setVal: (v: string) => void) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    const v8n = validateName(value);
    if (v8n) {
      setError(v8n);
    } else {
      setError("");
    }
    setVal(value);
  };

export interface SingleStatProps {
  items?: Array<number>;
  statName: string;
  statsMeasure: string;
  chartEntryColor?: string;
  labels?: Array<string>;
  chartEntryColors?: Array<string>;
}

export interface PlayerTwoStatsProps {
  data?: { statOne?: SingleStatProps; statTwo?: SingleStatProps };
  labels?: Array<string>;
  title?: string;
  useSingleYAxis?: boolean;
  onElementClick?: (datasetId: number, index: number) => void;
}

export const getCombinedDateTime = (date: DateTime, time: DateTime) => {
  const { year, month, day } = date;
  const { hour, minute } = time;

  return DateTime.local(year, month, day, hour, minute);
};

export const PRODID = "-//adidas//TeamFX//Web";

export const isReoccurenceDiffer = (prev: ReoccuranceProps, next: ReoccuranceProps) => {
  if (prev.isRepeating !== next.isRepeating) {
    return true;
  }

  if (prev.repeatFreq !== next.repeatFreq) {
    return true;
  }

  if (prev.repeatDays.length !== next.repeatDays.length) {
    return true;
  }

  for (let i = 0; i < prev.repeatDays.length; i++) {
    if (prev.repeatDays[i].day !== next.repeatDays[i].day) {
      return true;
    }

    if (prev.repeatDays[i].startTime?.toMillis() !== next.repeatDays[i].startTime?.toMillis()) {
      return true;
    }

    if (prev.repeatDays[i].endTime?.toMillis() !== next.repeatDays[i].endTime?.toMillis()) {
      return true;
    }
  }
  return false;
};

export const createIcsReoccuringEvent = (
  data: ReoccuranceProps,
  summary: string,
): string => {
  let events: string = "";

  const index = Object.values(RepeatFrequency).indexOf(data.repeatFreq);
  let interval: string = "";
  if (index === 1) {
    interval = `;INTERVAL=${index + 1}`;
  } else if (index === 2) {
    interval = `;INTERVAL=${index + 2}`;
  }

  data.repeatDays.forEach((day) => {
    const endTime: DateTime = day.endTime || DateTime.now();
    const startTime: DateTime = day.startTime || DateTime.now();

    const byDay: string = day.day.slice(0, 2).toUpperCase();
    const dtStart = getCombinedDateTime(data.dtStart, startTime);
    const until = getCombinedDateTime(data.dtEnd, endTime);

    const startTimeForDay = getCombinedDateTime(DateTime.now(), startTime);
    const endTimeForDay = getCombinedDateTime(DateTime.now(), endTime);
    const duration = DateTime.fromJSDate(endTimeForDay.toJSDate()).diff(
      startTimeForDay,
      ["minutes"],
    );

    events += `BEGIN:VEVENT\nUID:${crypto.randomUUID()}\nDTSTART:${dtStart
      .toUTC()
      .toFormat(
        "yyyyMMdd'T'HHmmss'Z'",
      )}\nDURATION:${duration}\nRRULE:FREQ=WEEKLY;UNTIL=${until
      .toUTC()
      .toFormat(
        "yyyyMMdd'T'HHmmss'Z'",
      )};BYDAY=${byDay}${interval}\nSUMMARY:${summary}\nEND:VEVENT\n`;
  });

  const reoccurrence: string = `BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:${PRODID}\n${events}END:VCALENDAR`;
  return reoccurrence;
};

export const parseIcsEvents = (reoccurrence?: string): ReoccuranceProps => {
  const reoccurrenceParsed: ReoccuranceProps = {
    isRepeating: false,
    repeatFreq: RepeatFrequency.EVERY_WEEK,
    repeatDays: [],
    dtStart: DateTime.now(),
    dtEnd: DateTime.now().plus({ year: 1, hour: 1 }),
  };

  if (!reoccurrence) {
    return reoccurrenceParsed;
  }

  const data = iCal.parse(reoccurrence);
  const comp = new iCal.Component(data);
  const vevents = comp.getAllSubcomponents("vevent");

  vevents.forEach((vevent) => {
    const recurDay: ReoccuringDaysProps = {
      isChecked: true,
      day: WeekDays.MO,
    };

    const rrule = vevent.getFirstPropertyValue("rrule");
    const dtstart = vevent.getFirstPropertyValue("dtstart");
    const until = rrule.until;
    const byDay = rrule.parts.BYDAY[0];

    const startTime = DateTime.fromJSDate(dtstart.toJSDate());
    const endTime = DateTime.fromJSDate(until.toJSDate());

    reoccurrenceParsed.dtStart = startTime.toLocal();
    reoccurrenceParsed.dtEnd = endTime.toLocal();
    reoccurrenceParsed.isRepeating = true;
    reoccurrenceParsed.repeatFreq = getRepeatFrequency(
      rrule.freq,
      rrule.interval,
    );

    recurDay.startTime = startTime;
    recurDay.endTime = endTime;
    recurDay.day = WeekDays[byDay as keyof typeof WeekDays];

    reoccurrenceParsed.repeatDays.push(recurDay);
  });

  return reoccurrenceParsed;
};

export const getRepeatFrequency = (
  freq: string,
  interval: number,
): RepeatFrequency => {
  if (freq === "WEEKLY" && interval == 2) {
    return RepeatFrequency.EVERY_SECOND_WEEK;
  } else if (freq === "WEEKLY" && interval == 4) {
    return RepeatFrequency.EVERY_FOUR_WEEK;
  } else {
    return RepeatFrequency.EVERY_WEEK;
  }
};

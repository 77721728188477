import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  useMediaQuery,
  IconButton,
  Paper,
  Box,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import theme from "theme";
import { queryClient, useAuthMutation } from "providers/auth";
import { DateTime } from "luxon";
import { EventUpdateInput, updateEvent } from "store/events/events.query";
import { EventType, TeamEvent } from "store/events/events.model";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { Trans } from "@lingui/macro";
import { NavigationContext } from "providers/navigation.provider";
import { MobileDatePicker } from "@mui/x-date-pickers";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ToggleButtonGroupCustom from "components/toggleButton/toggle-button-group.component";
import {
  createIcsReoccuringEvent,
  getCombinedDateTime,
  isReoccurenceDiffer,
  parseIcsEvents,
} from "components/utils/utils";
import "./event-timeline.css";
import {
  EventRepeating,
  ReoccuranceProps,
} from "../components/event-repeating";
import { MobileTimePicker } from "components/date-picker.component";

type TeamModalProps = {
  open: boolean;
  onClose: (eventUpdated: boolean) => void;
  isUpdatingSeries: boolean;
} & TeamEvent;

export const UpdateTimelineEventModal = (props: TeamModalProps) => {
  const {
    open,
    onClose,
    eventId,
    isUpdatingSeries,
    startTime,
    endTime,
    type,
    title,
    locationName,
    description,
    reoccurrence,
    matchDetails,
    seriesId,
  } = props;

  const updateEventWithId = isUpdatingSeries ? seriesId ?? eventId : eventId;
  const mutation = useAuthMutation(
    "updateEvent",
    updateEvent(updateEventWithId),
  );

  const {
    opponentTeamName = "",
    opponentScore = 0,
    teamScore = 0,
  } = matchDetails ?? {};

  const [eventName, setEventName] = useState(title);
  const [eventType, setEventType] = useState(type);
  const [startTimestamp, setStartTimestamp] = useState(
    DateTime.fromMillis(startTime),
  );
  const [selectedDate, setSelectedDate] = useState(
    DateTime.fromMillis(startTime),
  );
  const { currentTeam } = React.useContext(NavigationContext);
  const [endTimestamp, setEndTimestamp] = useState(
    DateTime.fromMillis(endTime),
  );
  const [location, setLocation] = useState(locationName);
  const [trainingDescription, setTrainingDescription] = useState(description);
  const [opponentName, setOpponentName] = useState(opponentTeamName);
  const [opponentTeamScore, setOpponentTeamScore] = useState(opponentScore);
  const [myTeamScore, setMyTeamScore] = useState(teamScore);
  const isSmallScreen = useMediaQuery("(max-width: 480px)");
  const [eventNameError, setEventNameError] = useState("");
  const [opponentTeamNameError, setOpponentTeamNameError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [startTimestampError, setstartTimestampError] = useState(false);
  const [endTimestampError, setendTimestampError] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [reoccurance, setReoccurance] = useState<ReoccuranceProps>(
    parseIcsEvents(seriesId ? reoccurrence : ""),
  );

  const eventTypes = Object.values(EventType);

  const validateInput = (input: string) => {
    const MAX_NAME_LENGTH = 64;
    const MIN_NAME_LENGTH = 2;
    const reg = /^([a-zA-Z0-9 _.-]+)$/;
    if (input.length < MIN_NAME_LENGTH) {
      return `Should be at least length of ${MIN_NAME_LENGTH}`;
    }

    if (input.length > MAX_NAME_LENGTH) {
      return `Should not be more then ${MAX_NAME_LENGTH}`;
    }

    if (!input.match(reg)) {
      return "Should include only letters of alphabet";
    }

    return "";
  };

  useEffect(() => {
    eventName && setEventNameError(validateInput(eventName));
    opponentName && setOpponentTeamNameError(validateInput(opponentName));
    location && setLocationError(validateInput(location));

    setSubmitEnabled(
      Boolean(
        (eventType !== EventType.MATCH && eventName && location) ||
          (eventType === EventType.MATCH && opponentName && location),
      ),
    );
  }, [eventName, location, opponentName, eventType]);

  useEffect(() => {
    setEventName(title);
    setEventType(type);
    setStartTimestamp(DateTime.fromMillis(startTime));
    setEndTimestamp(DateTime.fromMillis(endTime));
    setLocation(locationName);
    setTrainingDescription(description);
  }, [props.eventId]);

  useEffect(() => {
    if (mutation.isSuccess) {
      queryClient.invalidateQueries(["events", props.teamId]);
      queryClient.invalidateQueries(["event", props.eventId]);
      onClose(true);
    }

    if (mutation.isError) {
      toast.error(mutation.error.data.detail);
      onClose(false);
    }
  }, [mutation.isSuccess, mutation.isError, props.teamId, props.eventId]);

  const checkReoccuranceDaysFilled = (): boolean => {
    if (!reoccurance.repeatDays.length) {
      return false;
    }
    return reoccurance.repeatDays.every((day) => {
      return Boolean(
        day.startTime &&
          day.endTime &&
          day.endTime >= day.startTime.plus({ minutes: 15 }),
      );
    });
  };

  const isInputTimeCorrect: boolean =
    !startTimestampError &&
    !endTimestampError &&
    Boolean(startTimestamp && endTimestamp);

  const isReoccuranceSelected: boolean =
    eventType === EventType.TRAINING
      ? (reoccurance.isRepeating && checkReoccuranceDaysFilled()) ||
        (!reoccurance.isRepeating && isInputTimeCorrect)
      : isInputTimeCorrect;

  const isSubmitButtonDisabled: boolean = Boolean(
    !submitEnabled ||
      locationError ||
      mutation.isLoading ||
      !isReoccuranceSelected,
  );

  const isSubmitButtonDisabledForEvent: boolean =
    eventType === EventType.MATCH
      ? Boolean(isSubmitButtonDisabled || opponentTeamNameError)
      : Boolean(isSubmitButtonDisabled || eventNameError);

  const onSubmit = () => {
    const startTimeCombined: DateTime = getCombinedDateTime(
      selectedDate,
      startTimestamp,
    );
    const endTimeCombined: DateTime = getCombinedDateTime(
      selectedDate,
      endTimestamp,
    );

    let eventTitle: string = eventName;
    if (eventType === EventType.MATCH) {
      eventTitle = `MATCH VS. ${opponentName}`;
    }

    const data: EventUpdateInput = {
      title: eventTitle,
      type: eventType,
      startTime: startTimeCombined.toMillis(),
      endTime: endTimeCombined.toMillis(),
      locationName: location,
      description: trainingDescription || "",
    };

    if (data.type === EventType.MATCH) {
      data.matchDetails = {
        teamScore: myTeamScore,
        opponentScore: opponentTeamScore,
        opponentTeamName: opponentName,
        startTime: startTimeCombined.toMillis(),
      };
    }

    if (reoccurance.isRepeating) {
      const prevReoc = parseIcsEvents(props.reoccurrence);
      if (isReoccurenceDiffer(prevReoc, reoccurance)) {
        data.reoccurrence = createIcsReoccuringEvent(reoccurance, eventTitle);
      }
    }

    mutation.mutate(data);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      scroll="body"
      maxWidth={"sm"}
      keepMounted={open}
    >
      <Paper style={{ background: theme.background.actions }}>
        <DialogTitle id="form-dialog-title">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="column">
              <Typography variant="h5">
                {props.autoEventId ? (
                  <Trans id="edit_auto_event_label">
                    EDIT AUTO DETECTED EVENT
                  </Trans>
                ) : (
                  <Trans id="edit_event_label">EDIT EVENT</Trans>
                )}
              </Typography>
            </Stack>
            <IconButton onClick={() => onClose(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ height: "fit-content" }}>
          <DialogContentText>
            <Stack direction="column" sx={{ width: "100%" }} spacing={2}>
              <Typography variant="body2">
                <Trans id="event_type_label">Event type</Trans>:
              </Typography>
              <Stack direction="row">
                <ToggleButtonGroupCustom
                  buttons={eventTypes}
                  value={eventType}
                  buttonAction={(name) => setEventType(name as EventType)}
                />
              </Stack>
              {eventType === EventType.MATCH ? (
                <TextField
                  variant="filled"
                  label={<Trans id="opponent_name_label">Opponent Name</Trans>}
                  sx={{ width: "100%" }}
                  onChange={(e) => setOpponentName(e.target.value)}
                  value={opponentName}
                  helperText={opponentTeamNameError}
                  error={Boolean(opponentTeamNameError)}
                />
              ) : (
                <TextField
                  variant="filled"
                  label={<Trans id="event_title_label">Event Title</Trans>}
                  sx={{ width: "100%" }}
                  onChange={(e) => setEventName(e.target.value)}
                  value={eventName}
                  helperText={eventNameError}
                  error={Boolean(eventNameError)}
                />
              )}
              <TextField
                variant="filled"
                label={<Trans id="event_location_label">Location</Trans>}
                sx={{ width: "100%" }}
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                helperText={locationError}
                error={Boolean(locationError)}
              />

              {eventType === EventType.TRAINING && (
                <EventRepeating
                  isUpdatingSeries={isUpdatingSeries}
                  isUpdatingEvent={true}
                  selectedRepeatingEvent={Boolean(seriesId ?? "")}
                  props={reoccurance}
                  handleChange={(recurr) =>
                    setReoccurance((prev) => ({ ...prev, ...recurr }))
                  }
                />
              )}

              {!(
                reoccurance.isRepeating && eventType === EventType.TRAINING
              ) && (
                <Stack spacing={2}>
                  <MobileDatePicker
                    value={selectedDate}
                    onChange={(v) => setSelectedDate(v || DateTime.now())}
                    minDate={DateTime.now().minus({ days: 30 })}
                    format={"dd MMMM yyyy"}
                    slots={{
                      textField: (params) => (
                        <TextField variant="filled" {...params} />
                      ),
                    }}
                    slotProps={{
                      toolbar: {
                        className: "leaderBoard-datepicker",
                      },
                      layout: {
                        className: "leaderBoard-datepicker__layout",
                      },
                      textField: {
                        style: {
                          width: "100%",
                        },
                      },
                    }}
                    label={<Trans id="event_time_date_label">Date</Trans>}
                  />
                  <Stack
                    direction={isSmallScreen ? "column" : "row"}
                    spacing={1}
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    <MobileTimePicker
                      value={startTimestamp}
                      onChange={(v) => {
                        setStartTimestamp(v || DateTime.now());
                        setEndTimestamp(
                          (v || DateTime.now()).plus({ minutes: 15 }),
                        );
                      }}
                      maxTime={selectedDate.endOf("day").minus({ minutes: 15 })}
                      ampm={false}
                      label={
                        <Trans id="event_time_begins_at_label">Begins At</Trans>
                      }
                      format={"HH:mm"}
                      slots={{
                        textField: (params) => (
                          <TextField variant="filled" {...params} />
                        ),
                      }}
                      slotProps={{
                        toolbar: {
                          className: "leaderBoard-datepicker",
                        },
                        textField: {
                          style: {
                            width: "100%",
                          },
                        },
                      }}
                      onError={(e) => setstartTimestampError(Boolean(e))}
                    />
                    <MobileTimePicker
                      value={endTimestamp}
                      onChange={(v) => setEndTimestamp(v || DateTime.now())}
                      minTime={(
                        startTimestamp || DateTime.now().startOf("day")
                      )?.plus({ minutes: 15 })}
                      maxTime={selectedDate.endOf("day")}
                      ampm={false}
                      label={
                        <Trans id="event_time_ends_at_label">Ends At</Trans>
                      }
                      format={"HH:mm"}
                      slots={{
                        textField: (params) => (
                          <TextField variant="filled" {...params} />
                        ),
                      }}
                      slotProps={{
                        toolbar: {
                          className: "leaderBoard-datepicker",
                        },
                        textField: {
                          style: {
                            width: "100%",
                          },
                        },
                      }}
                      onError={(e) => setendTimestampError(Boolean(e))}
                    />
                  </Stack>
                </Stack>
              )}

              <TextField
                id="filled-multiline-flexible"
                label={
                  <Trans id="event_description_label">Event description</Trans>
                }
                minRows={5}
                inputProps={{ maxLength: 750 }}
                multiline
                sx={{ width: "100%" }}
                variant="filled"
                value={trainingDescription}
                onChange={(e) => setTrainingDescription(e.target.value)}
              />

              <Stack key="opponent" hidden={eventType !== EventType.MATCH}>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={1}
                >
                  <Box width={"100%"} id="myScore">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="body1" color="white">
                        {currentTeam.teamName}
                      </Typography>
                      <Stack direction="row">
                        <IconButton
                          sx={{ color: "gray" }}
                          disabled={myTeamScore <= 0}
                          onClick={() =>
                            myTeamScore > 0 && setMyTeamScore(myTeamScore - 1)
                          }
                        >
                          <RemoveCircleIcon style={{ fontSize: "24px" }} />
                        </IconButton>

                        <Typography variant="body1" className="score-label">
                          {myTeamScore}
                        </Typography>

                        <IconButton
                          sx={{ color: theme.actions.primary }}
                          onClick={() => setMyTeamScore(myTeamScore + 1)}
                        >
                          <AddCircleIcon style={{ fontSize: "24px" }} />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Box>

                  <Divider flexItem />

                  <Box width={"100%"} id="opponentScore">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="body1" color="white">
                        {opponentName || (
                          <Trans id="event_match_opponent_placeholder">
                            Opponent
                          </Trans>
                        )}
                      </Typography>
                      <Stack direction="row">
                        <IconButton
                          sx={{ color: "gray" }}
                          disabled={opponentTeamScore <= 0}
                          onClick={() =>
                            opponentTeamScore > 0 &&
                            setOpponentTeamScore(opponentTeamScore - 1)
                          }
                        >
                          <RemoveCircleIcon style={{ fontSize: "24px" }} />
                        </IconButton>

                        <Typography variant="body1" className="score-label">
                          {opponentTeamScore}
                        </Typography>

                        <IconButton
                          sx={{ color: theme.actions.primary }}
                          onClick={() =>
                            setOpponentTeamScore(opponentTeamScore + 1)
                          }
                        >
                          <AddCircleIcon style={{ fontSize: "24px" }} />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Stack
            padding="16px 24px"
            direction="row"
            spacing={2}
            justifyContent="flex-end"
          >
            <Button
              variant="outlined"
              onClick={() => onClose(false)}
              className="button-action__common"
            >
              <Trans id="cancel_cta">CANCEL</Trans>
            </Button>
            <Button
              variant="contained"
              disabled={isSubmitButtonDisabledForEvent}
              className="button-action__common"
              onClick={onSubmit}
            >
              {mutation.isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <Trans id="save_cta">Save</Trans>
              )}
            </Button>
          </Stack>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Button } from "@mui/material";

import { TeamMemberRole } from "store/team-members/team-members.model";
import { useAuthQuery } from "providers/auth";
import { getUserProfile } from "store/user/user.query";
import { TeamMembersReadOnlyView } from "../containers/teams/team-members-readonly";
import { NavigationContext } from "providers/navigation.provider";
import { Trans } from "@lingui/macro";

export const Dashboard = () => {
  const { currentTeam } = useContext(NavigationContext);
  const userQuery = useAuthQuery("userProfile", getUserProfile);

  const { role, teamId, banner } = currentTeam;
  const history = useHistory();

  const goToTeamMembers = () => {
    history.push(`/teams/${teamId}/settings?tabSelected=MEMBERS`);
  };

  return (
    <Container sx={{ overflow: "hidden" }}>
      <Grid item xs={12} position="relative">
        <Box
          sx={{
            zIndex: 0,
            width: "100%",
            position: "absolute",
            opacity: 0.5,
            maskImage:
              "linear-gradient(to bottom, black 50%,  rgba(0,0,0,0.6) 60%,  rgba(0,0,0,0.4) 70%, transparent 80%)",
          }}
        >
          <img src={banner} style={{ width: "100%" }} alt="" />
        </Box>
        <Grid container position="relative" direction="row" zIndex={2}>
          <Grid item style={{ marginTop: 40 }}>
            <Typography
              variant="h3"
            >
              <Trans>
                Hello {userQuery.data?.firstName?.toUpperCase()}! Welcome to{" "}
                ADIDAS Team
                FX
              </Trans>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        zIndex={3}
        position="relative"
        item
        xs={12}
        style={{ marginTop: "15rem" }}
      >
        <Grid container direction="column" alignItems="center">
          {role !== TeamMemberRole.PLAYER && (
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={goToTeamMembers}
              >
                Invite Or Manage Members
              </Button>
            </Grid>
          )}
          {teamId && <TeamMembersReadOnlyView teamId={teamId} />}
        </Grid>
      </Grid>
    </Container>
  );
};

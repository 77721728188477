import * as React from "react";
import { Avatar, Badge, Button, Grid, Typography } from "@mui/material";
import {
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import {
  ITeamMemberInfo,
  PlayerPositionFullTitle,
} from "store/team-members/team-members.model";
import { PlayerAvatar } from "components/avatar.component";
import { LeaderBoardMetric } from "store/team-leaderboard/team-leaderboard.model";
import theme from "theme";
import TooltipCustom from "components/tooltip.component";

export const POSITION_TO_COLOR: Record<number, string> = {
  1: "#E6C247",
  2: "#CFCFCF",
  3: "#C09A6F",
};

export const getPlayerName = ({ value }: GridValueFormatterParams) => {
  const info = value as unknown as ITeamMemberInfo;
  if (!info) return "";
  return info.memberInfo?.firstName
    ? `${info.memberInfo?.firstName} ${info.memberInfo?.lastName}`
    : info.memberInfo?.tagId;
};

export const getPlayerId = ({ value }: { value: any }) => {
  return (value as unknown as ITeamMemberInfo).playerId;
};

export const renderPlayer =
  (onPlayerClick?: (playerId: string) => void) =>
  (params: GridRenderCellParams) => {
    const info = params.value as unknown as ITeamMemberInfo;
    if (!info) return "Player";
    const name = getPlayerName(params as GridValueFormatterParams);
    const position = info.position;

    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <PlayerAvatar teamId={info.teamId} playerId={info.playerId} showPosition />
        </Grid>
        <Grid item sx={{ textOverflow: "ellipsis", maxWidth: "240px" }}>
          {onPlayerClick ? (
            <Button
              sx={{ width: "100%", padding: 0 }}
              onClick={() => onPlayerClick?.(info.playerId)}
            >
              <Typography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "100%",
                }}
                variant="subtitle2"
              >
                {name}
              </Typography>
            </Button>
          ) : (
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
              }}
              variant="subtitle2"
            >
              {name}
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  };

export const renderPosition = (params: GridRenderCellParams) => {
  const index = Number(
    params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
  );
  const color = POSITION_TO_COLOR[index];

  const textStyle = color ? { color } : {};

  return (
    <Grid container justifyContent="space-around">
      <Grid item>
        <Typography variant="subtitle1" style={textStyle}>
          {index}.
        </Typography>
      </Grid>
    </Grid>
  );
};

export const getLeaderBoardAsList = (
  leaderboard?: LeaderBoardMetric,
  members?: ITeamMemberInfo[],
  addNoData?: boolean,
) => {
  if (!leaderboard || !members) {
    return [];
  }

  type LeaderBoardGrouped = Record<
    string,
    Record<keyof LeaderBoardMetric, number>
  >;
  const pickMetricByPlayerId =
    (group: LeaderBoardGrouped) =>
    (metric: LeaderBoardMetric, key: keyof LeaderBoardMetric) => {
      for (const { playerId, value } of metric[key]) {
        group[playerId] = {
          ...group[playerId],
          [key]: value,
        };
      }

      return group;
    };

  const infoByPlayerId = members.reduce(
    (acc, current) => ({
      ...acc,
      [current.playerId]: current,
    }),
    {} as Record<string, ITeamMemberInfo>,
  );

  let groupedLeaderBoardByPlayer = pickMetricByPlayerId({})(
    leaderboard,
    "countKicks",
  );
  groupedLeaderBoardByPlayer = pickMetricByPlayerId(groupedLeaderBoardByPlayer)(
    leaderboard,
    "countXplos",
  );
  groupedLeaderBoardByPlayer = pickMetricByPlayerId(groupedLeaderBoardByPlayer)(
    leaderboard,
    "countSprint",
  );
  groupedLeaderBoardByPlayer = pickMetricByPlayerId(groupedLeaderBoardByPlayer)(
    leaderboard,
    "topBallSpeed",
  );
  groupedLeaderBoardByPlayer = pickMetricByPlayerId(groupedLeaderBoardByPlayer)(
    leaderboard,
    "topRunningSpeed",
  );
  groupedLeaderBoardByPlayer = pickMetricByPlayerId(groupedLeaderBoardByPlayer)(
    leaderboard,
    "totalDistance",
  );
  groupedLeaderBoardByPlayer = pickMetricByPlayerId(groupedLeaderBoardByPlayer)(
    leaderboard,
    "totalDistanceXplos",
  );
  groupedLeaderBoardByPlayer = pickMetricByPlayerId(groupedLeaderBoardByPlayer)(
    leaderboard,
    "totalDistanceSprint",
  );

  const result = Object.keys(groupedLeaderBoardByPlayer).map((playerId) => ({
    id: playerId,
    player: infoByPlayerId[playerId],
    playerId,
    empty: false,
    ...groupedLeaderBoardByPlayer[playerId],
  }));

  if (addNoData) {
    members.forEach((member) => {
      if (!result.find((r) => r.playerId === member.playerId)) {
        result.push({
          id: member.playerId,
          player: member,
          playerId: member.playerId,
          empty: true,
          countKicks: 0,
          countXplos: 0,
          countSprint: 0,
          topBallSpeed: 0,
          topRunningSpeed: 0,
          totalDistance: 0,
          totalDistanceXplos: 0,
          totalDistanceSprint: 0,
        });
      }
    });
  }

  return result;
};

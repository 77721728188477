import { SvgIcon, SvgIconProps } from "@mui/material";
import { EventType } from "store/events/events.model";
import { HighlightType } from "store/video/video.model";
import { ReactComponent as MatchIcon } from "images/icons/event_match.svg";
import { ReactComponent as TrainingIcon } from "images/icons/event_training.svg";
import { ReactComponent as OtherIcon } from "images/icons/event_other.svg";

import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import MetricsKickCount from "components/icons/MetricsKickCount";
import MetricsExplosivenessCount from "components/icons/MetricsExplosivenessCount";

export const IconByEventType = ({
  type,
  size,
  ...props
}: { type: EventType; size: number } & SvgIconProps) => {
  switch (type) {
    case EventType.MATCH:
      return (
        <SvgIcon viewBox="0 0 21 21" {...props} style={{ fontSize: size }}>
          <MatchIcon />
        </SvgIcon>
      );
    case EventType.TRAINING:
      return (
        <SvgIcon viewBox="0 0 21 21" {...props} style={{ fontSize: size }}>
          <TrainingIcon />
        </SvgIcon>
      );
    case EventType.OTHER:
      return (
        <SvgIcon viewBox="0 0 21 21" {...props} style={{ fontSize: size }}>
          <OtherIcon />
        </SvgIcon>
      );
    default:
      return (
        <SvgIcon viewBox="0 0 21 21" {...props} style={{ fontSize: size }}>
          <OtherIcon />
        </SvgIcon>
      );
  }
};

export const IconByHighlightType = ({ type, ...props }) => {
  switch (type) {
    case HighlightType.kick:
      return <MetricsKickCount {...props} />;
    case HighlightType.run:
      return <DirectionsRunIcon {...props} />;
    default:
      return <MetricsExplosivenessCount {...props} />;
  }
};

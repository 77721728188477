import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { PlayerTwoStatsLines } from "components/charts/player-two-stats-line";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getTeamEvents,
  getTeamEventsWithParticipation,
} from "store/events/events.query";
import { useAuthQuery } from "providers/auth";
import theme from "theme";
import { getPlayersToCompare } from "utils/team";
import { IGetTeamMembersData } from "store/team-members/team-members.model";
import {
  TeamEventUserSpecific,
  TeamEventUserSpecificWithParticipants,
} from "store/events/events.model";

export type FeedbackStatsProps = {
  to: DateTime;
  from: DateTime;
  teamId: string;
  teamMembers?: IGetTeamMembersData;
};
const EVENT_TYPES = ["TRAINING", "MATCH", "OTHER"];
export const FeedbackStats = (props: FeedbackStatsProps) => {
  const { teamId, to, from, teamMembers } = props;
  const history = useHistory();
  const [eventTypes, setEventTypes] = React.useState<string[]>(EVENT_TYPES);
  const ALL_TEAM_MARKER = "ALL_TEAM";
  const [memberId, setMemberId] = React.useState<string>(ALL_TEAM_MARKER);
  const eventsQuery = useAuthQuery(
    ["events", "withParticipation", teamId, from, to],
    getTeamEventsWithParticipation({
      teamId,
      from: from.toMillis(),
      to: to.toMillis(),
    }),
    { enabled: Boolean(teamId && to && from) },
  );
  const onFilterClick = (type: string) => {
    if (eventTypes.includes(type)) {
      setEventTypes(eventTypes.filter((t) => t !== type));
    } else {
      setEventTypes([...eventTypes, type]);
    }
  };
  const [data, setData] = useState<TeamEventUserSpecific[]>([]);

  useEffect(() => {
    const filteredTypes =
      eventsQuery.data?.data.filter((e) => eventTypes.includes(e.type)) || [];

    const selectedPlayersOnly = (
      events: TeamEventUserSpecificWithParticipants[],
    ) => {
      return events.map((event) => {
        const personalFeedback =
          event.participants.find((p) => p.playerId === memberId)?.feedback ||
          null;

        return {
          ...event,
          satisfactionAverage: personalFeedback
            ? personalFeedback.satisfactionRating
            : 0,
          intensityAverage: personalFeedback
            ? personalFeedback.intensityRating
            : 0,
        };
      });
    };

    const filteredData =
      memberId && memberId !== ALL_TEAM_MARKER
        ? selectedPlayersOnly(filteredTypes)
        : filteredTypes;

    const skipMissing = filteredData.filter(
      (event) => event.satisfactionAverage && event.intensityAverage,
    );

    setData(skipMissing);
  }, [eventTypes, eventsQuery.data, memberId]);

  const getCurrentPlayerName = (playerId: string) => {
    const player = teamMembers?.teamMembers.find(
      (member) => member.playerId === playerId,
    );
    if (player) {
      return player.memberInfo?.firstName + " " + player.memberInfo?.lastName;
    }
    return "Player";
  };

  return (
    <>
      <Grid container direction="column" alignItems="flex-start" spacing={5}>
        <Grid item>
          <Grid
            container
            alignItems="center"
            justifyContent="start"
            spacing={1}
          >
            <Grid item>
              <FormControl variant="outlined" sx={{ ml: 4, minWidth: "400px" }}>
                <InputLabel
                  sx={{
                    fontSize: "16px",
                    padding: "0 5px",
                    background: theme.background.primary,
                  }}
                  id="selectPlayer-label"
                >
                  Select player
                </InputLabel>
                <Select
                  labelId="selectPlayer-label"
                  value={memberId}
                  onChange={(_event: any, option: any) => {
                    const v = option.props.value;
                    setMemberId(v);
                  }}
                >
                  <MenuItem
                    key={"team-select-menu-item-all"}
                    value={"ALL_TEAM_MARKER"}
                    selected={"ALL_TEAM_MARKER" === memberId}
                  >
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Grid item>All team</Grid>
                    </Grid>
                  </MenuItem>
                  {getPlayersToCompare(
                    memberId,
                    teamMembers?.teamMembers || [],
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item marginLeft={10}>
              <Typography variant="subtitle1">Event types: </Typography>
            </Grid>
            {EVENT_TYPES.map((type) => (
              <Grid item key={type}>
                <Button
                  onClick={() => onFilterClick(type)}
                  key={type}
                  style={{
                    borderRadius: 20,
                    padding: 10,
                    border: eventTypes.includes(type)
                      ? `1px solid ${theme.actions.primary}`
                      : `1px solid ${theme.borders.secondary}`,
                    margin: 3,
                  }}
                >
                  <Typography
                    key={type}
                    variant="subtitle2"
                    color={eventTypes.includes(type) ? "primary" : "grey"}
                  >
                    {type}
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item height={300} width={"100%"}>
          {eventsQuery.isLoading ? (
            <LinearProgress />
          ) : (
            <PlayerTwoStatsLines
              title={`${
                memberId ? getCurrentPlayerName(memberId) : "Average"
              } Satisfaction vs ${
                memberId ? getCurrentPlayerName(memberId) : "Average"
              } Intensity`}
              useSingleYAxis={true}
              onElementClick={(_dsIndex, index) => {
                const event = eventsQuery.data?.data[index];
                if (event) {
                  history.push(
                    `/teams/${event.teamId}/timeline?eventId=${event.eventId}`,
                  );
                }
              }}
              data={{
                statOne: {
                  statName: "Satisfaction",
                  statsMeasure: "POINTS",
                  items: data.map((v) => v.satisfactionAverage),
                },
                statTwo: {
                  statName: "Intensity",
                  statsMeasure: "POINTS",
                  items: data.map((v) => v.intensityAverage),
                },
              }}
              labels={data.map(
                (e) =>
                  DateTime.fromMillis(e.startTime).toFormat(
                    "HH:mm dd/MM/yyyy",
                  ) +
                  "\n" +
                  (e.title || e.matchDetails?.opponentTeamName),
              )}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

import * as React from "react";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import { Button, Grid, Stack } from "@mui/material";
import { EventOrDateTimeSelector } from "containers/layout/date-time.container";
import { useAuthQuery } from "providers/auth";
import { getTeamMembers } from "store/team-members/team-members.query";
import { NavigationContext } from "providers/navigation.provider";
import PlayerStats from "./tabs/player-stats";
import Comparison from "./tabs/comparison";
import { useAnalytics } from "use-analytics";
import {
  PlayerInLeaderboardTable,
  PlayerSelectInput,
} from "containers/layout/player-select.container";
import {
  IntervalMetric
} from "store/member-stats/member-stats.model";

export const MPS_TO_KMPH = 3.6;
export const M_IN_KM = 1000;
export const DAY_IN_MS = 86400 * 1000;

export default function Analytics() {
  const analytics = useAnalytics();
  const { currentTeam } = React.useContext(NavigationContext);
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const { teamId } = currentTeam;
  const minDate = React.useMemo(() => DateTime.now().minus({ weeks: 12 }), []);
  const [from, setFrom] = React.useState<DateTime>(
    query.get("from")
      ? DateTime.fromMillis(Number(query.get("from")))
      : DateTime.now().minus({ week: 1 }),
  );
  const [to, setTo] = React.useState<DateTime>(
    query.get("to")
      ? DateTime.fromMillis(Number(query.get("to")))
      : DateTime.now(),
  );
  const [memberId, setMemberId] = React.useState<string>(
    query.get("playerId") || "",
  );
  const [isSettingsActive, setIsSettingsActive] = React.useState<boolean>(false);
  const [exportData, setExportData] = React.useState<IntervalMetric[][]>([]);

  const [playerIds, setPlayerIds] = React.useState<string[]>([]);
  const [disabledPlayersId, setDisabledPlayerIds] = React.useState<string[]>(
    [],
  );
  const [playersChoosed, setPlayersChoosed] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (from.valueOf() > to.valueOf()) {
      const min = to;
      const max = from;

      setFrom(min);
      setTo(max);
    }

    const interval = to.valueOf() - from.valueOf();
    if (interval > DAY_IN_MS) {
      setQueryInterval("auto");
    } else if (interval > DAY_IN_MS / 6) {
      setQueryInterval("5m");
    } else {
      setQueryInterval("1m");
    }
  }, [from, to]);

  const onExportCSV = () => {
    const csv = exportData
      .map((data, index) => {
        return data.map((d) => {
          return [
            playerIds[index],
            d.intervalStart,
            d.interval,
            d.count,
            d.kicks.avgBallSpeed,
            d.kicks.bottomBallSpeed,
            d.kicks.count,
            d.kicks.topBallSpeed,
            d.kicks.zones.find((z) => z.zone === "COLD")?.count,
            d.kicks.zones.find((z) => z.zone === "MEDIUM")?.count,
            d.kicks.zones.find((z) => z.zone === "HOT")?.count,
            d.kicks.zones.find((z) => z.zone === "FIRE")?.count,
            d.playerMotion.avgRunningSpeed,
            d.playerMotion.avgSpeedZones.find((z) => z.zone === "STAND")
              ?.zoneDist,
            d.playerMotion.avgSpeedZones.find((z) => z.zone === "WALK")
              ?.zoneDist,
            d.playerMotion.avgSpeedZones.find((z) => z.zone === "JOG")
              ?.zoneDist,
            d.playerMotion.avgSpeedZones.find((z) => z.zone === "RUN")
              ?.zoneDist,
            d.playerMotion.avgSpeedZones.find((z) => z.zone === "SPRINT")
              ?.zoneDist,
            d.playerMotion.avgSpeedZones.find(
              (z) => z.zone === "HIGH_SPEED_RUN",
            )?.zoneDist,
            d.playerMotion.count,
            d.playerMotion.peakSpeedZones.find((z) => z.zone === "STAND")
              ?.zoneDist,
            d.playerMotion.peakSpeedZones.find((z) => z.zone === "WALK")
              ?.zoneDist,
            d.playerMotion.peakSpeedZones.find((z) => z.zone === "JOG")
              ?.zoneDist,
            d.playerMotion.peakSpeedZones.find((z) => z.zone === "RUN")
              ?.zoneDist,
            d.playerMotion.peakSpeedZones.find((z) => z.zone === "SPRINT")
              ?.zoneDist,
            d.playerMotion.peakSpeedZones.find(
              (z) => z.zone === "HIGH_SPEED_RUN",
            )?.zoneDist,
            d.playerMotion.topRunningSpeed,
            d.playerMotion.totalDistance,
            d.explosiveness.count,
            d.explosiveness.totalDistance,
            d.sprint.count,
            d.sprint.totalDistance,
          ].join(",");
        });
      })
      .flat();

    // Add header for columns
    csv.unshift(
      [
        "Player ID",
        "Interval Start",
        "Interval",
        "Count",
        "Avg Ball Speed",
        "Bottom Ball Speed",
        "Kicks Count",
        "Top Ball Speed",
        "Kicks Zones - Cold - Count",
        "Kicks Zones - Medium - Count",
        "Kicks Zones - Hot - Count",
        "Kicks Zones - Fire - Count",
        "Avg Running Speed",
        "Avg Speed Zones - Stand - Distance",
        "Avg Speed Zones - Walk - Distance",
        "Avg Speed Zones - Jog - Distance",
        "Avg Speed Zones - Run - Distance",
        "Avg Speed Zones - Sprint - Distance",
        "Avg Speed Zones - High Speed Run - Distance",
        "Player Motion Count",
        "Peak Speed Zones - Stand - Distance",
        "Peak Speed Zones - Walk - Distance",
        "Peak Speed Zones - Jog - Distance",
        "Peak Speed Zones - Run - Distance",
        "Peak Speed Zones - Sprint - Distance",
        "Peak Speed Zones - High Speed Run - Distance",
        "Top Running Speed",
        "Total Distance",
        "Explosiveness Count",
        "Explosiveness Total Distance",
        "Sprint Count",
        "Sprint Total Distance",
      ].join(","),
    );

    const csvContent = `data:text/csv;charset=utf-8,${csv.join("\n")}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `${from.toFormat("dd.MM.yyyy")}:${to.toFormat(
        "dd.MM.yyyy",
      )}@${playerIds.join("&")}.csv`,
    );
    document.body.appendChild(link);
    link.click();
  };

  const [queryInterval, setQueryInterval] = React.useState<string>("auto");

  const teamMembersQuery = useAuthQuery(
    ["teamMembers", teamId],
    getTeamMembers(teamId),
    { enabled: Boolean(teamId) },
  );

  return (
    <Stack sx={{ padding: "40px" }} direction="column" spacing={5}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item>
              <EventOrDateTimeSelector
                from={from}
                to={to}
                setFrom={setFrom}
                setTo={setTo}
                minDate={minDate}
                setPopupOpened={(v) => setIsSettingsActive(v)}
              />
            </Grid>
            <Grid item hidden={!playersChoosed}>
              <PlayerSelectInput
                playerIds={playerIds}
                disabled={disabledPlayersId}
                onClick={() => setPlayersChoosed(false)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item hidden={playersChoosed}>
          <Button
            color="primary"
            variant="contained"
            disabled={!playerIds.length}
            onClick={() => setPlayersChoosed(true)}
          >
            {playerIds.length > 1 ? "Compare Players" : "View Player Stats"}
          </Button>
        </Grid>

        <Grid item hidden={!playersChoosed}>
          <Button
            variant="contained"
            onClick={onExportCSV}
            color="secondary"
            disabled={!exportData.length}
          >
            EXPORT AS CSV
          </Button>
        </Grid>
      </Grid>
      <Grid item style={{ opacity: isSettingsActive ? 0.5 : 1, transition: "opacity 0.5 s" }}>
        {!playersChoosed && (
          <PlayerInLeaderboardTable
            from={from.toMillis()}
            to={to.toMillis()}
            teamId={teamId}
            playerIds={playerIds}
            setPlayerIds={setPlayerIds}
          />
        )}
        {playersChoosed &&
          (playerIds.length > 1 ? (
            <Comparison
              teamId={teamId}
              from={from}
              to={to}
              queryInterval={queryInterval}
              teamMembers={teamMembersQuery.data}
              playerIds={playerIds}
              setDisabledPlayerIds={setDisabledPlayerIds}
              onExport={setExportData}
            />
          ) : (
            <PlayerStats
              memberId={playerIds[0]}
              teamId={teamId}
              from={from.toMillis()}
              to={to.toMillis()}
              setDisabledPlayerIds={setDisabledPlayerIds}
              onExport={setExportData}
              queryInterval="auto"
            />
          ))}
      </Grid>
    </Stack>
  );
}

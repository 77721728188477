import { getHeaders, isError, send } from "services/commons.api";
import {
  TeamEvent,
  TeamEventUserSpecific,
  TeamEventUserSpecificWithMembers,
  TeamEventUserSpecificWithParticipants,
  UserEvent,
} from "./events.model";
import api from "../../config";

export type EventsParams = {
  teamId: string;
  from: number;
  to: number;
};

export const getTeamEvents =
  (params: EventsParams) => (token: string) => (_context) =>
    send<{ data: TeamEventUserSpecific[] }>({
      apiEndpoint: api.API.EVENTS.GET_LIST,
      urlParams: {
        ...params,
        includeParticipation: false,
      },
      headers: getHeaders(token),
    }).then((v) => {
      if (isError(v)) {
        throw v;
      }

      return v.data;
    });

export const getTeamEventsWithParticipation =
    (params: EventsParams) => (token: string) => (_context) =>
      send<{ data: TeamEventUserSpecificWithParticipants[] }>({
        apiEndpoint: api.API.EVENTS.GET_LIST,
        urlParams: {
          ...params,
          includeParticipation: true,
        },
        headers: getHeaders(token),
      }).then((v) => {
        if (isError(v)) {
          throw v;
        }
  
        return v.data;
      });

export const getTeamEventById =
  (eventId: string) => (token: string) => (_context) =>
    send<TeamEventUserSpecificWithMembers>({
      apiEndpoint: api.API.EVENTS.GET,
      urlParams: {
        eventId,
      },
      headers: getHeaders(token),
    }).then((v) => {
      if (isError(v)) {
        throw v;
      }

      return v.data;
    });

export type EventsInput = Omit<
  TeamEvent,
  "eventId" | "ownerId" | "invitesCount" | "invitesAcceptedCount"
>;
export type UserEventInput = Partial<
  Pick<
    UserEvent,
    "feedback" | "inviteStatus" | "participationStatus" | "position"
  >
>;
export type UserEventFeedbackReplyInput = {
  comment?: string;
  reaction?: string;
};
export type EventUpdateInput = Partial<Omit<TeamEvent, "">>;

export const updateEvent =
  (eventId: string) => (token: string) => (data: EventUpdateInput) =>
    send<{ data: TeamEvent[] }>({
      apiEndpoint: api.API.EVENTS.UPDATE,
      urlParams: { eventId },
      body: data,
      headers: getHeaders(token),
    }).then((v) => {
      if (isError(v)) {
        throw v;
      }

      return v.data;
    });

export const removeEvent =
    (eventId: string) => (token: string) => () =>
      send<{ data: TeamEvent[] }>({
        apiEndpoint: api.API.EVENTS.DELETE,
        urlParams: { eventId },
        headers: getHeaders(token),
      }).then((v) => {
        if (isError(v)) {
          throw v;
        }
  
        return v.data;
      });

export const updateUserEvent =
  (eventId: string, playerId: string) =>
    (token: string) =>
      (data: UserEventInput) =>
        send<{ data: TeamEvent[] }>({
          apiEndpoint: api.API.EVENTS.UPDATE_USER_EVENT,
          urlParams: { eventId, playerId },
          body: data,
          headers: getHeaders(token),
        }).then((v) => {
          if (isError(v)) {
            throw v;
          }

          return v.data;
        });

export const putUserEventFeedbackReply =
  (eventId: string, playerId: string) =>
    (token: string) =>
      (data: UserEventFeedbackReplyInput) =>
        send<{ data: TeamEvent }>({
          apiEndpoint: api.API.EVENTS.FEEDBACK_REPLY,
          urlParams: { eventId, playerId },
          body: data,
          headers: getHeaders(token),
        }).then((v) => {
          if (isError(v)) {
            throw v;
          }

          return v.data;
        });

export const updateUserPositionInEvent =
  (eventId: string) =>
    (token: string) =>
      (input: { playerId: string; data: UserEventInput }) =>
        send<{ data: TeamEvent[] }>({
          apiEndpoint: api.API.EVENTS.UPDATE_USER_EVENT,
          urlParams: { eventId, playerId: input.playerId },
          body: input.data,
          headers: getHeaders(token),
        }).then((v) => {
          if (isError(v)) {
            throw v;
          }

          return v.data;
        });

export const createEvent = (token: string) => (data: EventsInput) =>
  send<{ data: TeamEvent[] }>({
    apiEndpoint: api.API.EVENTS.CREATE,
    body: data,
    headers: getHeaders(token),
  }).then((v) => {
    if (isError(v)) {
      throw v;
    }

    return v.data;
  });

import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import * as React from "react";
import { queryClient, useAuthMutation, useAuthQuery } from "providers/auth";
import { getTeamMembers } from "store/team-members/team-members.query";
import ErrorIcon from "@mui/icons-material/Error";
import { PlayerAvatar } from "components/avatar.component";
import { muiTheme } from "../../theme";
import { toast } from "react-toastify";
import {
  ITeamMemberInfo,
  PlayerPosition,
  TeamMemberRole,
} from "store/team-members/team-members.model";
import { NavigationContext } from "providers/navigation.provider";
import {
  getTeamEventById,
  updateUserEvent,
  updateUserPositionInEvent,
} from "store/events/events.query";
import { PositionSelectModal } from "containers/position/position-select.modal";
import { PositionButton } from "containers/position/position.button";
import {
  InviteStatus,
  ParticipationStatus,
  TeamEventUserSpecific,
} from "store/events/events.model";
import TooltipCustom from "components/tooltip.component";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SendIcon from "@mui/icons-material/Send";
import CallMissedIcon from "@mui/icons-material/CallMissed";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import SyncIcon from "@mui/icons-material/Sync";
import { Skeleton, Button } from "@mui/material";
import { getName } from "utils/team";
import { Trans } from "@lingui/macro";
import { useAnalytics } from "use-analytics";

export type TimelineEventParticipationListProps = TeamEventUserSpecific;

export type TimelineEventMemberPositionProps = {
  playerName: string;
  inviteStatus: InviteStatus;
  participationStatus: ParticipationStatus;
  eventPosition?: PlayerPosition;
  member: ITeamMemberInfo;
  onPlayerPositionClick: (
    member: ITeamMemberInfo,
    eventPosition?: PlayerPosition,
  ) => void;
};

export const TimelineEventMemberPosition = ({
  playerName,
  member,
  eventPosition,
  inviteStatus,
  participationStatus,
  onPlayerPositionClick,
}: TimelineEventMemberPositionProps) => {
  const handlePositionButtonClick = (
    member: ITeamMemberInfo,
    eventPosition?: PlayerPosition,
  ) => {
    onPlayerPositionClick(member, eventPosition);
  };
  const navigator = React.useContext(NavigationContext);
  const { currentTeam } = navigator;

  return (
    <Grid item>
      <Box border={1} padding={1} borderColor={muiTheme.palette.divider}>
        <Grid
          container
          direction="row"
          spacing={1}
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container direction="row" spacing={2} alignItems="center">
              <Grid item>
                {member && (
                  <PlayerAvatar
                    teamId={member.teamId}
                    playerId={member.playerId}
                  />
                )}
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">{playerName}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <PositionButton
                  disabled={
                    member === null ||
                    currentTeam.role === TeamMemberRole.PLAYER
                  }
                  position={eventPosition}
                  onClick={() =>
                    handlePositionButtonClick(member, eventPosition)
                  }
                />
              </Grid>
              <Grid item>
                <TooltipCustom
                  title={inviteStatus.replace("_", " ")}
                  arrow
                  placement="top"
                >
                  <Typography variant="h6">
                    {inviteStatus === InviteStatus.ACCEPTED ? <DoneIcon /> : ""}
                    {inviteStatus === InviteStatus.REJECTED ? (
                      <CloseIcon />
                    ) : (
                      ""
                    )}
                    {inviteStatus === InviteStatus.TENTATIVE ? (
                      <HelpOutlineIcon />
                    ) : (
                      ""
                    )}
                    {inviteStatus === InviteStatus.INVITATION_PENDING ? (
                      <SendIcon />
                    ) : (
                      ""
                    )}
                  </Typography>
                </TooltipCustom>
              </Grid>
              <Grid item>
                <TooltipCustom
                  title={participationStatus.replace("_", " ")}
                  arrow
                  placement="top"
                >
                  <Typography variant="h6">
                    {participationStatus === ParticipationStatus.MISSED ? (
                      <CallMissedIcon />
                    ) : (
                      ""
                    )}
                    {participationStatus === ParticipationStatus.NOT_SYNCED ? (
                      <PauseCircleOutlineIcon />
                    ) : (
                      ""
                    )}
                    {participationStatus === ParticipationStatus.SYNCED ? (
                      <SyncIcon />
                    ) : (
                      ""
                    )}
                  </Typography>
                </TooltipCustom>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export const EventAttendeesTab = ({
  teamId,
  eventId,
  ...event
}: TimelineEventParticipationListProps) => {
  const analytics = useAnalytics();
  const eventQuery = useAuthQuery(
    ["event", eventId],
    getTeamEventById(eventId),
  );
  const teamMembersQuery = useAuthQuery(
    ["teamMembers", teamId],
    getTeamMembers(teamId),
  );
  const userEventMutation = useAuthMutation(
    ["updateUserEvent", eventId, event.participation.playerId],
    updateUserEvent(eventId, event.participation.playerId),
  );
  const [positionModalSelectedPlayer, setPositionModalSelectedPlayer] =
    React.useState<ITeamMemberInfo | null>(null);
  const [positionModalEventPosition, setPositionModalEventPosition] =
    React.useState<PlayerPosition | undefined>(undefined);
  const positionMutation = useAuthMutation(
    ["event", eventId],
    updateUserPositionInEvent(eventId),
  );

  React.useEffect(() => {
    if (positionMutation.isSuccess || userEventMutation.isSuccess) {
      queryClient.resetQueries(["event", eventId]);
    }
  }, [positionMutation.isSuccess, userEventMutation.isSuccess, eventId]);

  if (teamMembersQuery.isLoading || eventQuery.isLoading) {
    return <CircularProgress  />;
  }

  const members = teamMembersQuery.data?.teamMembers.reduce(
    (acc, member) => ({ ...acc, [member.playerId]: member }),
    {} as Record<string, ITeamMemberInfo>,
  );

  const changePlayerPosition = async (
    playerId: string,
    position: PlayerPosition,
  ) => {
    await positionMutation.mutateAsync({ playerId, data: { position } });

    if (positionMutation.isError) {
      return toast.error("Failed to set member position");
    }

    toast.success("Position changed!");
  };

  const handlePositionModalClose = (position?: PlayerPosition) => {
    if (positionModalSelectedPlayer?.playerId && position) {
      changePlayerPosition(positionModalSelectedPlayer?.playerId, position);
    }
    setPositionModalSelectedPlayer(null);
  };

  const handlePositionButtonClick = (
    member: ITeamMemberInfo,
    eventPosition?: PlayerPosition,
  ) => {
    setPositionModalSelectedPlayer(member);
    setPositionModalEventPosition(eventPosition);
    analytics.track("event-position-change", {
      eventId,
      playerId: member.playerId,
      eventPosition,
    });
  };

  const onInviteStatusChange = (inviteStatus: InviteStatus) => () => {
    userEventMutation.mutate({ inviteStatus });
    analytics.track("event-invite-status-change", { eventId, inviteStatus });
  };

  if (members) {
    return (
      <Grid container direction="column">
        <Grid item>
          <Paper
            hidden={event.endTime < Date.now()}
            style={{ padding: 15, paddingLeft: 30 }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Typography variant="subtitle1">
                  <Trans id="timeline_attendees_manage_participation_title">
                    Manage your participation in this event.
                  </Trans>
                </Typography>
              </Grid>
              <Grid item>
                {userEventMutation.isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={2}
                  >
                    <Grid
                      item
                      hidden={
                        event.participation.inviteStatus ===
                        InviteStatus.ACCEPTED
                      }
                    >
                      <Button
                        variant="outlined"
                        style={{ width: "fit-content", height: 50 }}
                        color="primary"
                        onClick={onInviteStatusChange(InviteStatus.ACCEPTED)}
                      >
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item style={{ marginTop: 3 }}>
                            <DoneIcon />
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">
                              <Trans
                                id="participation_accept_cta"
                                context="rsvp"
                              >
                                ACCEPT
                              </Trans>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Button>
                    </Grid>
                    <Grid
                      item
                      hidden={
                        event.participation.inviteStatus ===
                        InviteStatus.REJECTED
                      }
                    >
                      <Button
                        variant="outlined"
                        style={{ width: "fit-content", height: 50 }}
                        onClick={onInviteStatusChange(InviteStatus.REJECTED)}
                      >
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item style={{ marginTop: 3 }}>
                            <CloseIcon />
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">
                              <Trans
                                id="participation_reject_cta"
                                context="rsvp"
                              >
                                REJECT
                              </Trans>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Button>
                    </Grid>
                    <Grid
                      item
                      hidden={[
                        InviteStatus.TENTATIVE,
                        InviteStatus.INVITATION_PENDING,
                      ].includes(event.participation.inviteStatus)}
                    >
                      <Button variant="outlined"
                        style={{ width: "fit-content", height: 50 }}
                        onClick={onInviteStatusChange(InviteStatus.TENTATIVE)}
                      >
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item style={{ marginTop: 3 }}>
                            <HelpOutlineIcon />
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">
                              <Trans
                                id="participation_tentative_cta"
                                context="rsvp"
                              >
                                TENTATIVE
                              </Trans>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          {userEventMutation.isLoading || positionMutation.isLoading ? (
            <Skeleton variant="rectangular" height={300} />
          ) : (
            eventQuery.data?.participants
              .filter(
                ({ playerId }) =>
                  members[playerId] && members[playerId].role === "PLAYER",
              )
              .sort(({ playerId: a }, { playerId: b }) =>
                members[a]?.memberInfo?.firstName &&
                members[b]?.memberInfo?.firstName
                  ? members[a]!.memberInfo!.firstName!.localeCompare(
                      members[b]!.memberInfo!.firstName!,
                    )
                  : -1,
              )
              .map(
                ({ playerId, position, inviteStatus, participationStatus }) => (
                  <TimelineEventMemberPosition
                    key={playerId}
                    member={members[playerId]}
                    eventPosition={position}
                    inviteStatus={inviteStatus}
                    participationStatus={participationStatus}
                    playerName={getName(members[playerId])}
                    onPlayerPositionClick={handlePositionButtonClick}
                  />
                ),
              )
          )}
        </Grid>
        {positionModalSelectedPlayer && (
          <PositionSelectModal
            open={Boolean(positionModalSelectedPlayer)}
            handleClose={handlePositionModalClose}
            position={positionModalEventPosition}
            preferredPosition={
              positionModalSelectedPlayer?.memberInfo?.position
            }
            playerName={`${getName(positionModalSelectedPlayer)}`}
          />
        )}
      </Grid>
    );
  }

  return <ErrorIcon />;
};

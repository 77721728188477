import React from "react";
import ReactDOM from "react-dom/client";
import { Router } from "react-router-dom";
import "./index.css";
import Root from "./root";
import history from "./services/history";
import { queryClient } from "./providers/auth";
import { QueryClientProvider } from "react-query";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { ErrorBoundary } from "react-error-boundary";
import Analytics from "analytics";
import { AnalyticsProvider } from "use-analytics";
import { I18nProvider } from "@lingui/react";
import { initLocale } from "./utils/i18n";
import { muiTheme } from "./theme";
import imports from "./imports";
import { i18n } from "@lingui/core";
import { CookiesConsent } from "components/cookies";
import InstanaPlugin, { logError } from "services/analytics";
import CONFIG, { isProduction } from "./config";

imports();

const analytics = Analytics({
  app: "teamfx-web-app",
  debug: !isProduction,
  plugins: [
    InstanaPlugin({
      instanaKey: CONFIG.SERVICES.INSTANA.KEY,
      instanaReportingURL: CONFIG.SERVICES.INSTANA.REPORTING_URL,
    })
  ],
});

function fallbackRender({ error }) {
  alert("An error happened! You will be logged out to start from the start.");
  console.error("Crash error", JSON.stringify(error));
  analytics.track("error", error);
  if (isProduction) { 
    window.location.pathname = "/logout";
  }

  return <div>{JSON.stringify(error)}</div>;
}

initLocale();


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={"en"}>
    <QueryClientProvider client={queryClient}>
      <AnalyticsProvider instance={analytics}>
        <ThemeProvider theme={muiTheme}>
          <StyledEngineProvider injectFirst>
              <I18nProvider i18n={i18n}>
                <ErrorBoundary
                  FallbackComponent={fallbackRender}
                  onError={logError}
                >
                  <Router history={history}>
                    <Root />
                  </Router>
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    draggable
                    pauseOnHover
                  />
                  <CookiesConsent />
                </ErrorBoundary>
              </I18nProvider>
          </StyledEngineProvider>
        </ThemeProvider>
      </AnalyticsProvider>
    </QueryClientProvider>
  </LocalizationProvider>,
);
